<template>
<div class="auth-wrapper">
    <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#000" size="70" text="Please wait..." :is-full-screen="true" />
    <div class="auth-content">
        <div class="card">
            <div class="row align-items-center text-center">
                <div class="col-md-12">
                    <div class="card-body">
                        <h4 class="mb-4 f-w-500"><b>{{GUIConfig.GUItitle}}</b></h4>
                        <img src="/../../assets/images/login_logo.png" alt="" class="img-fluid mb-4" width="70">
                        <img src="/../../assets/images/ev-logo.png" alt="" class="img-fluid mb-4" width="70">
                        <h4 class="mb-3 f-w-400">Sign In</h4>
                        <div class="form-group mb-3">
                            <label class="floating-label">User Name</label>
                            <input type="text" class="form-control" id="user_nameTXT" v-model="username" placeholder="User Name" v-on:keyup.enter="loginAction()">
                        </div>
                        <div class="form-group mb-4">
                            <label class="floating-label">Password</label>
                            <input :type="typevalue" class="form-control" id="passwordTXT" v-model="password" placeholder="Password" v-on:keyup.enter="loginAction()">
                            <img id="cnpasswdimg" :src="imgvalue" class="eyeimg" @click="showPassword" title="Show Password"/>
                        </div>
                        <button class="btn btn-block btn-primary mb-1" @click="loginAction()">Log In</button>
                        <div class="form-group mb-1">
                            <a class="forgetpass float-right mt-2" style="color: #3b32c5;" @click="openforgetPassPopup()">Forgot Password? </a>
                        </div>
                        <br />
                        <hr />
                        <div class="form-group mb-1">
                            <p class="mb-2 text-muted text-left">Creative Micro Systems : <a href="https://www.cmsgp.com" target="_blank" class="f-w-400">www.cmsgp.com</a></p>
                            <p class="mb-1 text-muted text-left">GUI Version : <a style="cursor: auto;"><b>{{GUIConfig.GUIversion}}</b></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal name="forgetpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="350" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="45%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"><i class="fa fa-search" aria-hidden="true"></i> Find Your Account</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hideforgetpopupcloseBTN" type="button" class="btn btn-default" @click="hideforgetpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-5">
                        Enter user name to search for your account
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-7">
                        <input type="text" v-model="forgetemailidTXT" placeholder="Enter the registered user name" id="forgetemailidTXT" maxlength="32" class="mx-input" name="username" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-5">
                        Captcha
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-7">
                        <div style="display: flex;">
                            <div id="captcha">
                            </div> &nbsp;&nbsp;&nbsp; <i style="font-size:16px;cursor: pointer;margin-top: 15px;" title="captcha refresh" @click="createCaptcha()" class="fa">&#xf021;</i>
                        </div>
                        <input type="text" v-model="forgetCaptchaTXT" placeholder="Enter captcha" id="forgetCaptchaTXT" maxlength="32" class="mx-input" name="forgetCaptchaTXT" />

                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="saveforgetpopupBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveforgetpopupFunction()">Apply</a>
                <a id="saveforgetpopupclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideforgetpopup()">Cancel</a>
            </div>
        </div>
    </modal>
	<modal name="kickpopup"	transition="nice-modal-fade" classes="demo-modal-class" :min-width="200" :min-height="200"
	:pivot-y="0.5"
	:adaptive="true"
	:draggable="true"
	:scrollable="true"
	:reset="true"
	width="25%"
	height="auto"
	:clickToClose="false"
  >
	<div class="size-modal-content">
        <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h5 style="color:#00535c"> Information</h5>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button  type="button" class="btn btn-default" @click="closekickPopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
<div class="modal-body" style="margin-top:0px;background:#fff">
	<div class="row">
		<div class="col-lg-12">
			<h5>
		     To continue you have to logout the old session.
			  </h5>
			  <p></p>
			  <h5>
				Do you want to logout the existing session ?
			  </h5>
		</div>
	</div>
</div>
<div class="modal-footer flex-center">
    <a  class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="applykickuser()">Yes</a>
    <a  type="button" class="btn btn-sm btn-outline-info" @click="closekickPopup()">Cancel</a>
</div>
</div>
	</modal>
</div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import GUIConfig from "@/GUI_Config";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js';
import swal from 'sweetalert';
export default {
    props: {},
    name: 'Login',
    components: {
        VueElementLoading,
        swal
    },
    data() {
        return {
            isLoading: false,
            GUIConfig: GUIConfig,
            // username: 'praveen',
            // password: 'Softel@123',
            username: '',
            password: '',
            responsemsg: '',
            userrole: '',
            loginJson: '',
            sessionloginID: null,
            typevalue: "password",
            imgvalue: "/../../assets/images/password_close.png",
            forgetemailidTXT: '',
            forgetCaptchaTXT: '',
            captchacode: '',
			backendSts:'',
	        backendStsAdminIP:'',
	        backendStsAdminIPArr:[],
	        aliveipTXT:'',
        }
    },
    mounted() {
        document.getElementById("user_nameTXT").focus();
    },
    created() {},
    methods: {
        async loginAction1() {
        var sessiondata = {
            access_token:"ddddddddddddddddd",
            user_name:"Vijay",
            user_role:"Admin",
            }
            sessionStorage.setItem('loginsession', JSON.stringify(sessiondata));
            this.$router.replace({
                        path: "ev/index"
                    });
        },
        async loginAction() {
            this.isLoading = true;
            if (this.username == "") {
                this.warningMessage("Please enter username");
                this.isLoading = false;
                return true;
            } else if (this.password == "") {
                this.warningMessage("Please enter password");
                this.isLoading = false;
                return true;
            }
            if (this.username !== "" && this.password !== "") {
                var loginsjson = {
                    user_name: this.username.toString(),
                    password: this.password.toString(),
                };
                 console.log('loginURL :: ' + apiURL.loginURL);
                this.loginDetailJSON = await commMethods.apiPOSTmethod(apiURL.loginURL, loginsjson, null);
                console.log('JSON :: ' + JSON.stringify(this.loginDetailJSON));
                if (this.loginDetailJSON != undefined && this.loginDetailJSON.data.status == "success") {
                    this.successMessage("Login Successfully!");
                    console.log('session user_details  access_token : ', this.loginDetailJSON.data.user_details.access_token)
                    sessionStorage.setItem('loginsession', JSON.stringify(this.loginDetailJSON.data.user_details));
                    this.isLoading = false;
                    this.$router.replace({
                        path: "ev/index"
                    });
                    return true;
                } else if (this.loginDetailJSON != undefined && this.loginDetailJSON.data.status == "alive another user") {
			        this.isLoading = false;
			        this.$modal.show("kickpopup");
                  return true;
		       } else {
                    this.isLoading = false;
                    this.errorMessage(this.loginDetailJSON.data.status);
                    return true;
                }
            } else {
                this.isLoading = false;
                this.errorMessage("username and password must be present");
            }
        },
        clearlbl() {
            this.responsemsg = "";
        },
        showPassword() {
            if (this.typevalue === "password") {
                this.typevalue = "text";
                this.imgvalue = "/../../assets/images/password_open.png";
            } else {
                this.typevalue = "password";
                this.imgvalue = "/../../assets/images/password_close.png";
            }
        },
        beforeOpen() {},
        beforeClose() {},
        opened(e) { 
			this.createCaptcha();
		},
        closed(e) {},
        showforgetpopup() {
            this.$modal.show('forgetpopup');
        },
        hideforgetpopup() {
            this.$modal.hide('forgetpopup');
        },
		closekickPopup(){
		console.log('closekickPopup   :: ');
		this.$modal.hide("kickpopup");
		},
       async applykickuser(){
		var inputJson = {
			user_name: this.username.toString(),
            password: this.password.toString(),
		 };
		 console.log('inputJson  :: '+JSON.stringify(inputJson));
			let kickoutJSON = await commMethods.apiPOSTmethod(apiURL.kickoutURL,inputJson);
			console.log('kickoutJSON  :: '+JSON.stringify(kickoutJSON.data));
			if (kickoutJSON != undefined && kickoutJSON.data.status == "success") {
				this.$modal.hide("kickpopup");
				await this.loginAction();
			}
	},
       async openforgetPassPopup() {
			await this.showforgetpopup();
            this.forgetemailidTXT = "";
			this.forgetCaptchaTXT = "";
        },
        async saveforgetpopupFunction() {
            this.isLoading = true;
            if (this.forgetemailidTXT == "") {
                this.warningMessage("please enter the user name");
                this.isLoading = false;
                return true;
            }
			if (this.forgetCaptchaTXT == "") {
                this.warningMessage("please enter the captcha");
                this.isLoading = false;
                return true;
            }
            if (this.forgetCaptchaTXT != this.captchacode) {
                swal({
                    title: "Warning!",
                    text: "Invalid Captcha. try Again",
                    icon: "warning",
                    button: "Close",
                });
				this.isLoading = false;
				this.createCaptcha();
                return true;
            }
            var forgetPassCheckJson = {
                user_name: this.forgetemailidTXT.toString(),
            };
            var checkforgetaddJSON = await commMethods.apiPOSTmethod(apiURL.checkforgetaddURL, forgetPassCheckJson, null);
            console.log('JSON :: ' + JSON.stringify(checkforgetaddJSON));
            if (checkforgetaddJSON != undefined && checkforgetaddJSON.data.status == "success") {
                swal({
                    title: "Success!",
                    text: "Password reset request has been sent to the registered mail id.",
                    icon: "success",
                    button: "Close",
                });
				await this.hideforgetpopup();
                this.isLoading = false;
                return true;
            } else if (checkforgetaddJSON != undefined && checkforgetaddJSON.data.status != "success") {
                swal({
                    title: "Warning!",
                    text: checkforgetaddJSON.data.status,
                    icon: "warning",
                    button: "Close",
                });
				await this.hideforgetpopup();
                this.isLoading = false;
                return true;
            } else {
                swal({
                    title: "Error!",
                    text: checkforgetaddJSON.data.status,
                    icon: "error",
                    button: "Close",
                });
				await this.hideforgetpopup();
                this.isLoading = false;
                return true;
            }
        },
        createCaptcha() {
            //clear the contents of captcha div first
            document.getElementById('captcha').innerHTML = "";
            var charsArray =
                "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
            var lengthOtp = 6;
            var captcha = [];
            for (var i = 0; i < lengthOtp; i++) {
                //below code will not allow Repetition of Characters
                var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
                if (captcha.indexOf(charsArray[index]) == -1)
                    captcha.push(charsArray[index]);
                else i--;
            }
            var canv = document.createElement("canvas");
            canv.id = "captcha";
            canv.width = 100;
            canv.height = 50;
            var ctx = canv.getContext("2d");
            ctx.font = "25px Georgia";
            ctx.strokeText(captcha.join(""), 0, 30);
            //storing captcha so that can validate you can save it somewhere else according to your specific requirements
            this.captchacode = captcha.join("");
            document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
        },
        getRandomInt(max) {
            return "session_" + Math.floor(Math.random() * Math.floor(max));
        },
        checksplChar(inputstring) {
            var regex = /^[0-9a-zA-Z\_]+$/
            return regex.test(inputstring);
        },
        successMessage(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        infoMessage(msg) {
            this.$notice.info({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        errorMessage(msg) {
            this.$notice.error({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        }
    }
}
</script>

<style scoped>
.eyeimg {
    right: 10px;
    position: absolute;
    top: 12px;
    width: 20px;
    cursor: pointer;
}

.forgetpass:hover {
    text-decoration: underline;
}

.floating-label {
    top: -10px !important;
    font-size: 0.75rem;
    color: #00535c;
}
</style>
