<template>
<div class="pcoded-main-container ">
    <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
        <ul class="nav flex-column text-white w-100">
            <span class="h5  my-2 " style="color:#E91E63">
                &nbsp; <b>Gateway List :</b>
            </span>
            <li class="h5 text-white my-2">
                <v-jstree :data="diagTreeViewData" @item-click="diagTreeItemClick"></v-jstree>
            </li>
        </ul>

    </div>

    <!-- Main Wrapper -->
    <div class="p-1 my-container active-cont">
        <!-- Top Nav -->
        <nav class="navbar top-navbar navbar-light" style="cursor: pointer !important;z-index: 5;">
            <a class="btn btn-sm border-0" id="menu-btn"><i class="feather icon-menu"></i></a>
        </nav>
        <!--End Top Nav -->
        <div class="row">
            <div class="col-lg-12 flexboxcontainer">
                <div class="col-lg-8 rightbox1">
                    <div class="card table-card">
                        <div class="card-header">
                            <h5> Signal Profile <b class="text-c-blue">- {{ diagSignaldateLBL }}</b></h5>
                            <div class="card-header-right" style="display: flex;">
                                <div class="form-check" style="margin-top: 5px;">
                                    <input class="form-check-input" type="radio" value="Daily" v-model="SignalCHKBXTXT" name="SignalCHKBX" id="dailySignalCHKBX" checked @change="SignalCHKBXFunction()">
                                    <label class="form-check-label" for="dailySignalCHKBX">
                                        Daily
                                    </label>
                                </div>
                                &nbsp; &nbsp;
                                <div class="form-check" style="margin-top: 5px;">
                                    <input class="form-check-input" type="radio" value="Monthly" v-model="SignalCHKBXTXT" name="SignalCHKBX" id="monthlySignalCHKBX" @change="SignalCHKBXFunction()">
                                    <label class="form-check-label" for="monthlySignalCHKBX">
                                        Monthly </label>
                                </div>
                                &nbsp; &nbsp;
                                <div class="btn-group card-option" v-show="SignalCHKBXTXT == 'Daily'">
                                    <date-picker v-model="diagdailydateFRMT" format="DD MMM YYYY" value-type="format" placeholder="Select Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="diagdailyPickerChange" valueType="format"></date-picker>
                                </div>
                                <div class="btn-group card-option" v-show="SignalCHKBXTXT == 'Monthly'">
                                    <date-picker v-model="diagSignalmonthlydateFRMT" format="MMM YYYY" placeholder="Select Month" type="month" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="diagSignalmonthlyPickerChange" valueType="format"></date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0" v-show="SignalCHKBXTXT == 'Daily'">
                            <div v-if="dailyseriesData.length !=0">
                                <apexchart width="100%" :height="((diagpageLayoutHeight-280)/2)" type="line" :options="dailychartOptions" :series="dailyseriesData"></apexchart>
                            </div>
                            <div v-else class="text-center" v-bind:style="{'height':((diagpageLayoutHeight-250)/2)+'px'}">
                                <div> No Records Found</div>
                            </div>
                        </div>
                        <div class="card-body p-0" v-show="SignalCHKBXTXT == 'Monthly'">
                            <div v-if="monthlySignalseriesData.length !=0">
                                <apexchart width="100%" :height="((diagpageLayoutHeight-280)/2)" type="line" :options="monthlySignalChartOptions" :series="monthlySignalseriesData"></apexchart>
                            </div>
                            <div v-else class="text-center" v-bind:style="{'height':((diagpageLayoutHeight-250)/2)+'px'}">
                                <div> No Records Found</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 rightbox2">
                    <div class="card table-card">
                        <div class="card-header">
                            <h5>{{selDEVNAME}} - Details</h5>
							<div class="card-header-right" v-if="devStatusTXT != ''">
							<span v-if="devStatusTXT == 'Communicating'"> Status :  <b  style="color:green"> <i class="fa fa-check"></i> {{ devStatusTXT }} </b></span>
							<span v-else> Status :  <b  style="color:red"><i class="fa fa-times"></i> {{ devStatusTXT }} </b></span>
							</div>
                        </div>
                        <div class="card-body p-0" v-bind:style="{'height':((diagpageLayoutHeight-250)/2)+'px'}">
                            <div class="table-responsive">
                                <table class="table table-hover mb-1">
                                    <tbody>
                                        <tr>
                                            <td>Serial Number</td>
                                            <td class="text-left"><label class="badge badge-light-primary text-left">{{devSerialNoTXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>No of Slave Meters</td>
                                            <td class="text-left"><label class="badge badge-light-primary text-left">{{devSlveNoMetersTXT}}</label></td>
                                        </tr>
                            <tr>
                                <td>Last Diag Message<br />Date & Time</td>
                                <td class="text-left"><label class="badge badge-light-primary text-left">{{devSlveDiagMsgTXT}}</label></td>
                            </tr>
                            <tr>
                                <td>Connectivity Up Time</td>
                                <td class="text-left"><label class="badge badge-light-primary text-left">{{devSlveConnectivityUptimeTXT}}</label></td>
                            </tr>
                            <tr>
                                <td>Firmware Version</td>
                                <td class="text-left"><label class="badge badge-light-primary text-left">{{devSlvefwVerTXT}}</label></td>
                            </tr>
							<tr>
								<td><b><u>Meters Status</u></b></td>
								<td></td>
								</tr>
                            </tbody>
                            </table>
							<div style="display: flex;align-items: center;flex-wrap: wrap;margin-left:20px;padding: 0px;" v-if="devMetArrList.length != 0">
								<div v-for="(items,genindex) in devMetArrList" :key="genindex">
								<div class="item" v-if="items.sts == 'Communicating'" title="Communicating">
									 <small class="text"><b>{{items.met_name}}</b></small>
									 <img src="/assets/images/online_meter.png"  title="Communicating"  width="30" height="30" >
								 </div>
								 <div class="item" title="Not Communicating" v-else-if="items.sts == 'Not Communicating'">
									<small class="text"><b>{{items.met_name}}</b></small>
									<img src="/assets/images/offline_meter.png"  title="Not Communicating"  width="30" height="30">
								</div>
								<div class="item" v-else title="Not Configured">
									<small class="text"><b>{{items.met_name}}</b></small>
									<img src="/assets/images/notcomm_meter.png"  title="Not Configured"  width="30" height="30">
								</div>
								</div>
								 </div>
							<!-- <tr v-for="(items, index) in devMetArrList" :key="index">
								<td class="pull-left" style="padding: 0.2rem 0.5rem;"><i class="fa fa-compass"></i> {{items.met_name}}</td>
								<td class="text-left" style="padding: 0.2rem 0.5rem;" v-if="items.sts == 'Communicating'"><label class="badge text-white" style="background-color: green;">{{items.sts}}</label></td>
								<td class="text-left" style="padding: 0.2rem 0.5rem;" v-else><label class="badge text-white" style="background-color: red;">{{items.sts}}</label></td>
							</tr> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 flexboxcontainer">
            <div class="col-lg-8 rightbox1">
                <div class="card table-card">
                    <div class="card-header">
                        <h5>Connectivity Percentage Profile - <b class="text-c-blue">{{ diagmonthlydateLBL }}</b></h5>
                        <div class="card-header-right">
                            <div class="btn-group card-option">
                                <date-picker v-model="diagmonthlydateFRMT" format="MMM YYYY" placeholder="Select Month" type="month" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="diagmonthlyPickerChange" valueType="format"></date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div v-if="diagmonthlyseriesData.length != 0">
                            <apexchart width="100%" :height="((diagpageLayoutHeight-305)/2)" :type="diagmonthlyChartType" :options="diagmonthlychartOptions" :series="diagmonthlyseriesData"></apexchart>
                        </div>
                        <div v-else class="text-center" v-bind:style="{'height':((diagpageLayoutHeight-275)/2)+'px'}">
                            <div> No Records Found</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 rightbox2">
                <div class="card table-card">
                    <div class="card-header">
                        <h5><i class="fa fa-microchip"></i> {{selDEVNAME}} - Events <small>(Latest <b>50</b> Events)</small></h5>
                        <div class="card-header-right" v-show="false">
                            <div class="btn-group card-option">
                                <date-picker v-model="eventdateFRMT" format="DD MMM YYYY" value-type="format" placeholder="Select Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="eventsPickerChange" valueType="format"></date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-2">
                        <div class="col-lg-12">
                            <div v-bind:style="{'height':((diagpageLayoutHeight-308)/2)+'px'}" style="overflow: auto;overflow-x: hidden;border: 1px solid linen;">
                                <ul class="timeline" v-if="deviceEventArrayList.length != 0">
                                    <li v-for="(items, index) in deviceEventArrayList" :key="index">
                                        <i class="fa fa-clock"></i>
                                        <div class="" style="margin-left: 50px">
                                            <span class="timeline-header text-muted">{{items.date_time}}</span>
                                            <p class="m-b-0">{{items.msg}}</p>
                                        </div>
                                    </li>
                                </ul>
                                <div class="text-center" v-else>
                                    <div> No Records Found</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import VJstree from "vue-jstree";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import GUIConfig from "@/GUI_Config";
import commMethods from '@/api/common_methods.js'
var myDate = new Date();
export default {
    name: "Dashboard",
    components: {
        DatePicker,
        VueElementLoading,
        VJstree,
    },
    data() {
        return {
            diagpageLayoutHeight: 0,
			userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
            isLoading: false,
            diagTreeViewData: [],
			devStatusTXT: "",
            devSerialNoTXT: "-",
            devSlveNoMetersTXT: "-",
            modemConUptimeTXT: "-",
            modemSignalStrTXT: "-",
            devSlveDiagMsgTXT: "-",
            devSlveConnectivityUptimeTXT:"-",
            modemFrmTXT: "-",
            modemCommStsTXT: "-",
            devSlvefwVerTXT: "-",
            devMetArrList: [],
            diagSignaldateLBL: this.dateOnlyFRMT(myDate),
            diagdailydateFRMT: this.dateOnlyFRMT(myDate),
            SignalCHKBXTXT: 'Daily',
            diagmonthlydateLBL: this.dateMonthFRMT(myDate),
            diagmonthlydateFRMT: this.dateMonthFRMT(myDate),
            diagSignalmonthlydateFRMT: this.dateMonthFRMT(myDate),
            eventdateFRMT: this.dateOnlyFRMT(myDate),
            dailyChartType: "bar",
            deviceEventArrayList: [],
            dailychartOptions: {
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [120, 100, 100],
                    },
                },
                grid: {
                    borderColor: "#f1f1f1",
                    row: {
                        colors: ["#f4f4f4", "#f4f4f4"],
                        opacity: 0.5,
                    },
                    column: {
                        colors: ["#f4f4f4", "#f7f7f7"],
                        opacity: 0.5,
                    },
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
					title: {
						text: "Hours",
						offsetX: 0,
    					offsetY: 90,
						margin: 10,
					},
                    // type: "datetime",
                    // min: this.diagdailydateFRMT,
                    // max: this.diagdailydateFRMT,
                    type: "numeric",
                    tickPlacement: 'on',
                    min:  this.dateOnlyNUMFRMT(this.diagdailydateFRMT),
                    max:  this.dateOnlyNUMFRMT(this.diagdailydateFRMT),
                    labels: {
                        show: true,
                        //  format: 'HH:mm',
                        formatter: function (value, timestamp) {
                            var today = new Date(timestamp);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth() + 1;
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            if (mm < 10) {
                                mm = "0" + mm;
                            }
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            //hh = 1;
                            //var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                            var date = hh+ ":" + '00';
                            return date;
                        },
                    },
                },
                yaxis: [{
                    title: {
                        text: "Signal Strength (dB )",
                    },
                }, ],
                markers: {
                    size: [4, 4],
                },
                // colors: ["#00535c", "#7a023a"],
                colors: ["#0D47A1", "#00535c"],
                chart: {
                    zoom: {
                        enabled: true,
                        type: "x",
                        autoScaleYaxis: false,
                        zoomedArea: {
                            fill: {
                                color: "#90CAF9",
                                opacity: 0.4,
                            },
                            stroke: {
                                color: "#0D47A1",
                                opacity: 0.4,
                                width: 1,
                            },
                        },
                    },
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 350,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 850,
                        },
                    },
                    height: 385,
                    stacked: false,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    curve: "smooth", // ['smooth', 'straight']
                    lineCap: "butt",
                    colors: undefined,
                    width: 1,
                    dashArray: 1,
                },

                legend: {
                    show: false,
                    position: "bottom",
                    horizontalAlign: "center",
                },
                title: {
                    text: "",
                    align: "left",
                },
                tooltip: {
                    x: {
                        show: true,
                        //format: "dd/MM/yyyy HH:mm",
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth() + 1;
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            if (mm < 10) {
                                mm = "0" + mm;
                            }
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            var date =
                                dd + "-" + mm + "-" + yyyy + " : " + hh + ":" + min + ":" + sec;
                            return date;
                        },
                    },
                },
            },
            dailyseriesData: [],
            //**
            monthlySignalChartOptions: {
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [120, 100, 100],
                    },
                },
                grid: {
                    borderColor: "#f1f1f1",
                    row: {
                        colors: ["#f4f4f4", "#f4f4f4"],
                        opacity: 0.5,
                    },
                    column: {
                        colors: ["#f4f4f4", "#f7f7f7"],
                        opacity: 0.5,
                    },
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
					title: {
						text: "Days",
						offsetX: 0,
    					offsetY: 120,
						// margin: 10,
					},
                   // type: "datetime",
                    labels: {
                        show: true,
                        rotate: -25,
                        rotateAlways: true,
                        hideOverlappingLabels: true,
                       // format: 'MMM dd',
						// offsetX: -50,
          				// offsetY: 0,
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth();
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            //  if (mm < 10) {mm = "0" + mm;}
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            // var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                            var monthlimt = [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ];
                            var date =  monthlimt[Number(mm)]+ " "+dd;
                            return date;
                        },
                    },
                },
                yaxis: [{
                    title: {
                        text: "Signal Strength (dB )",
                    },
                }, ],
                markers: {
                    size: [4],
                },
                // colors: ["#00535c", "#7a023a"],
                colors: ["#0D47A1", "#00535c"],
                chart: {
                    zoom: {
                        enabled: true,
                        type: "x",
                        autoScaleYaxis: false,
                        zoomedArea: {
                            fill: {
                                color: "#90CAF9",
                                opacity: 0.4,
                            },
                            stroke: {
                                color: "#0D47A1",
                                opacity: 0.4,
                                width: 1,
                            },
                        },
                    },
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 350,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 850,
                        },
                    },
                    height: 385,
                    stacked: false,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    curve: "smooth", // ['smooth', 'straight']
                    lineCap: "butt",
                    colors: undefined,
                    width: 1,
                    dashArray: 1,
                },

                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                },
                title: {
                    text: "",
                    align: "left",
                },
                tooltip: {
                    x: {
                        show: true,
                        format: "dd MMM yyyy",
						// formatter: function (value, timestamp) {
                        //     var today = new Date(value);
                        //     var yyyy = today.getFullYear();
                        //     var mm = today.getMonth() + 1;
                        //     var dd = today.getDate();
                        //     var hh = today.getHours();
                        //     var min = today.getMinutes();
                        //     var sec = today.getSeconds();
                        //     if (mm < 10) {
                        //         mm = "0" + mm;
                        //     }
                        //     if (dd < 10) {
                        //         dd = "0" + dd;
                        //     }
                        //     if (hh < 10) {
                        //         hh = "0" + hh;
                        //     }
                        //     if (min < 10) {
                        //         min = "0" + min;
                        //     }
                        //     if (sec < 10) {
                        //         sec = "0" + sec;
                        //     }
                        //     var date = dd + "-" + mm + "-" + yyyy;
                        //     return date;
                        // },
                    },
                },
            },
            monthlySignalseriesData: [],
            // month graph
            diagmonthlyChartType: "bar",
            diagmonthlychartOptions: {              
                annotations: {
                    yaxis: [{
                        y: 100,
                        borderColor: '#fff',
                        // label: {
                        //     borderColor: '#775DD0',
                        //     style: {
                        //         color: '#fff',
                        //         background: '#775DD0'
                        //     },
                        //     text: 'Connectivity 100%'
                        // }
                    }]
                },
               
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 1.5,
                        opacityTo: 1,
                        stops: [20, 100, 100],
                    },
                },
                grid: {
                    borderColor: "#f1f1f1",
                    row: {
                        colors: ["#f4f4f4", "#f4f4f4"],
                        opacity: 0.5,
                    },
                    column: {
                        colors: ["#f4f4f4", "#f7f7f7"],
                        opacity: 0.5,
                    },
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
               
                xaxis: {
                    // type: "datetime",
					title: {
						text: "Days",
						offsetX: 0,
    					offsetY: 115,
						margin: 10,
					},                    
                    labels: {
                        show: true,
                        rotate: -25,
                        rotateAlways: true,
                        hideOverlappingLabels: true,
                       // format: 'MMM dd',
						// offsetX: -50,
          				// offsetY: 0,
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth();
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            //  if (mm < 10) {mm = "0" + mm;}
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            // var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                            var monthlimt = [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ];
                            var date =  monthlimt[Number(mm)]+ " "+dd;
                            return date;
                        },
                        // formatter: function (value, timestamp) {
                        //     var today = new Date(timestamp);
                        //     var yyyy = today.getFullYear();
                        //     var mm = today.getMonth();
                        //     var dd = today.getDate();
                        //     var hh = today.getHours();
                        //     var min = today.getMinutes();
                        //     var sec = today.getSeconds();
                        //     //  if (mm < 10) {mm = "0" + mm;}
                        //     if (dd < 10) {
                        //         dd = "0" + dd;
                        //     }
                        //     if (hh < 10) {
                        //         hh = "0" + hh;
                        //     }
                        //     if (min < 10) {
                        //         min = "0" + min;
                        //     }
                        //     if (sec < 10) {
                        //         sec = "0" + sec;
                        //     }
                        //     // var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                        //     var monthlimt = [
                        //         "Jan",
                        //         "Feb",
                        //         "Mar",
                        //         "Apr",
                        //         "May",
                        //         "Jun",
                        //         "Jul",
                        //         "Aug",
                        //         "Sep",
                        //         "Oct",
                        //         "Nov",
                        //         "Dec",
                        //     ];
                        //     var date = dd + " " + monthlimt[Number(mm)];
                        //     return date;
                        // },
                    },
                },
                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                },
                yaxis: [{
					min:0,
					max:100,
                    title: {
                        text: "Connectivity in %",
                    },
                }, ],
                markers: {
                    size: [4, 4],
                },
                colors: ["#279562", "#ff0000"],
                chart: {
                    stacked: true,
                    stackType: '100%',
                  
                    zoom: {
                        enabled: false
                    },
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 350,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 850,
                        },
                    },
                    toolbar: {
                        show: false,
                    },
                

                },
                dataLabels: {
                    enabled: true,
                    style: {
                    fontSize: '9px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                  },
                },
                stroke: {
                    show: true,
                    curve: "smooth", // ['smooth', 'straight']
                    lineCap: "butt",
                    colors: undefined,
					width: 1,
                    dashArray: 1.5,
                },
                title: {
                    text: "",
                    align: "left",
                },
                tooltip: {
                    y: {
                        show: true,
                        formatter: function (value, timestamp) {
                            return value + " %";
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth() + 1;
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            if (mm < 10) {
                                mm = "0" + mm;
                            }
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            var date = dd + "-" + mm + "-" + yyyy;
                            return date;
                        },
                    },
                },
            },
            diagmonthlyseriesData: [],
            selLOCID: null,
            selLOCNAME: null,
            selDEVID: null,
            selDEVNAME: null,
            diagnosticInterval: null,
        };
    },
    props: {},
    async mounted() {
        this.isLoading = true;
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        if(window.innerHeight <= 768){
            this.diagpageLayoutHeight = window.innerHeight+100;
        }else{
            this.diagpageLayoutHeight = window.innerHeight;
        }        
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        // treeview         
        console.log("-----diagnosticsTreeViewsession---------->>"+JSON.stringify(sessionStorage.getItem("diagnosticsTreeViewsession")));
        if(sessionStorage.getItem("diagnosticsTreeViewsession") != null){
             this.diagTreeViewData  = JSON.parse(sessionStorage.getItem("diagnosticsTreeViewsession"));
             this.getDiagSessionTreeListData  = await this.getDiagSessionTreeListDetails();
             if(this.getDiagSessionTreeListData != null){
                console.log("treeSessionListViewData : "+this.getDiagSessionTreeListData.length);
                console.log("treeViewData : "+this.diagTreeViewData.length);
                if(this.getDiagSessionTreeListData.length != this.diagTreeViewData.length){
                    await this.getDiagTreeListDetails();
                }
             }            
             await this.convertTreeViewFRT(this.diagTreeViewData);
        }else{
             await this.getDiagTreeListDetails();
        }   
        await this.getDiagSignalChartDetails(this.SignalCHKBXTXT);
        await this.getDeviceEventsDetails();
        await this.getDiagConvPercentChartDetails();
        await this.callDiagnosticsLoad();
        menu_btn.addEventListener("click", () => {
            sidebar.classList.toggle("active-nav");
            container.classList.toggle("active-cont");
        });
        this.isLoading = false;
    },
    destroyed() {
        clearInterval(this.diagnosticInterval);
    },
    methods: {
        async callDiagnosticsLoad() {
            await this.getDeviceStatusDetails();
            var api_sent = false;
            var response_received = true;
            this.diagnosticInterval = setInterval(async () => {
                console.log('callDiagnosticsLoad :api_sent :' + api_sent + ': response_received :' + response_received);
                if (api_sent == false && response_received == true) {
                    console.log("callDiagnosticsLoad API Request Sent");
                    api_sent = true;
                    response_received = false;
                    var startDate = new Date();
                    await this.getDeviceStatusDetails();
                    var endDate = new Date();
                    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
                    console.log("Total Elapsed : ", seconds);
                    console.log("****callDiagnosticsLoad**API*****END****");
                    response_received = true;
                    api_sent = false;
                }
            }, GUIConfig.runIntervalTime);
        },
        async getDiagTreeListDetails() {
            let diagnosticsTreeviewListJSON = await commMethods.apiGETmethod(apiURL.diagnosticsTreeviewListURL, null, this.userSessionData);
            console.log('JSON :::diagnosticsTreeviewListJSON::: ' + JSON.stringify(diagnosticsTreeviewListJSON.data));
            if (diagnosticsTreeviewListJSON != undefined && diagnosticsTreeviewListJSON.data.status == "found") {
                sessionStorage.setItem('diagnosticsTreeViewsession',JSON.stringify(diagnosticsTreeviewListJSON.data.tree_details));
                await this.convertTreeViewFRT(diagnosticsTreeviewListJSON.data.tree_details);
            }
        },
        async getDiagSessionTreeListDetails() {
            let diagnosticsTreeviewListJSON = await commMethods.apiGETmethod(apiURL.diagnosticsTreeviewListURL, null, this.userSessionData);
            console.log('JSON :::getDiagSessionTreeListDetails::: ' + JSON.stringify(diagnosticsTreeviewListJSON.data));
            if (diagnosticsTreeviewListJSON != undefined && diagnosticsTreeviewListJSON.data.status == "found") {
                // sessionStorage.setItem('diagnosticsTreeViewsession',JSON.stringify(diagnosticsTreeviewListJSON.data.tree_details));
                return diagnosticsTreeviewListJSON.data.tree_details;
                // await this.convertTreeViewFRT(diagnosticsTreeviewListJSON.data.tree_details);
            }
        },
        convertTreeViewFRT(treeJson) {
            if (treeJson.length != 0) {
                for (var locIndx = 0; locIndx < treeJson.length; locIndx++) {
                    if (treeJson[locIndx].children.length != 0) {
                        for (var devIndx = 0; devIndx < treeJson[locIndx].children.length; devIndx++) {
                           	 if (treeJson[locIndx].opened == true && treeJson[locIndx].children[devIndx].selected == true) {
								this.selLOCID = treeJson[locIndx].children[devIndx].loc_id;
                        		this.selLOCNAME = treeJson[locIndx].children[devIndx].loc_name;
                                this.selDEVID = treeJson[locIndx].children[devIndx].dev_id;
                                this.selDEVNAME = treeJson[locIndx].children[devIndx].dev_name;
                          	  }
                        }
                    }
                }
            }
            console.log('--------selLOCID------>>' + this.selLOCID);
            console.log('--------selLOCNAME------>>' + this.selLOCNAME);
            console.log('--------selDEVID------>>' + this.selDEVID);
            console.log('--------selDEVNAME------>>' + this.selDEVNAME);
            this.diagTreeViewData = treeJson;
        },
        async diagTreeItemClick(node) {
            this.isLoading = true;
            console.log(node.model.text + " clicked !");
            this.selLOCID = node.model.loc_id;
            this.selLOCNAME = node.model.loc_name;
            this.selDEVID = node.model.dev_id;
            this.selDEVNAME = node.model.dev_name;
            this.selMETID = node.model.met_id;
            this.selMETNAME = node.model.text;
            clearInterval(this.diagnosticInterval);
            await this.getDiagSignalChartDetails(this.SignalCHKBXTXT);
            await this.getDeviceEventsDetails();
            await this.getDiagConvPercentChartDetails();
            await this.getDeviceStatusDetails();
            sessionStorage.setItem('diagnosticsTreeViewsession', JSON.stringify(this.diagTreeViewData));
            this.isLoading = false;
            // setTimeout(() => {
            //     this.isLoading = false;
            // }, 1000);
        },
        dailychartTypeMethod(type) {
            this.dailyChartType = type;
        },
        diagdiagmonthlyChartTypeMethod(type) {
            this.diagmonthlyChartType = type;
        },
        notAfterToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        async getDiagSignalChartDetails(chkBx) {
            console.log('---------chkBx------>' + chkBx);
            var signalProfileChartJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "date": this.diagSignaldateLBL
            };
            var url = apiURL.diagnosticsSignalDayProfileURL;
            if (chkBx == "Monthly") {
                url = apiURL.diagnosticsSignalMonthProfileURL;
            }
            console.log(JSON.stringify(signalProfileChartJson) + '---------getDiagSignalChartDetails :::' + url);
            let diagnosticsSignalDayProfileJSON = await commMethods.apiPOSTmethod(url, signalProfileChartJson, this.userSessionData);
            console.log('JSON :::diagnosticsSignalDayProfileJSON::: ' + JSON.stringify(diagnosticsSignalDayProfileJSON.data));
            if (diagnosticsSignalDayProfileJSON != undefined && diagnosticsSignalDayProfileJSON.data.status == "found") {
                if (chkBx == "Monthly") {
                    this.monthlySignalseriesData = diagnosticsSignalDayProfileJSON.data.details;
                } else {
                    this.dailyseriesData = diagnosticsSignalDayProfileJSON.data.details;
                }
            } else {
                this.dailyseriesData = [];
                this.monthlySignalseriesData = [];
            }
        },
        async getDiagConvPercentChartDetails() {
            var monthlyProfileChartJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "date": this.diagmonthlydateLBL
            };
            console.log(JSON.stringify(monthlyProfileChartJson) + '---------getDiagConvPercentChartDetails :::' + apiURL.diagnosticsConnMonthProfileURL);
            let diagnosticsConnMonthProfileJSON = await commMethods.apiPOSTmethod(apiURL.diagnosticsConnMonthProfileURL, monthlyProfileChartJson, this.userSessionData);
            console.log('JSON :::diagnosticsConnMonthProfileJSON::: ' + JSON.stringify(diagnosticsConnMonthProfileJSON.data));
            if (diagnosticsConnMonthProfileJSON != undefined && diagnosticsConnMonthProfileJSON.data.status == "found") {
               this.diagmonthlyseriesData = diagnosticsConnMonthProfileJSON.data.details;
            } else {
                this.diagmonthlyseriesData = [];
            }
        },
        async getDeviceEventsDetails() {
            var instJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "date": this.eventdateFRMT
            };
            console.log(JSON.stringify(instJson) + '---------getMETEventsDetails :::' + apiURL.diagnosticsDeviceEventsURL);
            let diagnosticsDeviceEventsJSON = await commMethods.apiPOSTmethod(apiURL.diagnosticsDeviceEventsURL, instJson, this.userSessionData);
            console.log('JSON :::diagnosticsDeviceEventsJSON::: ' + JSON.stringify(diagnosticsDeviceEventsJSON.data));
            if (diagnosticsDeviceEventsJSON != undefined && diagnosticsDeviceEventsJSON.data.status == "found") {
                this.deviceEventArrayList = diagnosticsDeviceEventsJSON.data.details;
            } else {
                this.deviceEventArrayList = [];
            }
        },
        async getDeviceStatusDetails() {
            var instJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME
            };
            console.log(JSON.stringify(instJson) + '---------getDeviceStatusDetails :::' + apiURL.diagnosticsDeviceStatusURL);
            let diagnosticsDeviceStatusJSON = await commMethods.apiPOSTmethod(apiURL.diagnosticsDeviceStatusURL, instJson, this.userSessionData);
            console.log('JSON :::diagnosticsDeviceStatusJSON::: ' + JSON.stringify(diagnosticsDeviceStatusJSON.data));
            if (diagnosticsDeviceStatusJSON != undefined && diagnosticsDeviceStatusJSON.data.status == "found") {
				this.devStatusTXT = diagnosticsDeviceStatusJSON.data.dev_status;
                this.devSerialNoTXT = diagnosticsDeviceStatusJSON.data.ser_num;
                this.devSlveNoMetersTXT = diagnosticsDeviceStatusJSON.data.dev_count;
                this.devSlveDiagMsgTXT = diagnosticsDeviceStatusJSON.data.dev_last_diag_time;
                this.devSlveConnectivityUptimeTXT = diagnosticsDeviceStatusJSON.data.conn_up_time;
                this.devSlvefwVerTXT = diagnosticsDeviceStatusJSON.data.fw_ver;
                this.devMetArrList = diagnosticsDeviceStatusJSON.data.met_details;
            } else {
				this.devStatusTXT = "";
                this.devSerialNoTXT = "-";
                this.devSlveNoMetersTXT = "-";
                this.devSlveDiagMsgTXT = "-";
                this.devSlvefwVerTXT = "-";
                this.devSlveConnectivityUptimeTXT ="-";
                this.devMetArrList = [];
            }
        },
        async SignalCHKBXFunction() {
            this.isLoading = true;
            console.log('->>' + this.SignalCHKBXTXT);
            if (this.SignalCHKBXTXT == 'Daily') {
                this.diagSignaldateLBL = this.diagdailydateFRMT;

            } else {
                this.diagSignaldateLBL = this.diagSignalmonthlydateFRMT;

            }
            await this.getDiagSignalChartDetails(this.SignalCHKBXTXT);
            this.isLoading = false;
        },
        async diagdailyPickerChange(value) {
            this.isLoading = true;
            this.diagdailydateFRMT = value;
            this.diagSignaldateLBL = value;
            await this.SignalCHKBXFunction();
            console.log("----------->" + value);
            this.isLoading = false;
        },
        async diagSignalmonthlyPickerChange(value) {
            this.isLoading = true;
            this.diagSignalmonthlydateFRMT = value;
            this.diagSignaldateLBL = value;
            await this.SignalCHKBXFunction();
            console.log("----------->" + value);
            this.isLoading = false;
        },
        async diagmonthlyPickerChange(value) {
            this.isLoading = true;
            this.diagmonthlydateFRMT = value;
            this.diagmonthlydateLBL = value;
            await this.getDiagConvPercentChartDetails();
            console.log("----------->" + value);
            this.isLoading = false;
        },
        async eventsPickerChange(value) {
            this.isLoading = true;
            this.eventdateFRMT = value;
            await this.getDeviceEventsDetails();
            console.log("----------->" + value);
            this.isLoading = false;
        },
        dateOnlyNUMFRMT(dateval) {
    var date = new Date(dateval);
    var month = date.getMonth()+1;
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
	var monthlimt = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthStr = month <= 9 ? "0" + month : month;
    var dateStr = day <= 9 ? "0" + day : day;
    return  year +"-" +monthStr +"-" +dateStr;
    },
        dateOnlyFRMT(dateval) {
            var date = new Date(dateval);
            var month = date.getMonth();
            var day = date.getDate();
            var year = date.getFullYear();
            var hour = date.getHours();
            var min = date.getMinutes();
            var sec = date.getSeconds();
            var monthlimt = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var dateStr = day <= 9 ? "0" + day : day;
            return dateStr + " " + monthlimt[month] + " " + year;
        },
        dateMonthFRMT(dateval) {
            var date = new Date(dateval);
            var month = date.getMonth();
            var day = date.getDate();
            var year = date.getFullYear();
            var hour = date.getHours();
            var min = date.getMinutes();
            var sec = date.getSeconds();
            var monthlimt = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var dateStr = day <= 9 ? "0" + day : day;
            return monthlimt[month] + " " + year;
        },
    },
    watch: {},
};
</script>

<style scoped>
.table td,
.table th {
    border-top: 1px solid transparent !important;
}
.side-navbar {
    width: 260px;
    height: 100%;
    position: fixed;
    margin-left: -280px;
    margin-top: -5px;
    background-color: #ffffff;
    transition: 0.5s;
}
.flexboxcontainer{
    display:flex;
    /* grid-template-columns: repeat(auto-fit, minmax(500px,1fr)); */
    flex-wrap:wrap;
}

.rightbox1{
    min-width: 700px;
}

.rightbox2{
    min-width: 500px;
}


.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #ffffff26;
}

.my-container {
    transition: 0.4s;
}

.active-nav {
    margin-left: 0;
}

/* for main section */
.active-cont {
    margin-left: 250px;
}

#menu-btn {
    background-color: #00535c;
    color: #fff;
    margin-top: -15px;
    margin-left: -30px;
    cursor: pointer;
}

/*
.my-container input {
  border-radius: 2rem;
  padding: 2px 20px;
}
*/

.item {
    width: 80px;
    height: 55px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e2e3d7;
    cursor: pointer;
    border-radius: 3px;
}
.item:hover{
    transform: scale(1.1);
    background-color: #dddfc4;
}
.mx-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.badge {
    font-size: 100%;
    padding: 0.5em 0.7em;
    font-weight: bold !important;
    width: 100% !important;
}
</style>
