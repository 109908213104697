<template>
<div class="pcoded-main-container">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="card">
                <div class="card-header" style="padding: 0px 0px 10px 15px;">
                    <div class="row">
                        <div class="col-lg-4">
                            <h5 style="margin-top: 10px;">Config / Firmware Upload</h5>
                        </div>
                        <div class="col-lg-7" style="margin-top: 10px;">
                            <label><b>Choose Gateway :</b> &nbsp;</label>
                            <select type="text" class="mx-input" name="deviceSelDrpTXT" v-model="deviceSelDrpTXT" style="width: 20%;">
                                <option name="Choose Gateway" selected disabled>Choose Gateway</option>
                                <option v-for="(item,index) in deviceArrList" :key="index" :value="+item.id+'$'+item.dev_name">{{item.dev_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-bind:style="{'height':((pageLayoutHeight-215))+'px'}">
                    <div class="row">
                        <div class="col-lg-12">
                            <ul class="timeline">
                                <li v-if="userSessionData.role != 'Operator'">
                                    <i class="fa fa-download bg-dark text-white"></i>
                                    <div class="timeline-item">
                                        <span class="time">&nbsp;</span>
                                        <h3 class="timeline-header">Download Configuration</h3>
                                        <div class="timeline-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <button type="submit" class="btn btn-primary btn-sm" @click="callDownloadFunction('Download Configuration')"><i class="fa fa-download text-white"></i> Save Configuration to PC</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  <div class="timeline-footer">

<a>&nbsp;</a>

  </div> -->
                                    </div>
                                </li>
                                <li v-if="userSessionData.role != 'Operator'">
                                    <i class="fa fa-upload bg-danger text-white"></i>
                                    <div class="timeline-item">
                                        <span class="time">&nbsp;</span>
                                        <h3 class="timeline-header">Upload Configuration</h3>
                                        <div class="timeline-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <input type="file" id="file1" ref="file1" accept=".tar, .cfg" v-on:change="handleFile1Upload()" />
                                                    <p></p>
                                                    <button id="upload_BTN" type="button" class="btn btn-sm btn-primary" @click="callUploadConfigFunction('Upload Configuration')">
                                                        <i class="fa fa-upload text-white"></i> Upload Configuration to Gateway
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <!-- timeline 3333333333333333 -->
                                <li v-if="userSessionData.role == 'Super User'">
                                    <i class="fa fa-upload bg-dark text-white"></i>
                                    <div class="timeline-item">
                                        <span class="time">&nbsp;</span>
                                        <h3 class="timeline-header">Upgrade Firmware</h3>
                                        <div class="timeline-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <input type="file" id="file2" ref="file2" accept=".tar, .zip" v-on:change="handleFile2Upload()" />
                                                    <p></p>
                                                    <button id="upload_BTN" type="button" class="btn btn-sm btn-primary" @click="callUploadFirmwareFunction('Upgrade Firmware')">
                                                        <i class="fa fa-upload text-white"></i> Upgrade Gateway Firmware
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <i class="fa fa-retweet bg-success text-white"></i>
                                    <div class="timeline-item">
                                        <span class="time">&nbsp;</span>
                                        <h3 class="timeline-header">Restart Gateway</h3>
                                        <div class="timeline-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <button id="reset_BTN" type="button" class="btn btn-sm btn-primary" @click="callResetFunction('Restart Gateway')">
                                                        <i class="fa fa-retweet text-white"></i> Restart
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-show="false">
                                    <i class="fa fa-clock bg-warning text-white"></i>
                                    <div class="timeline-item">
                                        <span class="time">&nbsp;</span>
                                        <h3 class="timeline-header">Time Sync</h3>
                                        <div class="timeline-body">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <button id="time_sync_BTN" type="button" class="btn btn-sm btn-primary" @click="callTimeSyncFunction('Time Sync')">
                                                        <i class="fa fa-clock text-white"></i> Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal name="confrimationModelPopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{confrimationModelPopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_smscloseBTN" type="button" class="btn btn-default" @click="hideconfrimationModelPopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Reason
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <textarea class="inputTEXTcss" id="descriptionTXT" v-model="descriptionTXT" rows="10" cols="70" style="min-width: 100%;min-height: 150px;max-width: 100%;border:1px solid lightgray"></textarea>
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="saveconfrimationBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="applyfunction()">Apply</a>
                <a id="confrimationclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideconfrimationModelPopup()">Cancel</a>
            </div>
        </div>
    </modal>

	<modal name="alertPopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="alertpopupbeforeOpen" @opened="alertpopupopened" @closed="alertpopupclosed" @before-close="alertpopupbeforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{alertPopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
				<div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-12 text-center">
                        <img src="/assets/images/loading.gif" style="width: 50px;"/>
						<br/>
						<small>please wait...</small>
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-12 text-center">
                        <h6>{{alertPopupMsg}}</h6>
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <!-- <a id="alertPopupclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideAlertPopup()">Cancel</a> -->
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import GUIConfig from "@/GUI_Config";
import commMethods from '@/api/common_methods.js';
import swal from 'sweetalert';
export default {
    name: "ConfigUploadFw",
    components: {
        VueElementLoading,
		swal
    },
    data() {
        return {
            pageLayoutHeight: 0,
            isLoading: false,
			file1:'',
			file1Size:'',
			file2:'',
			file2Size:'',
            userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
            confrimationModelPopupTitle: 'Confirmation',
            deviceSelDrpTXT: 'Choose Gateway',
            descriptionTXT: '',
            deviceArrList: [],
			checkdeviceArrList:[],
            applyBTNTXT: '',
			alertPopupTitle:'',
			alertPopupMsg:'',
			seqnum:'',
			configJSON:null,
			checkststusInterval:null,
			GUIConfig: GUIConfig,
        };
    },
    props: {},
    async mounted() {
        this.isLoading = true;
        this.pageLayoutHeight = window.innerHeight;
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        await this.getDeviceDetailsList();
        this.isLoading = false;
    },
	destroyed(){
		if(this.checkststusInterval != null){
				clearInterval(this.checkststusInterval);
		}
	},
    methods: {
        async getDeviceDetailsList() {
            let deviceManagementListLJSON = await commMethods.apiGETmethod(apiURL.deviceManagementListURL, null, this.userSessionData);
            console.log('JSON :::deviceManagementListLJSON::: ' + JSON.stringify(deviceManagementListLJSON.data));
            if (deviceManagementListLJSON != undefined && deviceManagementListLJSON.data.status == "found") {
                this.deviceArrList = deviceManagementListLJSON.data.details;
            } else {
                this.deviceArrList = [];
            }
        },
		async checkDeviceDetailsList() {
            let deviceManagementListLJSON = await commMethods.apiGETmethod(apiURL.devicecommunicationListURL, null, this.userSessionData);
            console.log('JSON :::deviceManagementListLJSON::: ' + JSON.stringify(deviceManagementListLJSON.data));
            if (deviceManagementListLJSON != undefined && deviceManagementListLJSON.data.status == "found") {
                this.checkdeviceArrList = deviceManagementListLJSON.data.details;
				return this.checkdeviceArrList;
            } else {
                this.checkdeviceArrList = [];
				return this.checkdeviceArrList;
            }
        },
        async applyfunction() {
			if (this.descriptionTXT.length < 10 || this.descriptionTXT.length > 50) {
                this.warningMessage('Your Reason is too short, please write more (minimum 10 characters and maximum 50 characters).');
                return false;
            }
			if(this.applyBTNTXT == 'Upload Configuration'){
				await this.submitFile1();
			} else if(this.applyBTNTXT == 'Upgrade Firmware'){
				await this.submitFile2();
			} else {
				await this.funConfig(this.applyBTNTXT);
			}
        },
		async callDownloadFunction(txt){
			if (this.deviceSelDrpTXT == "Choose Gateway") {
                this.warningMessage('Please Choose Gateway');
                return false;
            }
			var checkarr = await this.checkDeviceDetailsList();
			if(checkarr.length != 0){
				var boolch = false;
				var dev_id = this.deviceSelDrpTXT.split('$')[0];
            	var dev_name = this.deviceSelDrpTXT.split('$')[1];
				for(var tt=0;tt<checkarr.length;tt++){
					if(checkarr[tt].id == dev_id && checkarr[tt].dev_name == dev_name && checkarr[tt].dev_sts == "Communicating"){
						boolch = true;
					}
				}
				if(!boolch){
					this.warningMessage("Oops! ("+dev_name+") is not communicating");
					this.isLoading = false;
               		return false;
				}
			}
			this.showconfrimationModelPopup(txt);
		},
		async callUploadConfigFunction(txt){
			if (this.deviceSelDrpTXT == "Choose Gateway") {
                this.warningMessage('Please Choose Gateway');
                return false;
            }
			this.isLoading = true;
            if (this.file1 === undefined || this.file1 === '') {
                this.warningMessage('Please upload the gateway config file and proceed further.');
                this.isLoading = false;
                return false;
            }
            this.file1Size = Math.round((this.$refs.file1.files[0].size / 1024));
            if (this.file1Size == 0) {
                this.errorMessage('Config file size range should be (0-2)mb.');
                this.file1 = "";
                this.file1Size = "";
                this.isLoading = false;
                return false;
            } else if (this.file1Size >= 2048) {
                this.errorMessage('Config file size range should be (0-2)mb.');
                this.file1 = "";
                this.file1Size = "";
                this.isLoading = false;
                return false;
            }
			var checkarr = await this.checkDeviceDetailsList();
			if(checkarr.length != 0){
				var boolch = false;
				var dev_id = this.deviceSelDrpTXT.split('$')[0];
            	var dev_name = this.deviceSelDrpTXT.split('$')[1];
				for(var tt=0;tt<checkarr.length;tt++){
					if(checkarr[tt].id == dev_id && checkarr[tt].dev_name == dev_name && checkarr[tt].dev_sts == "Communicating"){
						boolch = true;
					}
				}
				if(!boolch){
					this.warningMessage("Oops! ("+dev_name+") is not communicating");
					this.isLoading = false;
               		return false;
				}
			}
			this.showconfrimationModelPopup(txt);
			this.isLoading = false;
		},

		async callUploadFirmwareFunction(txt){
			if (this.deviceSelDrpTXT == "Choose Gateway") {
                this.warningMessage('Please Choose Gateway');
                return false;
            }
			this.isLoading = true;
            if (this.file2 === undefined || this.file2 === '') {
                this.warningMessage('Please upload the gateway firmware file and proceed further.');
                this.isLoading = false;
                return false;
            }
            this.file2Size = Math.round((this.$refs.file2.files[0].size / 1024));
            if (this.file2Size == 0) {
                this.errorMessage('Config file size range should be (0-2)mb.');
                this.file2 = "";
                this.file2Size = "";
                this.isLoading = false;
                return false;
            } else if (this.file2Size >= 2048) {
                this.errorMessage('Config file size range should be (0-2)mb.');
                this.file2 = "";
                this.file2Size = "";
                this.isLoading = false;
                return false;
            }
			var checkarr = await this.checkDeviceDetailsList();
			if(checkarr.length != 0){
				var boolch = false;
				var dev_id = this.deviceSelDrpTXT.split('$')[0];
            	var dev_name = this.deviceSelDrpTXT.split('$')[1];
				for(var tt=0;tt<checkarr.length;tt++){
					if(checkarr[tt].id == dev_id && checkarr[tt].dev_name == dev_name && checkarr[tt].dev_sts == "Communicating"){
						boolch = true;
					}
				}
				if(!boolch){
					this.warningMessage("Oops! ("+dev_name+") is not communicating");
					this.isLoading = false;
               		return false;
				}
			}
			this.showconfrimationModelPopup(txt);
			this.isLoading = false;
		},

		async callResetFunction(txt){
			if (this.deviceSelDrpTXT == "Choose Gateway") {
                this.warningMessage('Please Choose Gateway');
                return false;
            }
			var checkarr = await this.checkDeviceDetailsList();
			if(checkarr.length != 0){
				var boolch = false;
				var dev_id = this.deviceSelDrpTXT.split('$')[0];
            	var dev_name = this.deviceSelDrpTXT.split('$')[1];
				for(var tt=0;tt<checkarr.length;tt++){
					if(checkarr[tt].id == dev_id && checkarr[tt].dev_name == dev_name && checkarr[tt].dev_sts == "Communicating"){
						boolch = true;
					}
				}
				if(!boolch){
					this.warningMessage("Oops! ("+dev_name+") is not communicating");
					this.isLoading = false;
               		return false;
				}
			}
			this.showconfrimationModelPopup(txt);
		},
		async callTimeSyncFunction(txt){
			if (this.deviceSelDrpTXT == "Choose Gateway") {
                this.warningMessage('Please Choose Gateway');
                return false;
            }
			var checkarr = await this.checkDeviceDetailsList();
			if(checkarr.length != 0){
				var boolch = false;
				var dev_id = this.deviceSelDrpTXT.split('$')[0];
            	var dev_name = this.deviceSelDrpTXT.split('$')[1];
				for(var tt=0;tt<checkarr.length;tt++){
					if(checkarr[tt].id == dev_id && checkarr[tt].dev_name == dev_name && checkarr[tt].dev_sts == "Communicating"){
						boolch = true;
					}
				}
				if(!boolch){
					this.warningMessage("Oops! ("+dev_name+") is not communicating");
					this.isLoading = false;
               		return false;
				}
			}
			this.showconfrimationModelPopup(txt);
		},
        async funConfig(fun_txt) {
            this.isLoading = true;
            var dev_id = this.deviceSelDrpTXT.split('$')[0];
            var dev_name = this.deviceSelDrpTXT.split('$')[1];
            var newxJSON = {
                dev_id: dev_id,
                dev_name: dev_name,
                reason: this.descriptionTXT
            }
            var url = apiURL.downloadconfigURL;
            var msg = "Gateway (" + dev_name + ") - Configuration downloaded successfully";
			var msgAlert = dev_name + " - Config file download command is in-progress...";

			// // upload
			// var msgAlert = dev_name + " upload config file command is in-progress...";
			// fw
			// var msgAlert = dev_name + " upload firmware file command is in-progress...";
            if (fun_txt == "Restart Gateway") {
                url = apiURL.resetURL;
                msg =  dev_name + " Restarted";
				msgAlert = dev_name + " - Restart command is in-progress...";
            } else if (fun_txt == "Time Sync") {
                url = apiURL.timesyncURL;
                msg = "Gateway (" + dev_name + ") - Time synchronized successfully";
				msgAlert = dev_name + " - Time synchronized command is in-progress...";
            }
            var downloadconfigJSON = await commMethods.apiPOSTmethod(url, newxJSON, this.userSessionData);
            console.log('downloadconfigJSON :: ' + JSON.stringify(downloadconfigJSON));
            if (downloadconfigJSON != undefined && downloadconfigJSON.data.status == "success") {
				this.configJSON = downloadconfigJSON.data;
				this.configJSON['com_type'] = fun_txt;
				this.configJSON['dev_name'] = dev_name;
                this.hideconfrimationModelPopup();
				this.alertPopupMsg = msgAlert;
				this.showAlertPopup();
                this.isLoading = false;
                return false;
            } else {
				swal({
  				title: "Warning!",
  				text: downloadconfigJSON.data.status,
  				icon: "warning",
  				button: "Close",
				});
                this.hideconfrimationModelPopup();
                this.isLoading = false;
                return false;
            }
        },
		handleFile1Upload () {
			this.isLoading = true;
			console.log('---this.deviceSelDrpTXT------------>'+this.deviceSelDrpTXT);
			if (this.deviceSelDrpTXT == "Choose Gateway") {
				$('#file1').val('');
                this.warningMessage('Please Choose Gateway');
				this.isLoading = false;
                return false;
            }
      		this.file1 = this.$refs.file1.files[0];
			this.isLoading = false;
    	},
        submitFile1() {
            this.isLoading = true;
            var _this = this;
			var dev_id = this.deviceSelDrpTXT.split('$')[0];
            var dev_name = this.deviceSelDrpTXT.split('$')[1];
			var url = apiURL.uploadconfigURL + "?dev_id="+dev_id+"&active_user="+this.userSessionData.user_name+"&active_role="+this.userSessionData.role;

		try {
		var _this = this;
	  	var formData = new FormData();
  		formData.append("config_file", _this.file1);
  		//formData.append("id", 7878);
  		var request = new XMLHttpRequest();
		console.log("--------URL------------>>>>"+url);
  		request.open("POST", url);
		request.setRequestHeader("Authorization",  _this.userSessionData.access_token);
  		request.send(formData);
  		request.onreadystatechange = function (response) {
	    console.log("--------request------------>>>>"+JSON.stringify(request));
		console.log("--------response------------>>>>",response);
    	if (request.readyState === 4) {
      	if (request.status === 200 && request.statusText === 'OK') {
			console.log("-----submitFile1---response------------>>>>"+JSON.stringify(request.responseText));
			  _this.configJSON = JSON.parse(request.responseText);
			  _this.configJSON['com_type'] = "Upload Configuration";
    		  _this.configJSON['dev_name'] = dev_name;
              _this.hideconfrimationModelPopup();
			  _this.alertPopupMsg = dev_name + " - Upload config file is in-progress...";
			  _this.showAlertPopup();
              _this.isLoading = false;
              return true;
      } else {
		swal({
                    title: "Warning!",
                    text: response.status,
                    icon: "warning",
                    button: "Close",
                  });
                  _this.isLoading = false;
                  _this.hideconfrimationModelPopup();
                  return true;
      }
      }
  	}
      } catch (error) {
        console.error("--error->>" + error);
      }

        },
		handleFile2Upload () {
			this.isLoading = true;
			if (this.deviceSelDrpTXT == "Choose Gateway") {
				$('#file2').val('');
                this.warningMessage('Please Choose Gateway');
				this.isLoading = false;
                return false;
            }
      		this.file2 = this.$refs.file2.files[0];
			this.isLoading = false;
    	},
        submitFile2() {
            this.isLoading = true;
			var dev_id = this.deviceSelDrpTXT.split('$')[0];
            var dev_name = this.deviceSelDrpTXT.split('$')[1];
			var url = apiURL.uploadfirmwareURL + "?dev_id="+dev_id+"&active_user="+this.userSessionData.user_name+"&active_role="+this.userSessionData.role;
            var _this = this;
			try {
		var _this = this;
	  	var formData = new FormData();
  		formData.append("firmware_file", _this.file2);
  		var request = new XMLHttpRequest();
		console.log("--------URL------------>>>>"+url);
  		request.open("POST", url);
		request.setRequestHeader("Authorization",  _this.userSessionData.access_token);
  		request.send(formData);
  		request.onreadystatechange = function (response) {
	    console.log("--------request------------>>>>"+JSON.stringify(request));
		console.log("--------response------------>>>>",response);
    	if (request.readyState === 4) {
      	if (request.status === 200 && request.statusText === 'OK') {
			console.log("-----submitFile2---response------------>>>>"+JSON.stringify(request.responseText));
			  _this.configJSON = JSON.parse(request.responseText);
			  _this.configJSON['com_type'] = "Upgrade Firmware";
    		  _this.configJSON['dev_name'] = dev_name;
              _this.hideconfrimationModelPopup();
			  _this.alertPopupMsg = dev_name + " - Upgrade firmware is in-progress...";
			  _this.showAlertPopup();
              _this.isLoading = false;
              return true;
      } else {
		swal({
                    title: "Warning!",
                    text: response.status,
                    icon: "warning",
                    button: "Close",
                  });
                  _this.isLoading = false;
                  _this.hideconfrimationModelPopup();
                  return true;
      }
      }
  	}
      } catch (error) {
        console.error("-upload firmware-error->>" + error);
      }
        },
        beforeOpen() {},
        beforeClose() {},
        opened(e) {},
        closed(e) {},
		alertpopupbeforeOpen() {},
        alertpopupbeforeClose() {
			if(this.checkststusInterval != null){
				clearInterval(this.checkststusInterval);
			}
		},
     	async alertpopupopened(e) {
			await this.callCheckFUNStatusMethod();
		},
        alertpopupclosed(e) {},
		async callCheckFUNStatusMethod(){
		// await this.checkstus();
    	var api_sent = false;
    	var response_received = true;
    	this.checkststusInterval = setInterval(async () => {
      	console.log('callCheckFUNStatusMethod :api_sent :'+ api_sent + ': response_received :'+ response_received);
      	if (api_sent == false && response_received == true) {
        console.log("callCheckFUNStatusMethod API Request Sent");
        api_sent = true;
        response_received = false;
		await this.checkstus();
        console.log("****callCheckFUNStatusMethod**API*****END****");
        response_received = true;
        api_sent = false;
      	}
    	}, GUIConfig.commandIntervalTime);
    },
	async checkstus(){
		console.log('-----*--this.configJSON--*->>'+JSON.stringify(this.configJSON));
	    var confgStatusJSON = await commMethods.apiPOSTmethod(apiURL.confgStatusURL, this.configJSON, this.userSessionData);
        console.log('confgStatusJSON :: ' + JSON.stringify(confgStatusJSON.data));
		if(confgStatusJSON != undefined && confgStatusJSON != null && confgStatusJSON.data.status == "success"){
			var msg = confgStatusJSON.data.dev_name + " successfully";
			  if (this.configJSON != null && this.configJSON.com_type == "Download Configuration") {
				  await this.downloadFile(confgStatusJSON.data.file_name,confgStatusJSON.data.file_path);
				  msg = confgStatusJSON.data.dev_name + " - Config file downloaded successfully.";
			  }else if (this.configJSON != null && this.configJSON.com_type == "Restart Gateway") {
				  msg = confgStatusJSON.data.dev_name + " - Restarted.";
			  }else if (this.configJSON != null && this.configJSON.com_type == "Upload Configuration") {
				  msg = confgStatusJSON.data.dev_name + " - Configuration uploaded successfully.";
			  }else if (this.configJSON != null && this.configJSON.com_type == "Upgrade Firmware") {
				  msg = confgStatusJSON.data.dev_name + " - Firmware Upgraded successfully.";
			  }
			  if(this.checkststusInterval != null) {
				clearInterval(this.checkststusInterval);
			  }
			  swal({
  				title: "Success!",
  				text: msg,
  				icon: "success",
  				button: "Close",
				});
				this.hideAlertPopup();
                this.isLoading = false;
                return false;
		}else if(confgStatusJSON != undefined && confgStatusJSON != null && confgStatusJSON.data.status == "in_progress"){
		}else {
			if(this.checkststusInterval != null){
				clearInterval(this.checkststusInterval);
			}
			swal({
  				title: "Error!",
  				text: confgStatusJSON.data.status,
  				icon: "error",
  				button: "Close",
				});
                this.hideAlertPopup();
                this.isLoading = false;
                return false;
		}
	},
	downloadFile(fileName, filepath) {
            console.log(fileName + "Download FILE PATH :" + apiURL.serverURL + filepath);
            const link1 = document.createElement("a");
            link1.href = apiURL.serverURL + filepath;
            link1.setAttribute("download", fileName);
            document.body.appendChild(link1);
            link1.click();
        },
        showconfrimationModelPopup(txt) {
            this.applyBTNTXT = txt;
            this.confrimationModelPopupTitle = txt + " - Confirmation";
            this.descriptionTXT = "";
            this.$modal.show('confrimationModelPopup');
        },
        hideconfrimationModelPopup() {
            this.$modal.hide('confrimationModelPopup');
        },
		showAlertPopup(){
			this.$modal.show('alertPopup');
		},
		hideAlertPopup(){
			this.$modal.hide('alertPopup');
		},
        successMessage(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        infoMessage(msg) {
            this.$notice.info({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        errorMessage(msg) {
            this.$notice.error({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
    },
    watch: {},
};
</script>

<style scoped>
.timeline {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #ddd;
    left: 31px;
    margin: 0;
    border-radius: 2px
}

.timeline>li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table
}

.timeline>li:after {
    clear: both
}

.timeline>li>.timeline-item {
    -webkit-box-shadow: 1px 1px 3px 3px rgb(4 0 0 / 10%);
    box-shadow: 1px 1px 3px 3px rgb(4 0 0 / 10%);
    border-radius: 5px;
    margin-top: 0;
    background: #fff;
    color: #444;
    margin-left: 60px;
    margin-right: 15px;
    padding: 0;
    position: relative
}

.timeline>li>.timeline-item>.time {
    color: #999;
    float: right;
    padding: 10px;
    font-size: 12px
}

.timeline>li>.timeline-item>.timeline-header {
    margin: 0;
    color: #555;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    font-size: 16px;
    line-height: 1.1
}

.timeline>li>.timeline-item>.timeline-header>a {
    font-weight: 600
}

.timeline>li>.timeline-item>.timeline-body,
.timeline>li>.timeline-item>.timeline-footer {
    padding: 10px
}

.timeline>li>.fa,
.timeline>li>.glyphicon,
.timeline>li>.ion {
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    color: #666;
    background: #d2d6de;
    border-radius: 50%;
    text-align: center;
    left: 18px;
    top: 0
}

.timeline>.time-label>span {
    font-weight: 600;
    padding: 5px;
    display: inline-block;
    background-color: #fff;
    border-radius: 4px
}

.timeline-inverse>li>.timeline-item {
    background: #f0f0f0;
    border: 1px solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none
}

.timeline-inverse>li>.timeline-item>.timeline-header {
    border-bottom-color: #ddd
}
</style>
