<template>
<div class="pcoded-main-container">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#857b2b" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="row">
                <!-- [ sample-page ] start -->
                <div class="col-sm-4" style="padding-right: 5px;">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5 style="margin-top: 10px;"><i class="fa fa-map text-c-blue" aria-hidden="true"></i> Location Management</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <button id="locationmanagementaddBTN" v-if="userSessionData.role != 'Operator'" class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Location" @click="addlocationmanagementpopup()">
                                        <i class="fa fa-plus"></i> Add Location
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="locationmanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-240)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="locationmanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px">#</th>
                                                <th class="text-left">Org Name</th>
                                                <th class="text-left">Location Name</th>
                                                <th v-if="userSessionData.role != 'Operator'">Edit</th>
                                                <th   v-if="userSessionData.role != 'Operator'">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="locationmanageTBLJSONS != null">
                                            <tr v-for="(row,index) in locationmanageTBLJSONS.details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.org_name}}</td>
                                                <td class="text-left">{{row.loc_name}}</td>
                                                <td  style="cursor:pointer" title="Edit" v-if="userSessionData.role != 'Operator'" @click="locationEditFunction(row.id,index)">
                                                    <img src="assets/images/edit.png" width="20" />
                                                </td>
                                                <td  style="cursor:pointer" title="Delete"  v-if="userSessionData.role != 'Operator'" @click="locationDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="locationmanageTBLJSONS == null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8" style="padding-left: 5px;">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5 style="margin-top: 10px;"><i class="fa fa-microchip text-c-blue" aria-hidden="true"></i> Gateway Management</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <button id="devicemanagementaddBTN" v-if="userSessionData.role != 'Operator'" class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Device" @click="devicemanagementAddFunction()">
                                        <i class="fa fa-plus"></i> Add Gateway
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="devicemanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-240)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="devicemanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px"># </th>
                                                <th class="text-left">Location Name</th>
                                                <th class="text-left">Gateway Name</th>
                                                <th class="text-left">Serial Number</th>
                                                <th class="text-left">Connect Meter Type</th>
                                               <!-- <th>Gateway Model</th> -->
                                                <th class="text-left">Add Meters</th>
                                                <th class="text-left" v-if="userSessionData.role != 'Operator'">Edit</th>
                                                <th class="text-left" v-if="userSessionData.role != 'Operator'">Delete </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="devicemanageTBLJSONS != null">
                                            <tr v-for="(row,index) in devicemanageTBLJSONS.details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.loc_name}}</td>
                                                <td class="text-left">{{row.dev_name}}</td>
                                                <td class="text-left">{{row.dev_sn}}</td>
                                                <td class="text-left">{{row.met_type}}</td>
                                                <!-- <td>{{row.dev_model}}</td> -->
                                                <td  title="Meter Details" style="width:20px;cursor:pointer;"  @click="addMeterDetailsFunction(row.id,index)">
                                                    <img src="assets/images/meter.png" width="22" />
                                                </td>
                                                <td style="width:22px;cursor:pointer;" title="Edit" v-if="userSessionData.role != 'Operator'" @click="deviceEditFunction(row.id,index)">
                                                    <img src="assets/images/edit.png" width="20" />
                                                </td>
                                                <td  style="width:22px;cursor:pointer;" title="Delete" v-if="userSessionData.role != 'Operator'" @click="deviceDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="devicemanageTBLJSONS ==  null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- [ sample-page ] end -->
            </div>
            <!-- [ Main Content ] end -->
        </div>
    </div>
    <modal name="locationmangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{locationmangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_locationcloseBTN" type="button" class="btn btn-default" @click="hidelocationmanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Organization Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="orgNameTxt" placeholder="enter a organization name" id="orgNameTxt" maxlength="32" class="mx-input" name="orgNameTxt" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Location Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="locationNameTxt" placeholder="enter a location name" id="locationNameTxt" maxlength="32" class="mx-input" name="locationNameTxt" />
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createlocationmanagementBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveLocationConfigFunction()">{{locationpopupsavebuttontext}}</a>
                <a id="locationmanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hidelocationmanagementpopup()">Cancel</a>
            </div>
        </div>
    </modal>

    <modal name="devicemangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="32%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{devicemangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_devicecloseBTN" type="button" class="btn btn-default" @click="hidedevicemanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Location Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <select type="text" class="mx-input" name="devLocNameTxt" v-model="devLocNameTxt" @change="changeLocationMethod(devLocNameTxt)">
                            <option name="Choose Location" selected disabled>Choose Location</option>
                            <option v-for="(item,index) in locationArrayList" :key="index" :value="item.loc_name">{{item.loc_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Gateway Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text"  v-model="deviceNameTxt" placeholder="enter a gateway name" id="deviceNameTxt" maxlength="32" class="mx-input" name="deviceNameTxt" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Serial Address
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
						<select type="text" class="mx-input" name="deviceSerialTxt" v-model="deviceSerialTxt" :disabled="devSerialFlag" v-if="devicepopupsavebuttontext == 'Save'">
                            <option name="Choose Gateway Serial" selected disabled>Choose Gateway Serial</option>
                            <option v-for="(item,index) in deviceSerialArr" :key="index" :value="item.ser_num">{{item.ser_num}}</option>
                        </select>
						<input v-else type="text"  v-model="deviceSerialEditTxt" disabled id="deviceSerialEditTxt" maxlength="32" class="mx-input" name="deviceSerialEditTxt"/>
                    </div>
                </div>
                 <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Connect Meter Type
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <select type="text"  id="devmetconnetTypeTxt" class="mx-input" name="devmetconnetTypeTxt" v-model="devmetconnetTypeTxt">
                            <option name="Choose Connect Meter Type" selected disabled>Choose Connect Meter Type</option>
                            <option name="RS-485">RS-485</option>
                            <option name="RS-232">RS-232</option>
                        </select>
                    </div>
                </div>
              <!--  <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Gateway Model
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <select type="text" id="devModelTxt" class="mx-input" name="devModelTxt" v-model="devModelTxt">
                            <option name="Choose Gateway Model" selected disabled>Choose Gateway Model</option>
                            <option name="MIM" v-show="devmetconnetTypeTxt == 'Modem'">MIM</option>
                            <option name="IOT Router" v-show="devmetconnetTypeTxt == 'Modem'">IOT Router</option>
                            <option name="Intelligent Router" v-show="devmetconnetTypeTxt == 'Modem'">Intelligent Router</option>
                            <option name="1200" v-show="devmetconnetTypeTxt == 'RTU'">1200</option>
                            <option name="1500" v-show="devmetconnetTypeTxt == 'RTU'">1500</option>
                            <option name="2000" v-show="devmetconnetTypeTxt == 'RTU'">2000</option>
                            <option name="DCU" v-show="devmetconnetTypeTxt == 'DCU'">DCU</option>
                        </select>
                    </div>
                </div> -->
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createdevicemanagementBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveDeviceConfigFunction()">{{devicepopupsavebuttontext}}</a>
                <a id="devicemanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hidedevicemanagementpopup()">Cancel</a>
            </div>
            <p></p>
        </div>
    </modal>

    <modal name="meterDetailspopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="55%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{meterDetailspopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_meterDetailsPopcloseBTN" type="button" class="btn btn-default" @click="hidemeterDetailspopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">

                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="meterdetailsaddBTN" v-if="userSessionData.role != 'Operator'" class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Meter" @click="addMeterpopup()">
                            <i class="fa fa-plus"></i> Add Meter
                        </button>
                    </div>
                </div>
                <template>
                    <div class="table-responsive" id="meterDetailsTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:'400px'}">
                        <table data-v-f3f3eg9 class="table table-bordered" id="meterDetailsTablePANEID">
                            <thead class style="position: sticky;top: -1px;z-index:1;">
                                <tr>
                                    <th class="text-left" style="width:50px">#</th>
									<th class="text-left">Meter Status</th>
                                    <th class="text-left">Meter Name</th>
                                    <!-- <th>Serial Number</th> -->
                                    <th class="text-left">Slave ID</th>
                                    <th class="text-left">CT Ratio</th>
                                    <th class="text-left">PT Ratio</th>
                                    <th v-if="userSessionData.role != 'Operator'">Edit</th>
                                    <th v-if="userSessionData.role != 'Operator'">Delete</th>
                                </tr>
                            </thead>
                            <tbody v-if="deviceMeterTBLJSONS != null">
                                <tr v-for="(row,index) in deviceMeterTBLJSONS.met_details" :key="row.id">
                                    <td class="text-left">{{index+1}}</td>
									<td class="text-left">{{row.met_enable}}</td>
                                    <td class="text-left">{{row.met_name}}</td>
                                    <!-- <td>{{row.met_sn}}</td> -->
                                    <td class="text-left">{{row.met_addr}}</td>
                                    <td class="text-left">{{row.ct_ratio}}</td>
                                    <td class="text-left">{{row.pt_ratio}}</td>
                                    <td style="cursor:pointer;" v-if="userSessionData.role != 'Operator'" title="Edit" @click="meterDetailEditFunction(row.id,deviceRowIndex,index)">
                                        <img src="assets/images/edit.png" width="20" />
                                    </td>
                                    <td  style="cursor:pointer;" v-if="userSessionData.role != 'Operator'" title="Delete" @click="meterDetailDeleteFunction(row.id,deviceRowIndex,index)">
                                        <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="deviceMeterTBLJSONS ==  null" style="text-align:center;width: 100%;">
                            <span>No records found</span>
                        </div>
                    </div>
                </template>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="meterpopupmanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hidemeterDetailspopup()">Cancel</a>
            </div>
        </div>
    </modal>
    <modal name="addMeterDetailspopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{addMeterDetailspopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_locationcloseBTN" type="button" class="btn btn-default" @click="hideAddMeterDetailspopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
				<div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Meter Status
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
						<select type="text" class="mx-input" name="deviceMeterStatusTXT" v-model="deviceMeterStatusTXT" >
                            <option name="Choose Meter Status" selected disabled>Choose Meter Status</option>
                            <option name="Enable" value="Enable">Enable</option>
							<option name="Disable" value="Disable">Disable</option>
                        </select>
					 </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Meter Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="deviceMeternameTXT" placeholder="enter a meter name" id="deviceMeternameTXT" maxlength="10" class="mx-input" name="deviceMeternameTXT" />
                    </div>
                </div>
                <!-- <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Serial Number
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
						<select type="text" class="mx-input" name="deviceMeterSerialTXT" v-model="deviceMeterSerialTXT" v-if="addMeterDetailsTitlesavebuttontext == 'Save'">
                            <option name="Choose Meter Serial" selected disabled>Choose Meter Serial</option>
                            <option v-for="(item,index) in meterSerialArr" :key="index" :value="item.ser_num">{{item.ser_num}}</option>
                        </select>
						<input type="text" v-model="deviceMeterSerialEditTXT" id="deviceMeterSerialEditTXT" maxlength="32" class="mx-input" name="deviceMeterSerialEditTXT" v-else disabled/>
					 </div>
                </div> -->
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Slave ID
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="deviceMeterAddressTXT" placeholder="enter a meter salve ID" id="deviceMeterAddressTXT" maxlength="32" class="mx-input" name="deviceMeterAddressTXT" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        CT Ratio
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="deviceMeterCTRatioTXT" placeholder="enter a meter CT ratio" id="deviceMeterCTRatioTXT"  maxlength="10" @keypress="isNumberwithslash($event)" class="mx-input" name="deviceMeterCTRatioTXT" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        PT Ratio
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="deviceMeterPTRatioTXT" placeholder="enter a meter PT ratio" id="deviceMeterPTRatioTXT" maxlength="10" @keypress="isNumberwithslash($event)" class="mx-input" name="deviceMeterPTRatioTXT" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Meter Password
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input :type="typevalue1" v-model="deviceMeterPasswordTXT" placeholder="enter a meter password" id="deviceMeterPasswordTXT" maxlength="32" class="mx-input" name="deviceMeterPasswordTXT" />
                        <img id="cnpasswdimg" :src="imgvalue1" class="eyeimg" @click="showpassword1" />
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createMetDetailsBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveMeterDetailsFunction()">{{addMeterDetailsTitlesavebuttontext}}</a>
                <a id="hideMetDetailsBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideAddMeterDetailspopup()">Cancel</a>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js'
export default {
    name: "LocationDeviceManagement",
    components: {
        VueElementLoading,
    },
    data() {
        return {
            pageLayoutHeight: 0,
            userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
            isLoading: false,
            locationmangementpopupTitle: "Add Location",
            locationmangementEditID: null,
            orgNameTxt: "",
            locationNameTxt: "",
            locationpopupsavebuttontext: "Save",
            deviceRowIndex: 0,
            locationmanageTBLJSONS: null,
            locationArrayList: [],
            devicemangementpopupTitle: "Add Gateway",
            devmanagemntEditID: null,
            devlocIdTXT: "",
            devLocNameTxt: "",
            deviceNameTxt: "",
            deviceSerialTxt: "",
			deviceSerialEditTxt:'',
			devSerialFlag:false,
            devmetconnetTypeTxt: "Choose Connect Meter Type",
            devModelTxt: "Choose Gateway Model",
            devicepopupsavebuttontext: "Save",
            devicemanageTBLJSONS: null,
			deviceSerialArr:[],
			meterSerialArr:[],
            meterDetailspopupTitle: "",
            deviceMeterTBLJSONS: null,
            addMeterDetailspopupTitle: '',
            devMetId: '',
			deviceMeterStatusTXT:"Choose Meter Status",
            deviceMeternameTXT: '',
            deviceMeterSerialTXT: 'Choose Meter Serial',
			deviceMeterSerialEditTXT:'',
			metSerialFlag:false,
            deviceMeterAddressTXT: '',
            deviceMeterPasswordTXT: '',
            deviceMeterCTRatioTXT:'',
            deviceMeterPTRatioTXT:'',
            addMeterDetailsTitlesavebuttontext: 'Save',
            typevalue1: "password",
            imgvalue1: "assets/images/password_close.png",
        };
    },
    props: {},
    async mounted() {
        this.isLoading = true;
        this.pageLayoutHeight = window.innerHeight;
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        await this.getLocationDetailsList();
        await this.getDeviceDetailsList();
        this.isLoading = false;

    },

    methods: {
        async getLocationDetailsList() {
            let locationManagementListJSON = await commMethods.apiGETmethod(apiURL.locationManagementListURL, null, this.userSessionData);
            console.log('JSON :::locationManagementListJSON::: ' + JSON.stringify(locationManagementListJSON.data));
            if (locationManagementListJSON != undefined && locationManagementListJSON.data.status == "found") {
                this.locationmanageTBLJSONS = locationManagementListJSON.data;
                this.locationArrayList = locationManagementListJSON.data.details;
            } else {
                this.locationmanageTBLJSONS = null;
                this.locationArrayList = [];
            }
        },
        async getDeviceDetailsList() {
            let deviceManagementListLJSON = await commMethods.apiGETmethod(apiURL.deviceManagementListURL, null, this.userSessionData);
            console.log('JSON :::deviceManagementListLJSON::: ' + JSON.stringify(deviceManagementListLJSON.data));
            if (deviceManagementListLJSON != undefined && deviceManagementListLJSON.data.status == "found") {
                this.devicemanageTBLJSONS = deviceManagementListLJSON.data;
            } else {
                this.devicemanageTBLJSONS = null;
            }
        },
		async getDeviceaAndMETSerDetailsList() {
            let serialnumListJSON = await commMethods.apiGETmethod(apiURL.serialnumListURL, null, this.userSessionData);
            console.log('JSON :::serialnumListJSON::: ' + JSON.stringify(serialnumListJSON.data));
            if (serialnumListJSON != undefined && serialnumListJSON.data.status == "found") {
				this.deviceSerialArr = serialnumListJSON.data.dev_ser_details.filter(function (item) {
                        return item.configured == "Not Configured"
                    });
				this.meterSerialArr = serialnumListJSON.data.met_ser_details.filter(function (item) {
                       return item.configured == "Not Configured"
                });
            } else {
                this.deviceSerialArr = [];
				this.meterSerialArr = [];
            }
        },
        beforeOpen() {},
        beforeClose() {},
        opened(e) {},
        closed(e) {},
        showlocationmanagementpopup() {
            this.$modal.show("locationmangementpopup");
        },
        hidelocationmanagementpopup() {
            this.$modal.hide("locationmangementpopup");
        },
        addlocationmanagementpopup() {
            this.showlocationmanagementpopup();
            this.orgNameTxt = "";
            this.locationNameTxt = "";
            this.locationpopupsavebuttontext = "Save";
            this.locationmangementpopupTitle = "Add Location";
        },
        locationEditFunction(id, locIndex) {
            this.showlocationmanagementpopup();
            this.locationmangementpopupTitle = "Add Location";
            this.locationmangementEditID = id;
            this.orgNameTxt = this.locationmanageTBLJSONS.details[locIndex].org_name;
            this.locationNameTxt = this.locationmanageTBLJSONS.details[locIndex].loc_name;
            this.locationpopupsavebuttontext = "Update";
        },
        async locationDeleteFunction(id, locIndex) {
            this.isLoading = true;
            var locname = this.locationmanageTBLJSONS.details[locIndex].loc_name;
            if (this.devicemanageTBLJSONS != null) {
                for (var devInx = 0; devInx < this.devicemanageTBLJSONS.details.length; devInx++) {
                    if (this.devicemanageTBLJSONS.details[devInx].loc_name == locname) {
                        this.warningMessage("This location ("+locname+") is used in gateway configuration. To delete location, Remove- gateway (or) Edit - gateway's location name.");
                        this.isLoading = false;
                        return false;
                    }
                }
            }
            var alertval = confirm("Do you want to delete in this Location - ( " + this.locationmanageTBLJSONS.details[locIndex].loc_name + " )");
            if (alertval) {
                var locationManagementDELETEURL = apiURL.locationManagementDELETEURL.replace("$$id$$", id);
                console.log('JSON :::locationManagementDELETEURL::: ' + locationManagementDELETEURL);
                let locationManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(locationManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::locationManagementDELETEJSON::: ' + JSON.stringify(locationManagementDELETEJSON.data));
                if (locationManagementDELETEJSON != undefined && locationManagementDELETEJSON.data.status == "success") {
                    await this.getLocationDetailsList();
					await this.getDeviceDetailsList();
                    this.successMessage("Location ("+locname + ") - Deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(locationManagementDELETEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        async saveLocationConfigFunction() {
            this.isLoading = true;
            if (this.orgNameTxt == "") {
                this.warningMessage("Please enter organization name");
                this.isLoading = false;
                return false;
            }
            if (this.locationNameTxt == "") {
                this.warningMessage("Please enter location name");
                this.isLoading = false;
                return false;
            }
            //New Mode POST FUNCTION
            if (this.locationpopupsavebuttontext == "Save") {
                if (this.locationmanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.locationmanageTBLJSONS.details.filter(function (item) {
                        return item.loc_name == _this.locationNameTxt;
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("Location name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var newmodeJSON = {
                    org_name: this.orgNameTxt,
                    loc_name: this.locationNameTxt,
                }
                var locationManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.locationManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('locationManagementADDJSON :: ' + JSON.stringify(locationManagementADDJSON));
                if (locationManagementADDJSON != undefined && locationManagementADDJSON.data.status == "success") {
                    this.successMessage("Location ("+this.locationNameTxt + ") - Added Successfully");
                    await this.getLocationDetailsList();
					await this.getDeviceDetailsList();
                    this.hidelocationmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(locationManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                if (this.locationmanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.locationmanageTBLJSONS.details.filter(function (item) {
                        return item.loc_name == _this.locationNameTxt && item.id != _this.locationmangementEditID;
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("Location name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var editmodeJSON = {
                    id: this.locationmangementEditID,
                    org_name: this.orgNameTxt,
                    loc_name: this.locationNameTxt,
                }
                var locationManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.locationManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('locationManagementUPDATEJSON :: ' + JSON.stringify(locationManagementUPDATEJSON));
                if (locationManagementUPDATEJSON != undefined && locationManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("Location ("+this.locationNameTxt + ") - Updated Successfully");
                    await this.getLocationDetailsList();
					await this.getDeviceDetailsList();
                    this.hidelocationmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(locationManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        changeLocationMethod(locname) {
            if (this.locationmanageTBLJSONS != null) {
                var newarr = this.locationmanageTBLJSONS.details.filter(function (item) {
                    return item.loc_name == locname;
                });
                // console.log('newarr :'+JSON.stringify(newarr));
                if (newarr != undefined || newarr != null) {
                    this.devlocIdTXT = newarr[0].id;
                }
            }
        },
        async devicemanagementAddFunction() {
			this.isLoading = true;
			await this.getDeviceaAndMETSerDetailsList();
            this.showdevicemanagementpopup();
            this.devicemangementpopupTitle = "Add Gateway";
            this.devLocNameTxt = "Choose Location";
            this.devlocIdTXT = "";
            this.deviceNameTxt = "";
            this.deviceSerialTxt = "Choose Gateway Serial";
			this.deviceSerialEditTxt = '';
			this.devSerialFlag = false;
            this.devmetconnetTypeTxt = "Choose Connect Meter Type";
            // this.devModelTxt = "Choose Gateway Model";
            this.devicepopupsavebuttontext = "Save";
			this.isLoading = false;
        },
        deviceEditFunction(id, rowindex) {
            this.showdevicemanagementpopup();
            this.devicemangementpopupTitle = "Update Gateway";
            this.devmanagemntEditID = id;
            this.devlocIdTXT = this.devicemanageTBLJSONS.details[rowindex].loc_id;
            this.devLocNameTxt = this.devicemanageTBLJSONS.details[rowindex].loc_name;
            this.deviceNameTxt = this.devicemanageTBLJSONS.details[rowindex].dev_name;
            this.deviceSerialTxt = this.devicemanageTBLJSONS.details[rowindex].dev_sn;
			this.deviceSerialEditTxt = this.devicemanageTBLJSONS.details[rowindex].dev_sn;
			this.devSerialFlag = true;
            this.devmetconnetTypeTxt = this.devicemanageTBLJSONS.details[rowindex].met_type;
            // this.devModelTxt = this.devicemanageTBLJSONS.details[rowindex].dev_model;
            this.devicepopupsavebuttontext = "Update";
        },
        async deviceDeleteFunction(id, rowIndex) {
            var alertval = confirm("Do you want to delete in this Gateway - ( " + this.devicemanageTBLJSONS.details[rowIndex].dev_name + " ) If Ok, All the configured meters of the gateway will be removed.");
            var dvname = this.devicemanageTBLJSONS.details[rowIndex].dev_name;
            if (alertval) {
                this.isLoading = true;
                var deviceManagementDELETEURL = apiURL.deviceManagementDELETEURL.replace("$$id$$", id);
                console.log('JSON :::deviceManagementDELETEURL::: ' + deviceManagementDELETEURL);
                let deviceManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(deviceManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::deviceManagementDELETEJSON::: ' + JSON.stringify(deviceManagementDELETEJSON.data));
                if (deviceManagementDELETEJSON != undefined && deviceManagementDELETEJSON.data.status == "success") {
                    await this.getDeviceDetailsList();
                    this.successMessage("Gateway ("+dvname + ") - Deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(deviceManagementDELETEJSON.data.status);
                    this.isLoading = true;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        showdevicemanagementpopup() {
            this.$modal.show("devicemangementpopup");
        },
        hidedevicemanagementpopup() {
            this.$modal.hide("devicemangementpopup");
        },
		devtypeChange(devType){
			document.getElementById("devModelTxt").selectedIndex = 0;
			this.devModelTxt = "Choose Gateway Model";
		},
        async saveDeviceConfigFunction() {
            this.isLoading = true;
            if (this.devLocNameTxt == "Choose Location") {
                this.warningMessage("Please choose anyone location name");
                this.isLoading = false;
                return false;
            }
            if (this.deviceNameTxt == "") {
                this.warningMessage("Please enter gateway name");
                this.isLoading = false;
                return false;
            }
            if (this.deviceSerialTxt == "Choose Gateway Serial") {
                this.warningMessage("Please enter gateway serial number");
                this.isLoading = false;
                return false;
            }
            if (this.devmetconnetTypeTxt == "Choose Connect Meter Type") {
                this.warningMessage("Please choose anyone connect meter type");
                this.isLoading = false;
                return false;
            }
            // if (this.devModelTxt == "Choose Gateway Model") {
            //     this.warningMessage("Please choose anyone gateway model");
            //     this.isLoading = false;
            //     return false;
            // }
            //New Mode POST FUNCTION
            if (this.devicepopupsavebuttontext == "Save") {
                if (this.devicemanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.devicemanageTBLJSONS.details.filter(function (item) {
                        return item.dev_name == _this.deviceNameTxt
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("Gateway name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var newmodeJSON = {
                    loc_id: this.devlocIdTXT,
                    loc_name: this.devLocNameTxt,
                    dev_name: this.deviceNameTxt,
                    dev_sn: this.deviceSerialTxt,
                    met_type: this.devmetconnetTypeTxt,
                    // dev_model: this.devModelTxt
                }
                console.log(apiURL.deviceManagementADDURL + ':: URL :: ' + JSON.stringify(newmodeJSON));
                var deviceManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.deviceManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('deviceManagementADDJSON :: ' + JSON.stringify(deviceManagementADDJSON));
                if (deviceManagementADDJSON != undefined && deviceManagementADDJSON.data.status == "success") {
                    this.successMessage("Gateway Saved Successfully");
                    await this.getDeviceDetailsList();
                    this.hidedevicemanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(deviceManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                if (this.devicemanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.devicemanageTBLJSONS.details.filter(function (item) {
                        return item.dev_name == _this.deviceNameTxt && item.id != _this.devmanagemntEditID
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("Gateway name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var editmodeJSON = {
                    id: this.devmanagemntEditID,
                    loc_id: this.devlocIdTXT,
                    loc_name: this.devLocNameTxt,
                    dev_name: this.deviceNameTxt,
                    dev_sn: this.deviceSerialTxt,
                    met_type: this.devmetconnetTypeTxt,
                    // dev_model: this.devModelTxt
                }
                console.log(apiURL.deviceManagementUPDATEURL + ':: URL :: ' + JSON.stringify(editmodeJSON));
                var deviceManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.deviceManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('deviceManagementUPDATEJSON :: ' + JSON.stringify(deviceManagementUPDATEJSON));
                if (deviceManagementUPDATEJSON != undefined && deviceManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("Gateway Updated Successfully");
                    await this.getDeviceDetailsList();
                    this.hidedevicemanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(deviceManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        showmeterDetailspopup() {
            this.$modal.show("meterDetailspopup");
        },
        hidemeterDetailspopup() {
            this.$modal.hide("meterDetailspopup");
        },
        addMeterDetailsFunction(id, rowindex) {
            this.showmeterDetailspopup();
            this.deviceRowIndex = rowindex;
            this.meterDetailspopupTitle = this.devicemanageTBLJSONS.details[rowindex].dev_name+" - Meter Details";
            this.deviceMeterTBLJSONS = this.devicemanageTBLJSONS.details[rowindex];
            console.log('---deviceMeterTBLJSONS-->>>>>>' + JSON.stringify(this.deviceMeterTBLJSONS))
        },
        showAddmeterDetailspopup() {
            this.$modal.show("addMeterDetailspopup");
        },
        hideAddMeterDetailspopup() {
            this.$modal.hide("addMeterDetailspopup");
        },
        async addMeterpopup() {
			this.isLoading = true;
			await this.getDeviceaAndMETSerDetailsList();
            this.showAddmeterDetailspopup();
            this.addMeterDetailspopupTitle = "Add Meter";
            this.devMetId = "";
			this.deviceMeterStatusTXT = "Choose Meter Status";
            this.deviceMeternameTXT = "";
			this.metSerialFlag = false;
            this.deviceMeterSerialTXT = "Choose Meter Serial";
			this.deviceMeterSerialEditTXT = "";
            this.deviceMeterAddressTXT = "";
            this.deviceMeterPasswordTXT = "";
            this.deviceMeterCTRatioTXT = "";
            this.deviceMeterPTRatioTXT = "";
            this.addMeterDetailsTitlesavebuttontext = "Save";
			this.isLoading = false;
        },
        meterDetailEditFunction(id, deviceindex, rowindex) {
            this.showAddmeterDetailspopup();
            this.addMeterDetailspopupTitle = "Update Meter";
            this.devMetId = id;
			this.metSerialFlag = true;
			this.deviceMeterStatusTXT = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_enable;
            this.deviceMeternameTXT = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_name;
            this.deviceMeterSerialTXT = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_sn;
            this.deviceMeterAddressTXT = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_addr;
            this.deviceMeterPasswordTXT = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_pass;
			this.deviceMeterSerialEditTXT = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_sn;
            this.deviceMeterCTRatioTXT = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].ct_ratio;
            this.deviceMeterPTRatioTXT =  this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].pt_ratio;
            this.addMeterDetailsTitlesavebuttontext = "Update";
        },
        async meterDetailDeleteFunction(id, deviceindex, rowindex) {
            var metname = this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_name;
            var devid = this.devicemanageTBLJSONS.details[deviceindex].id;
            var alertval = confirm("Do you want to delete in this Meter - ( " + this.devicemanageTBLJSONS.details[deviceindex].met_details[rowindex].met_name + " )");
            if (alertval) {
                this.isLoading = true;
                var meterManagementDELETEURL = apiURL.meterManagementDELETEURL.replace("$$id$$", id).replace("$$dev_id$$", devid);
                console.log('JSON :::meterManagementDELETEURL::: ' + meterManagementDELETEURL);
                let meterManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(meterManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::meterManagementDELETEJSON::: ' + JSON.stringify(meterManagementDELETEJSON.data));
                if (meterManagementDELETEJSON != undefined && meterManagementDELETEJSON.data.status == "success") {
                    await this.getDeviceDetailsList();
                    await this.addMeterDetailsFunction(devid, deviceindex);
                    this.successMessage(metname + " - Deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(meterManagementDELETEJSON.data.status);
                    this.isLoading = true;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        isNumberwithslash: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            console.log("===charCode=>>"+charCode);
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46 && charCode !== 47
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        async saveMeterDetailsFunction() {
            this.isLoading = true;
			if (this.deviceMeterStatusTXT == "Choose Meter Status") {
                this.warningMessage("Please choose meter status");
                this.isLoading = false;
                return false;
            }
            if (this.deviceMeternameTXT == "") {
                this.warningMessage("Please enter meter name");
                this.isLoading = false;
                return false;
            }
            // if (this.deviceMeterSerialTXT == "") {
            //     this.warningMessage("Please enter meter serial number");
            //     this.isLoading = false;
            //     return false;
            // }
            if (this.deviceMeterAddressTXT == "") {
                this.warningMessage("Please enter meter slave ID");
                this.isLoading = false;
                return false;
            }
            if (this.deviceMeterCTRatioTXT == "") {
                this.warningMessage("Please enter meter CT Ratio");
                this.isLoading = false;
                return false;
            }
            if (this.deviceMeterPTRatioTXT == "") {
                this.warningMessage("Please enter meter PT Ratio");
                this.isLoading = false;
                return false;
            }
            
            if (this.deviceMeterPasswordTXT == "") {
                this.warningMessage("Please enter meter password");
                this.isLoading = false;
                return false;
            }
			// if(this.deviceMeterTBLJSONS.met_details.length > 4){
			// 	this.warningMessage("Unable to add meter! Maximum meters (5) already configured");
            //     this.isLoading = false;
            //     return false;
			// }
            //New Mode POST FUNCTION
            var devname = this.devicemanageTBLJSONS.details[this.deviceRowIndex].dev_name;
            var devid = this.devicemanageTBLJSONS.details[this.deviceRowIndex].id;
            if (this.addMeterDetailsTitlesavebuttontext == "Save") {
                // deviceMeterTBLJSONS.met_details
                var _this = this;
                var newarr = this.deviceMeterTBLJSONS.met_details.filter(function (item) {
                    return item.met_name == _this.deviceMeternameTXT
                });
                if (newarr.length != 0) {
                    this.warningMessage("Meter name already exists!");
                    this.isLoading = false;
                    return false;
                }
                var newmodeJSON = {
                    dev_id: devid,
                    dev_name: devname,
					met_status: this.deviceMeterStatusTXT,
                    met_name: this.deviceMeternameTXT,
                    // met_sn: this.deviceMeterSerialTXT,
                    met_addr: this.deviceMeterAddressTXT,
                    ct_ratio: this.deviceMeterCTRatioTXT,
                    pt_ratio: this.deviceMeterPTRatioTXT,
                    met_pass: this.deviceMeterPasswordTXT
                }
                console.log(apiURL.meterManagementADDURL + ':: URL :: ' + JSON.stringify(newmodeJSON));
                var meterManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.meterManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('meterManagementADDJSON :: ' + JSON.stringify(meterManagementADDJSON));
                if (meterManagementADDJSON != undefined && meterManagementADDJSON.data.status == "success") {
                    this.successMessage("Saved Successfully");
                    await this.getDeviceDetailsList();
                    await this.addMeterDetailsFunction(devid, this.deviceRowIndex);
                    this.hideAddMeterDetailspopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(meterManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                var _this = this;
                var newarr = this.deviceMeterTBLJSONS.met_details.filter(function (item) {
                    return item.met_name == _this.deviceMeternameTXT && item.id != _this.devMetId
                });
                if (newarr.length != 0) {
                    this.warningMessage("Meter name already exists!");
                    this.isLoading = false;
                    return false;
                }
                var editmodeJSON = {
                    met_id: this.devMetId,
                    dev_id: devid,
                    dev_name: devname,
					met_status: this.deviceMeterStatusTXT,
                    met_name: this.deviceMeternameTXT,
                    // met_sn: this.deviceMeterSerialTXT,
                    met_addr: this.deviceMeterAddressTXT,
                    ct_ratio: this.deviceMeterCTRatioTXT,
                    pt_ratio: this.deviceMeterPTRatioTXT,
                    met_pass: this.deviceMeterPasswordTXT
                }
                console.log(apiURL.meterManagementUPDATEURL + ':: URL :: ' + JSON.stringify(editmodeJSON));
                var meterManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.meterManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('meterManagementUPDATEJSON :: ' + JSON.stringify(meterManagementUPDATEJSON));
                if (meterManagementUPDATEJSON != undefined && meterManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("Updated Successfully");
                    await this.getDeviceDetailsList();
                    await this.addMeterDetailsFunction(devid, this.deviceRowIndex);
                    this.hideAddMeterDetailspopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(meterManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        showpassword1() {
            if (this.typevalue1 === "password") {
                this.typevalue1 = "text";
                this.imgvalue1 = "assets/images/password_open.png";
            } else {
                this.typevalue1 = "password";
                this.imgvalue1 = "assets/images/password_close.png";
            }
        },
        successMessage(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        infoMessage(msg) {
            this.$notice.info({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        errorMessage(msg) {
            this.$notice.error({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
    },
    watch: {},
};
</script>

<style scoped>
table tr th,
table tr td {
    text-align: center;
    font-weight: 500;
}

.eyeimg {
    right: 20px;
    position: absolute;
    top: 12px;
    width: 20px;
    cursor: pointer;
}
</style>
