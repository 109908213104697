<template>
<div class="pcoded-main-container">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="row">
                <!-- [ sample-page ] start -->
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-2">
                                    <h5 style="margin-top: 10px;"><i class="fa fa-user text-c-blue" aria-hidden="true"></i> User Management</h5>
                                </div>
                                <div class="col-lg-9">
                                </div>
                                <div class="col-lg-1 text-right">
                                    <button id="usermanagementaddBTN" v-if="userSessionData.role != 'Operator'" class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add User" @click="usermanagementAddFunction()">
                                        <i class="fa fa-plus"></i> Add User
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="usermanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-650)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="usermanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px">#</th>
                                                <th class="text-left">role</th>
                                                <th class="text-left">User Name</th>
                                                <th class="text-left">User Full Name</th>
                                                <th class="text-left">E-Mail ID</th>
                                                <th class="text-left">Phone Number</th>
                                                <th v-if="userSessionData.role != 'Operator'">Edit</th>
                                                <th v-if="userSessionData.role != 'Operator'">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="usermanageTBLJSONS != null">
                                            <tr v-for="(row,index) in usermanageTBLJSONS.user_details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.role}}</td>
                                                <td class="text-left">{{row.user_name}}</td>
                                                <td class="text-left">{{row.user_full_name}}</td>
                                                <td class="text-left">{{row.email}}</td>
                                                <td class="text-left">{{row.ph_no}}</td>
                                                <td style="cursor:pointer" title="Edit" v-if="userSessionData.role != 'Operator'" @click="userEditFunction(row.id,index)">
                                                    <img src="assets/images/edit.png" width="20" />
                                                </td>
                                                <td style="cursor:pointer" title="Delete" v-if="userSessionData.role != 'Operator' && loginuserID != row.id" @click="userDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash text-red" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                                <td style="cursor:not-allowed" title="Access Denied" v-else-if="userSessionData.role != 'Operator'">
                                                    <i class="fa fa-trash" style="color:gray;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="usermanageTBLJSONS ==  null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- [ sample-page ] end -->
            </div>
            <div class="row">
                <!-- [ sample-page ] start -->
                <div class="col-sm-5" style="padding-right: 5px;">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5 style="margin-top: 10px;"> <i class="fa fa-mobile text-c-blue" aria-hidden="true"></i> Email Management For Reports</h5>
                                </div>
                                <div class="col-lg-3">
                                </div>
                                <div class="col-lg-3 text-right">
                                    <button id="smsmanagementaddBTN" v-if="userSessionData.role != 'Operator'" class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Email" @click="smsmanagementAddFunction()">
                                        <i class="fa fa-plus"></i> Add Email
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="smsmanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-655)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="smsmanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px">#</th>
                                                <th class="text-left">User Name</th>
                                                <th class="text-left">Email</th>
                                                <th v-if="userSessionData.role != 'Operator'">Edit</th>
                                                <th v-if="userSessionData.role != 'Operator'">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="smsmanageTBLJSONS != null">
                                            <tr v-for="(row,index) in smsmanageTBLJSONS.details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.user_name}}</td>
                                                <td class="text-left">{{row.email}}</td>
                                                <td style="cursor:pointer" title="Edit" v-if="userSessionData.role != 'Operator'" @click="smsEditFunction(row.id,index)">
                                                    <img src="assets/images/edit.png" width="20" />
                                                </td>
                                                <td style="cursor:pointer" title="Delete" v-if="userSessionData.role != 'Operator'" @click="smsDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="smsmanageTBLJSONS ==  null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7" style="padding-left: 5px;">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5 style="margin-top: 10px;"> <i class="fa fa-envelope text-c-blue" aria-hidden="true"></i> Notification Management For Events</h5>
                                </div>
                                <div class="col-lg-4">
                                </div>
                                <div class="col-lg-2 text-right">
                                    <button id="emailmanagementaddBTN" v-if="userSessionData.role != 'Operator'" class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Email" @click="notfimanagementAddFunction()">
                                        <i class="fa fa-plus"></i> Add Notification
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="emailmanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-655)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="emailmanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px">#</th>
                                                <th class="text-left">Event Type</th>
                                                <th class="text-left">User Name</th>
                                                <th class="text-left">E-Mail ID</th>
                                                <th class="text-left">Phone Number</th>
                                                <th class="text-left">SMS/EMAIL</th>
                                                <th v-if="userSessionData.role != 'Operator'">Edit</th>
                                                <th v-if="userSessionData.role != 'Operator'">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="notfimanageTBLJSONS != null">
                                            <tr v-for="(row,index) in notfimanageTBLJSONS.details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.event_type}}</td>
                                                <td class="text-left">{{row.user_name}}</td>
                                                <td class="text-left">{{row.email_id}}</td>
                                                <td class="text-left">{{row.ph_no}}</td>
                                                <td class="text-left">{{row.sms_email}}</td>
                                                <td style="cursor:pointer" title="Edit" v-if="userSessionData.role != 'Operator'" @click="notfimanagementEditFunction(row.id,index)">
                                                    <img src="assets/images/edit.png" width="20" />
                                                </td>
                                                <td style="cursor:pointer" title="Delete" v-if="userSessionData.role != 'Operator'" @click="notfimanagementDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="notfimanageTBLJSONS ==  null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- [ sample-page ] end -->
            </div>
            <!-- [ Main Content ] end -->
        </div>
    </div>
    <modal name="usermangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{usermangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_closeBTN" type="button" class="btn btn-default" @click="hideusermanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Role
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <select id="userroleTXT" v-model="userroleTXT" class="mx-input">
                            <option value="Super User" v-if="userSessionData.role == 'Super User'">Super User</option>
                            <option value="Admin" selected>Admin</option>
                            <option value="Operator">Operator</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        User Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="userpopupNameTxt" placeholder="enter a user name" @keypress="userIDblockSpecialChar($event)" id="userpopupNameTxt" maxlength="32" class="mx-input" name="username" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        User Full Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="userpopupFullNameTxt" placeholder="enter a user full name" @keypress="userIDblockSpecialChar($event)" id="userpopupFullNameTxt" class="mx-input" maxlength="32" name="userfullname" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Password
                        <span style="color: red">*</span> <i class="fa fa-info-circle text-c-red" title="Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters"></i>:

                    </div>
                    <div class="col-lg-8">
                        <input class="mx-input" v-model="userpopuppasswordTXT" placeholder="enter a password" :type="typevalue" maxlength="32" id="userpasswordID" :disabled="passDisableflag" @keyup="passwordEventListener()" title="Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters" required />
                        <img id="passwdimg" :src="imgvalue" class="eyeimg" @click="showpassword" v-show="!passDisableflag" />
                        <br>
                        <span v-if="userpopuppasswordTXT != '' && userpopupsavebuttontext == 'Save'" id="StrengthDisp" class="badge displayBadge">Weak</span>
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Confirm Password
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input class="mx-input" :type="typevalue1" placeholder="enter a confirm password" maxlength="32" v-model="userpopuppasswordconfrimTXT" id="confrimpasswordID" :disabled="passDisableflag" />
                        <img id="cnpasswdimg" :src="imgvalue1" class="eyeimg" @click="showpassword1" v-show="!passDisableflag" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Email
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input id="userpopupmailIDTxt" class="mx-input" placeholder="enter a email" v-model="userpopupmailIDTxt" type="text" />
                    </div>
                </div>

                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Phone Number
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input id="PhoneTxtbox" class="mx-input" type="text" placeholder="enter a phone number" v-model="userpopupPhoneTxt" maxlength="10" @keypress="isNumber($event)" />
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createusermanagementBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveUserConfigFunction()">{{userpopupsavebuttontext}}</a>
                <a id="usermanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideusermanagementpopup()">Cancel</a>
            </div>
        </div>
    </modal>

    <!--SMS mangement popup-->
    <modal name="smsmangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{smsmangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_smscloseBTN" type="button" class="btn btn-default" @click="hidesmsmanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        User Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="smspopupNameTxt" placeholder="enter a User Name" @keypress="userIDblockSpecialChar($event)" id="smspopupNameTxt" maxlength="32" class="mx-input" name="username" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Email
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input id="smspopupPhoneTxt" class="mx-input" placeholder="enter a Email ID" type="text" v-model="smspopupPhoneTxt" />
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createsmsmanagementBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveSmsConfigFunction()">{{smspopupsavebuttontext}}</a>
                <a id="smsmanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hidesmsmanagementpopup()">Cancel</a>
            </div>
        </div>
    </modal>
    <!--Email mangement popup-->
    <modal name="emailmangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{emailmangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_emailcloseBTN" type="button" class="btn btn-default" @click="hideemailmanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Choose Event Type
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <select id="notfipopupTypeTxt" v-model="notfipopupTypeTxt" class="mx-input">
                            <option value="Event Type" selected disabled>Event Type</option>
							<option value="All">All</option>
                            <option value="Communication Events">Communication Events</option>
                            <option value="Meter Events">Meter Events</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        User Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text" v-model="notfipopupNameTxt" placeholder="enter a User Name" @keypress="userIDblockSpecialChar($event)" id="notfipopupNameTxt" maxlength="32" class="mx-input" name="username" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Email
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input id="notfipopupEmailTxt" placeholder="enter a Email ID" class="mx-input" type="text" v-model="notfipopupEmailTxt" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Phone Number
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input id="notfipopupPhTxt" class="mx-input" placeholder="enter a Phone Number" type="text" v-model="notfipopupPhTxt" maxlength="10" @keypress="isNumber($event)" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        SMS / EMAIL
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8" style="display: flex;">
                        <div class="form-check" style="margin-top: 5px;">
                            <input class="form-check-input" type="radio" value="Sms" v-model="notfismsemailCHKBXTXT" name="notfismsemailCHKBXTXT" id="SMSCHKBX" checked>
                            <label class="form-check-label" for="SMSCHKBX">
                                SMS
                            </label>
                        </div>
                        &nbsp; &nbsp;
                        <div class="form-check" style="margin-top: 5px;">
                            <input class="form-check-input" type="radio" value="Email" v-model="notfismsemailCHKBXTXT" name="notfismsemailCHKBXTXT" id="EMAILCHKBX">
                            <label class="form-check-label" for="EMAILCHKBX">
                                EMAIL </label>
                        </div>
                        &nbsp; &nbsp;
                        <div class="form-check" style="margin-top: 5px;">
                            <input class="form-check-input" type="radio" value="Both" v-model="notfismsemailCHKBXTXT" name="notfismsemailCHKBXTXT" id="BOTHCHKBX">
                            <label class="form-check-label" for="BOTHCHKBX">
                                Both </label>
                        </div>
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createemailmanagementBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="savenotfimanageConfigFunction()">{{emailpopupsavebuttontext}}</a>
                <a id="emailmanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideemailmanagementpopup()">Cancel</a>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js'

let timeout;

let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
export default {
    name: "UserManagement",
    components: {
        VueElementLoading,
    },
    data() {
        return {
            timeout: null,
            strongPassword: new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'),
            mediumPassword: new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'),
            pageLayoutHeight: 0,
            isLoading: false,
            usermanageTBLJSONS: null,
            userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
            loginuserID: null,
            usermangementpopupTitle: 'Add User',
            userEditID: '',
            userroleTXT: "Admin",
            userpopupNameTxt: '',
            userpopupFullNameTxt: '',
            passDisableflag: false,
            userpopuppasswordTXT: '',
            userpopuppasswordconfrimTXT: '',
            userpopupmailIDTxt: '',
            userpopupPhoneTxt: '',
            userpopupsavebuttontext: 'Save',
            typevalue: "password",
            typevalue1: "password",
            imgvalue: "assets/images/password_close.png",
            imgvalue1: "assets/images/password_close.png",
            smsmanageTBLJSONS: null,
            notfimanageTBLJSONS: null,
            smsmangementpopupTitle: '',
            smspopupNameTxt: '',
            smspopupPhoneTxt: '',
            smspopupsavebuttontext: '',
            emailmangementpopupTitle: '',
            notfipopupTypeTxt: 'Event Type',
            notfipopupNameTxt: '',
            notfipopupEmailTxt: '',
            notfipopupPhTxt: '',
            notfismsemailCHKBXTXT: 'Both',
            emailpopupsavebuttontext: '',
        };
    },
    props: {},
    async mounted() {
        if (this.userSessionData != null) {
            this.loginuserID = this.userSessionData.id;
        }
        console.log('loginuserID ::', this.loginuserID);
        this.isLoading = true;
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        this.pageLayoutHeight = window.innerHeight;
        await this.getUserConfigList();
        await this.getSMSConfigList();
        await this.getNotfiConfigList();
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    },

    methods: {
        beforeOpen() {},
        beforeClose() {},
        opened(e) {},
        closed(e) {},
        async getUserConfigList() {
            let userManagementListJSON = await commMethods.apiGETWthUsersmethod(apiURL.userManagementListURL, null, this.userSessionData);
            console.log('JSON :::userManagementListJSON::: ' + JSON.stringify(userManagementListJSON.data));
            if (userManagementListJSON != undefined && userManagementListJSON.data.status == "found") {
                this.usermanageTBLJSONS = userManagementListJSON.data;
            } else {
                this.usermanageTBLJSONS = null;
            }
        },

        async getSMSConfigList() {
            let smsManagementListJSON = await commMethods.apiGETmethod(apiURL.smsManagementListURL, null, this.userSessionData);
            console.log('JSON :::smsManagementListJSON::: ' + JSON.stringify(smsManagementListJSON.data));
            if (smsManagementListJSON != undefined && smsManagementListJSON.data.status == "found") {
                this.smsmanageTBLJSONS = smsManagementListJSON.data;
            } else {
                this.smsmanageTBLJSONS = null;
            }
        },
        async getNotfiConfigList() {
            let notfiManagementListJSON = await commMethods.apiGETmethod(apiURL.notfiManagementListURL, null, this.userSessionData);
            console.log('JSON :::notfiManagementListJSON::: ' + JSON.stringify(notfiManagementListJSON.data));
            if (notfiManagementListJSON != undefined && notfiManagementListJSON.data.status == "found") {
                this.notfimanageTBLJSONS = notfiManagementListJSON.data;
            } else {
                this.notfimanageTBLJSONS = null;
            }
        },
        userEditFunction(id, rowIndex) {
            this.openusermanagementpopup();
            this.userEditID = id;
            this.userpopupsavebuttontext = "Update";
            this.usermangementpopupTitle = "Update User";
            this.userroleTXT = this.usermanageTBLJSONS.user_details[rowIndex].role;
            this.userpopupNameTxt = this.usermanageTBLJSONS.user_details[rowIndex].user_name;
            this.userpopupFullNameTxt = this.usermanageTBLJSONS.user_details[rowIndex].user_full_name;
            this.passDisableflag = true;
            this.userpopuppasswordTXT = this.usermanageTBLJSONS.user_details[rowIndex].password;
            this.userpopuppasswordconfrimTXT = this.usermanageTBLJSONS.user_details[rowIndex].password;
            this.userpopupmailIDTxt = this.usermanageTBLJSONS.user_details[rowIndex].email;
            this.userpopupPhoneTxt = this.usermanageTBLJSONS.user_details[rowIndex].ph_no;
        },
        async userDeleteFunction(id, rowIndex) {
            this.isLoading = true;
            var userNameTxt = this.usermanageTBLJSONS.user_details[rowIndex].user_name;
            var alertval = confirm("Do you want to delete in this User Name - " + userNameTxt);
            if (alertval) {
                console.log('JSON :::userManagementDELETEURL::: ' + apiURL.userManagementDELETEURL);
                var userManagementDELETEURL = apiURL.userManagementDELETEURL.replace("$$id$$", id);
                let userManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(userManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::userManagementDELETEJSON::: ' + JSON.stringify(userManagementDELETEJSON.data));
                if (userManagementDELETEJSON != undefined && userManagementDELETEJSON.data.status == "success") {
                    await this.getUserConfigList();
                    this.successMessage(userNameTxt + " - deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(userManagementDELETEJSON.data.status);
                    this.isLoading = true;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        async saveUserConfigFunction() {
            this.isLoading = true;
            if (this.userroleTXT == "") {
                this.warningMessage("Please choose user role");
                this.isLoading = false;
                return false;
            }
            if (this.userpopupNameTxt == "") {
                this.warningMessage("Please enter user name");
                this.isLoading = false;
                return false;
            }
            if (this.userpopupFullNameTxt == "") {
                this.warningMessage("Please enter user full name");
                this.isLoading = false;
                return false;
            }
            if (this.userpopuppasswordTXT == "") {
                this.warningMessage("Please enter user password");
                this.isLoading = false;
                return false;
            }
            // if (this.userpopuppasswordTXT.length < 8) {
            //     this.warningMessage("User password minimum 8 characters");
            //     this.isLoading = false;
            //     return false;
            // }
            let strengthBadge = document.getElementById('StrengthDisp');
            if (strengthBadge) {
                console.log('strengthBadge TXT :' + strengthBadge.textContent);
                if (strengthBadge.textContent != 'Strong') {
                    this.warningMessage("Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters");
                    this.isLoading = false;
                    return false;
                }
            }
            if (this.userpopuppasswordconfrimTXT == "") {
                this.warningMessage("Please enter user confirm password");
                this.isLoading = false;
                return false;
            }
            if (this.userpopuppasswordconfrimTXT != this.userpopuppasswordTXT) {
                this.warningMessage("password did not match");
                this.isLoading = false;
                return false;
            }
            if (this.userpopupmailIDTxt == "") {
                this.warningMessage("Please enter user Mail - ID");
                this.isLoading = false;
                return false;
            }
            var emailflag = this.EmailAddressvalid(this.userpopupmailIDTxt);
            if (!emailflag) {
                this.warningMessage("Invalid Mail ID");
                this.isLoading = false;
                return false;
            }
            if (this.userpopupPhoneTxt == "") {
                this.warningMessage("Please enter user phone number");
                this.isLoading = false;
                return false;
            }
            if (this.userpopupPhoneTxt.length != 10) {
                this.warningMessage("Please enter valid user phone number");
                this.isLoading = false;
                return false;
            }
            //New Mode POST FUNCTION
            if (this.userpopupsavebuttontext == "Save") {
                if (this.usermanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.usermanageTBLJSONS.user_details.filter(function (item) {
                        return item.user_name == _this.userpopupNameTxt
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("User name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                    for (var km = 0; km < this.usermanageTBLJSONS.user_details.length; km++) {
                        if (this.usermanageTBLJSONS.user_details[km].role == "Super User" && this.userroleTXT == "Super User") {
                            this.warningMessage("Super User role is already activated, so you cannot to create Super User.");
                            this.isLoading = false;
                            return false;
                        }
                    }
                }
                var newmodeJSON = {
                    role: this.userroleTXT,
                    user_name: this.userpopupNameTxt,
                    user_full_name: this.userpopupFullNameTxt,
                    password: this.userpopuppasswordTXT,
                    email: this.userpopupmailIDTxt,
                    ph_no: this.userpopupPhoneTxt,
                }
                console.log('newmodeJSON :: ' + JSON.stringify(newmodeJSON));
                var userManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.userManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('userManagementADDJSON :: ' + JSON.stringify(userManagementADDJSON));
                if (userManagementADDJSON != undefined && userManagementADDJSON.data.status == "success") {
                    this.successMessage("Saved Successfully");
                    await this.getUserConfigList();
                    this.hideusermanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(userManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                // Edit Mode POST FUNCTION

                for (var km = 0; km < this.usermanageTBLJSONS.user_details.length; km++) {
                    var empid = this.usermanageTBLJSONS.user_details[km].id;
                    if (empid != this.userEditID) {
                        var user_name22 = this.usermanageTBLJSONS.user_details[km].user_name;
                        var mail_id22 = this.usermanageTBLJSONS.user_details[km].email;
                        var phone_no22 = this.usermanageTBLJSONS.user_details[km].ph_no;
                        if (this.usermanageTBLJSONS.user_details[km].role == "Super User" && this.userroleTXT == "Super User") {
                            this.warningMessage("Install User role is already activated, so you cannot to create Install User.");
                            this.isLoading = false;
                            return false;
                        }
                        if (this.userpopupNameTxt == user_name22) {
                            this.warningMessage("User name is already used. Try another.");
                            this.isLoading = false;
                            return false;
                        } else if (mail_id22 == this.userpopupmailIDTxt) {
                            this.isLoading = false;
                            this.warningMessage("Mail ID is already used. Try another.");
                            return false;

                        } else if (phone_no22 == this.userpopupPhoneTxt) {
                            this.isLoading = false;
                            this.warningMessage("Phone number is already used. Try another.");
                            return false;
                        }
                    }
                }
                var editmodeJSON = {
                    id: this.userEditID,
                    role: this.userroleTXT,
                    user_name: this.userpopupNameTxt,
                    user_full_name: this.userpopupFullNameTxt,
                    password: this.userpopuppasswordTXT,
                    email: this.userpopupmailIDTxt,
                    ph_no: this.userpopupPhoneTxt,
                }
                var userManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.userManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('userManagementUPDATEJSON :: ' + JSON.stringify(userManagementUPDATEJSON));
                if (userManagementUPDATEJSON != undefined && userManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("Saved Successfully");
                    await this.getUserConfigList();
                    this.hideusermanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(userManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },

        StrengthChecker(PasswordParameter) {
            // We then change the badge's color and text based on the password strength
            let password = document.getElementById('userpasswordID');
            let strengthBadge = document.getElementById('StrengthDisp');
			if (strengthBadge) {
                if (strongPassword.test(PasswordParameter)) {
                    strengthBadge.style.backgroundColor = "green"
                    strengthBadge.textContent = 'Strong'
					strengthBadge.style.color = "white";
                } else if (mediumPassword.test(PasswordParameter)) {
                    strengthBadge.style.backgroundColor = 'blue'
                    strengthBadge.textContent = 'Medium';
					strengthBadge.style.color = "white";
                } else {
                    strengthBadge.style.backgroundColor = 'red'
                    strengthBadge.textContent = 'Weak';
					strengthBadge.style.color = "black";
                }
            }
        },
        passwordEventListener() {
            console.log('000000<>>');
            //The badge is hidden by default, so we show it
            let password = document.getElementById('userpasswordID');
            let strengthBadge = document.getElementById('StrengthDisp');
            if (strengthBadge) {
                strengthBadge.style.display = 'block';
            }
            clearTimeout(this.timeout);

            //We then call the StrengChecker function as a callback then pass the typed password to it

            this.timeout = setTimeout(() => this.StrengthChecker(password.value), 100);

            //Incase a user clears the text, the badge is hidden again

            if (password.value.length !== 0) {
                if (strengthBadge) {
                    strengthBadge.style.display != 'block';
                }
            } else {
                if (strengthBadge) {
                    strengthBadge.style.display = 'none';
                }
            }
        },
        usermanagementAddFunction() {
            this.openusermanagementpopup();
            this.userpopupsavebuttontext = "Save";
            this.userEditID = "";
            this.userroleTXT = "Admin";
            this.userpopupNameTxt = "";
            this.userpopupFullNameTxt = "";
            this.passDisableflag = false;
            this.userpopuppasswordTXT = "";
            this.userpopuppasswordconfrimTXT = "";
            this.userpopupmailIDTxt = "";
            this.userpopupPhoneTxt = "";
        },
        smsmanagementAddFunction() {
            this.opensmsmanagementpopup();
            this.smspopupsavebuttontext = "Save";
            this.smsmangementpopupTitle = "Add Email";
            this.smsEditID = "";
            this.smspopupNameTxt = "";
            this.smspopupPhoneTxt = "";
        },
        smsEditFunction(id, rowIndex) {
            this.opensmsmanagementpopup();
            this.smspopupsavebuttontext = "Update";
            this.smsmangementpopupTitle = "Update Email";
            this.smsEditID = id;
            this.smspopupNameTxt = this.smsmanageTBLJSONS.details[rowIndex].user_name;
            this.smspopupPhoneTxt = this.smsmanageTBLJSONS.details[rowIndex].email;
        },
        async smsDeleteFunction(id, rowIndex) {
            var alertval = confirm("Do you want to delete this User Email- ( " + this.smsmanageTBLJSONS.details[rowIndex].user_name + " )");
            var smsuser = this.smsmanageTBLJSONS.details[rowIndex].user_name;
            if (alertval) {
				this.isLoading =true;
                var smsManagementDELETEURL = apiURL.smsManagementDELETEURL.replace("$$id$$", id);
                console.log('JSON :::smsManagementDELETEURL::: ' + smsManagementDELETEURL);
                let smsManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(smsManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::smsManagementDELETEJSON::: ' + JSON.stringify(smsManagementDELETEJSON.data));
                if (smsManagementDELETEJSON != undefined && smsManagementDELETEJSON.data.status == "success") {
                    await this.getSMSConfigList();
                    this.successMessage(smsuser + " - deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(smsManagementDELETEJSON.data.status);
                    this.isLoading = true;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        notfimanagementAddFunction() {
            this.openemailmanagementpopup();
            this.emailpopupsavebuttontext = "Save";
            this.emailmangementpopupTitle = "Add Notification";
            this.notfiEditID = "";
            this.notfipopupTypeTxt = "Event Type";
            this.notfipopupNameTxt = "";
            this.notfipopupEmailTxt = "";
            this.notfipopupPhTxt = "";
            this.notfismsemailCHKBXTXT = 'Both';
        },
        notfimanagementEditFunction(id, rowIndex) {
            this.openemailmanagementpopup();
            this.emailpopupsavebuttontext = "Update";
            this.emailmangementpopupTitle = "Update Email";
            this.notfiEditID = id;
            this.notfipopupTypeTxt = this.notfimanageTBLJSONS.details[rowIndex].event_type;
            this.notfipopupNameTxt = this.notfimanageTBLJSONS.details[rowIndex].user_name;
            this.notfipopupEmailTxt = this.notfimanageTBLJSONS.details[rowIndex].email_id;
            this.notfipopupPhTxt = this.notfimanageTBLJSONS.details[rowIndex].ph_no;
            this.notfismsemailCHKBXTXT = this.notfimanageTBLJSONS.details[rowIndex].sms_email;
        },
        async notfimanagementDeleteFunction(id, rowIndex) {
            var alertval = confirm("Do you want to delete in this User Email - ( " + this.notfimanageTBLJSONS.details[rowIndex].user_name + " )");
            var userNameTxt = this.notfimanageTBLJSONS.details[rowIndex].user_name;
            if (alertval) {
                console.log('JSON :::notfiManagementDELETEURL::: ' + apiURL.notfiManagementDELETEURL);
                var notfiManagementDELETEURL = apiURL.notfiManagementDELETEURL.replace("$$id$$", id);
                let notfiManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(notfiManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::notfiManagementDELETEJSON::: ' + JSON.stringify(notfiManagementDELETEJSON.data));
                if (notfiManagementDELETEJSON != undefined && notfiManagementDELETEJSON.data.status == "success") {
                    await this.getNotfiConfigList();
                    this.successMessage(userNameTxt + " - deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(notfiManagementDELETEJSON.data.status);
                    this.isLoading = true;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        openusermanagementpopup() {
            this.$modal.show('usermangementpopup');
        },
        hideusermanagementpopup() {
            this.$modal.hide('usermangementpopup');
        },
        opensmsmanagementpopup() {
            this.$modal.show('smsmangementpopup');
        },
        hidesmsmanagementpopup() {
            this.$modal.hide('smsmangementpopup');
        },
        openemailmanagementpopup() {
            this.$modal.show('emailmangementpopup');
        },
        hideemailmanagementpopup() {
            this.$modal.hide('emailmangementpopup');
        },
        async saveSmsConfigFunction() {
            this.isLoading = true;
            if (this.smspopupNameTxt == "") {
                this.warningMessage("Please enter user name");
                this.isLoading = false;
                return false;
            }
            if (this.smspopupPhoneTxt == "") {
                this.warningMessage("Please enter email id");
                this.isLoading = false;
                return false;
            }
			var emailflag = this.EmailAddressvalid(this.smspopupPhoneTxt);
            if (!emailflag) {
                this.warningMessage("Invalid Mail ID");
                this.isLoading = false;
                return false;
            }
            // if (this.smspopupPhoneTxt.length != 10) {
            //     this.warningMessage("Please enter valid phone number");
            //     this.isLoading = false;
            //     return false;
            // }
            //New Mode POST FUNCTION
            if (this.smspopupsavebuttontext == "Save") {
                if (this.smsmanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.smsmanageTBLJSONS.details.filter(function (item) {
                        return item.email == _this.smspopupPhoneTxt
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("User Email id already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var newmodeJSON = {
                    user_name: this.smspopupNameTxt,
                    email: this.smspopupPhoneTxt,
                }
                console.log(apiURL.smsManagementADDURL + ':: URL :: ' + JSON.stringify(newmodeJSON));
                var smsManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.smsManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('smsManagementADDJSON :: ' + JSON.stringify(smsManagementADDJSON));
                if (smsManagementADDJSON != undefined && smsManagementADDJSON.data.status == "success") {
                    this.successMessage("Saved Successfully");
                    await this.getSMSConfigList();
                    this.hidesmsmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(smsManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                if (this.smsmanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.smsmanageTBLJSONS.details.filter(function (item) {
                        return item.email == _this.smspopupPhoneTxt && item.id != _this.smsEditID
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("User email id already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var editmodeJSON = {
                    id: this.smsEditID,
                    user_name: this.smspopupNameTxt,
                    email: this.smspopupPhoneTxt,
                }
                console.log(apiURL.smsManagementUPDATEURL + ':: URL :: ' + JSON.stringify(editmodeJSON));
                var smsManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.smsManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('smsManagementUPDATEJSON :: ' + JSON.stringify(smsManagementUPDATEJSON));
                if (smsManagementUPDATEJSON != undefined && smsManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("Updated Successfully");
                    await this.getSMSConfigList();
                    this.hidesmsmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(smsManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        async savenotfimanageConfigFunction() {
            this.isLoading = true;
            if (this.notfipopupTypeTxt == "Event Type") {
                this.warningMessage("Please choose event type");
                this.isLoading = false;
                return false;
            }
            if (this.notfipopupNameTxt == "") {
                this.warningMessage("Please enter user name");
                this.isLoading = false;
                return false;
            }
            if (this.notfipopupEmailTxt == "") {
                this.warningMessage("Please enter user email");
                this.isLoading = false;
                return false;
            }
            var emailflag = this.EmailAddressvalid(this.notfipopupEmailTxt);
            if (!emailflag) {
                this.warningMessage("Invalid Mail ID");
                this.isLoading = false;
                return false;
            }
            if (this.notfipopupPhTxt == "") {
                this.warningMessage("Please enter user phone number");
                this.isLoading = false;
                return false;
            }
            if (this.notfipopupPhTxt.length != 10) {
                this.warningMessage("Please enter valid user phone number");
                this.isLoading = false;
                return false;
            }
            //New Mode POST FUNCTION
            if (this.emailpopupsavebuttontext == "Save") {
                if (this.notfimanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.notfimanageTBLJSONS.details.filter(function (item) {
                        return item.user_name == _this.notfipopupNameTxt;
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("User name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var newmodeJSON = {
                    event_type: this.notfipopupTypeTxt,
                    user_name: this.notfipopupNameTxt,
                    email_id: this.notfipopupEmailTxt,
                    ph_no: this.notfipopupPhTxt,
                    sms_email: this.notfismsemailCHKBXTXT,
                }
                console.log(apiURL.notfiManagementADDURL + ':: URL :: ' + JSON.stringify(newmodeJSON));
                var notfiManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.notfiManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('notfiManagementADDJSON :: ' + JSON.stringify(notfiManagementADDJSON));
                if (notfiManagementADDJSON != undefined && notfiManagementADDJSON.data.status == "success") {
                    this.successMessage("Saved Successfully");
                    await this.getNotfiConfigList();
                    this.hideemailmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(notfiManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                if (this.notfimanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.notfimanageTBLJSONS.details.filter(function (item) {
                        return item.user_name == _this.notfipopupNameTxt && item.id != _this.notfiEditID;
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("User name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var editmodeJSON = {
                    id: this.notfiEditID,
                    event_type: this.notfipopupTypeTxt,
                    user_name: this.notfipopupNameTxt,
                    email_id: this.notfipopupEmailTxt,
                    ph_no: this.notfipopupPhTxt,
                    sms_email: this.notfismsemailCHKBXTXT,
                }
                console.log(apiURL.notfiManagementUPDATEURL + ':: URL :: ' + JSON.stringify(editmodeJSON));
                var notfiManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.notfiManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('notfiManagementUPDATEJSON :: ' + JSON.stringify(notfiManagementUPDATEJSON));
                if (notfiManagementUPDATEJSON != undefined && notfiManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("Updated Successfully");
                    await this.getNotfiConfigList();
                    this.hideemailmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(notfiManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        userIDblockSpecialChar(e) {
            var keyCode = e.which;
            if (
                !(
                    (keyCode >= 65 && keyCode <= 90) ||
                    (keyCode >= 97 && keyCode <= 122)
                ) &&
                keyCode != 8 &&
                keyCode != 32
            ) {
                e.preventDefault();
            }
        },
        successMessage(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        infoMessage(msg) {
            this.$notice.info({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        errorMessage(msg) {
            this.$notice.error({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        EmailAddressvalid(str) {
            var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return pattern.test(str); // returns a boolean
        },
        showpassword() {
            if (this.typevalue === "password") {
                this.typevalue = "text";
                this.imgvalue = "assets/images/password_open.png";
            } else {
                this.typevalue = "password";
                this.imgvalue = "assets/images/password_close.png";
            }
        },
        showpassword1() {
            if (this.typevalue1 === "password") {
                this.typevalue1 = "text";
                this.imgvalue1 = "assets/images/password_open.png";
            } else {
                this.typevalue1 = "password";
                this.imgvalue1 = "assets/images/password_close.png";
            }
        },
    },
    watch: {},
};
</script>

<style scoped>
.eyeimg {
    right: 20px;
    position: absolute;
    top: 12px;
    width: 20px;
    cursor: pointer;
}

table tr th,
table tr td {
    text-align: center;
    font-weight: 500;
}
</style>
