<template>
  <div :class="['wrapper', classes]" id="evOverALLPANE" @click="activityMethod" @mouseenter="activityMethod" @mousemove="activityMethod" @mouseleave="activityMethod">
	<!-- <div :class="['wrapper', classes]"> -->
    <dash-header ></dash-header>
    <div class="content-wrapper" style="margin-left:0px">
      <!-- <section class="content-header" style="visibility: hidden;">
        <h1 style="color:#00183e;">
          {{$route.name }}
          <small>{{ $route.meta.description }}</small>
        </h1>
        <ol class="" >
            <li>
            <span style="font-size:12px;">
              <i class="fa fa-clock-o text-green" style="font-size:15px"></i> <span style="font-size:12px;font-weight:bold">-
             </span> </span>
          </li>
          <li>
            <a>
              <i class="fa fa-home"></i>Home</a>
          </li>
         <li class="active">{{$route.name}}</li> 
        </ol>
      </section>     -->
	  <section class="topflexclass" >
      <router-view></router-view>
	</section>
    </div>
    <dash-footer></dash-footer>
  </div>
</template>

<script>
import config from '../config'
import DashFooter from './layout/DashFooter'
import DashHeader from './layout/DashHeader';
import GUIConfig from "@/GUI_Config";
import apiURL from "@/API_Config";
import common_methods from '@/api/common_methods';
export default {
  name: 'Dash',
  components: {
    DashFooter,
    DashHeader
  },
  data: function () {
    return {
      // section: 'Dash',
    apiURL:apiURL,
    userSessionData: JSON.parse(sessionStorage.getItem("loginsession")),
	  idealTimeOut: GUIConfig.idelTimer, //   14400 seconds => 4 hours // 30 seconds
	  idleSecondsTimer: null,
	  idleSecondsCounter: 0,
    isTabClosed:false,
    currdateTime:'',
    classes: {
        fixed_layout: config.fixedLayout,
        hide_logo: config.hideLogoOnMobile
      }
    }
  },
  computed: {
  },
 async mounted(){
    document.onclick = function () {
  	console.log('WORKING onclick');
  	this.idleSecondsCounter = 0;
  	};
  document.onmousemove = function () {
  	 console.log('WORKING onmousemove : '+this.idleSecondsTimer);
  	 this.idleSecondsCounter = 0;
  	};
  document.onkeypress = function () {
  	 console.log('WORKING onkeypress');
  	 this.idleSecondsCounter = 0;
  	};

	this.idleSecondsTimer = setInterval(this.CheckIdleTime, 1000);
  setTimeout(async () => {
   await this.callConnectionAliveLoad();
  }, 1000); 

  this.$root.$on("clearDashSectionFunction", () => {
      // your code goes here
      this.clearDashSection();
    });

  //window.addEventListener('unload', this.handleBeforeUnload);
  },
  beforeDestroy() {
   //     window.removeEventListener('unload', this.handleBeforeUnload);
   if(this.connectionAliveTimeInterval != null){
		clearInterval(this.connectionAliveTimeInterval);
	}
	if(this.idleSecondsTimer != null){
		clearInterval(this.idleSecondsTimer);
	}
  },
  destroyed(){
	if(this.connectionAliveTimeInterval != null){
		clearInterval(this.connectionAliveTimeInterval);
	}
	if(this.idleSecondsTimer != null){
		clearInterval(this.idleSecondsTimer);
	}
  },
  watch: {
        isTabClosed(newValue) {
          // This watch will be triggered when the isTabClosed data property changes
          if (newValue) {
            // Add your code here to handle the beforeunload event
            console.log('User left the page or closed the tab');
          }
        }
      },
  methods: {
  clearDashSection(){
    if(this.connectionAliveTimeInterval != null){
		clearInterval(this.connectionAliveTimeInterval);
	  }
	  if(this.idleSecondsTimer != null){
		clearInterval(this.idleSecondsTimer);
  	}
  },
  handleBeforeUnload(event) {
          // This method will be called when the user leaves the page or closes the tab
        //  this.isTabClosed = true;
      console.log("--------------------------->>");
          // Optionally, you can show a confirmation dialog to the user
        //  event.preventDefault();
        
  },
	async callConnectionAliveLoad(){
    await this.getConnectionAliveInfo();
    var api_sent = false;
    var response_received = true;
    this.connectionAliveTimeInterval = setInterval(async () => {
      console.log('api_sent :'+ api_sent + ': response_received :'+ response_received);
      if (api_sent == false && response_received == true) {
        console.log("callConnectionAlive Load API Request Sent");
        api_sent = true;
        response_received = false;
        await this.getConnectionAliveInfo();
        console.log("****callConnectionAliveLoad***********");
        response_received = true;
        api_sent = false;
      }
    }, GUIConfig.commandIntervalTime);
    },
	async getConnectionAliveInfo(){
    console.log("------userSessionData--------:::"+JSON.stringify(this.userSessionData));
	  var connectionAlivejson = {
          user_name: this.userSessionData.user_name,
          password: this.userSessionData.password
    };
    console.log(':::connectionAlivejson::: '+JSON.stringify(connectionAlivejson));
	  let connectionAliveJSON = await common_methods.apiPOSTmethod(apiURL.connectionAliveURL,connectionAlivejson,this.userSessionData);
		console.log(':::getConnectionAliveInfo::: '+JSON.stringify(connectionAliveJSON.data));
		if ((connectionAliveJSON != undefined && connectionAliveJSON.data.status == "terminate") || (connectionAliveJSON != undefined && connectionAliveJSON.data.session_id != this.userSessionData.session_id) || (connectionAliveJSON.data.status == "server error")) {
			clearInterval(this.idleSecondsTimer);
			clearInterval(this.connectionAliveTimeInterval);
			await this.$root.$emit('terminateSesstionFunction', 'Due to new login, This session expired!.');
      return false;
	  	}
	},
	  activityMethod(){
		  console.log('--CALL activityMethod->>>');
		  this.idleSecondsCounter = 0;
	  },
	  async CheckIdleTime() {
			  this.idleSecondsCounter++;
			  console.log('---idleSecondsCounter->>>'+this.idleSecondsCounter);
			  // var oPanel = document.getElementById("timeOut");
			  // if (oPanel) {
			  //     oPanel.innerHTML = (this.idealTimeOut - this.idleSecondsCounter);
			  // }
			  if (this.idleSecondsCounter >= this.idealTimeOut) {
				  clearInterval(this.idleSecondsTimer);
			    clearInterval(this.connectionAliveTimeInterval);
				  await this.logoutSession();
          return false;
			  }
	  },
    async logoutSession(){
      this.isLoading = true;
	  	var logoutSTRJSON =  {
				user_name: this.userSessionData.user_name,
        password: this.userSessionData.password
		};
		  let logoutJSON = await common_methods.apiPOSTmethod(apiURL.logoutURL,logoutSTRJSON,this.userSessionData);
			console.log('logoutJSON  TEST :: '+JSON.stringify(logoutJSON.data));
			if (logoutJSON != undefined && logoutJSON.data.status == "success") {
        this.isLoading = false;
        // alert("Your Session has expired. Please login again.");
        await this.$root.$emit('terminateSesstionFunction' , 'Your Session has expired. Please login again.');
        return false;
			}else{
				this.errorMessage(logoutJSON.data.status);
        this.isLoading = false;
				return false;
			}
    },
  }
}
</script>

<style scoped>
 .topflexclass {
    margin-top: 105px;
}

@media only screen and (min-width: 901px) and (max-width: 1600px) {
  .topflexclass {
    margin-top: 170px;
}
}
@media only screen and (min-width: 501px) and (max-width: 900px) {
  .topflexclass {
    margin-top: 208px;
}
}
@media only screen and (min-width: 101px) and (max-width: 500px) {
  .topflexclass {
    margin-top: 228px;
}
}


</style>
