<template>
<div class="pcoded-main-container">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#857b2b" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="row">
                <!-- [ sample-page ] start -->
                <div class="col-sm-6" style="padding-right: 5px;">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5 style="margin-top: 10px;"><i class="fa fa-cog text-c-blue" aria-hidden="true"></i> Gateway Serial Management</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <button id="serialmanagementaddBTN"  class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Serial" @click="addserialmanagementpopup()">
                                        <i class="fa fa-plus"></i> Add Gateway Serial
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="serialmanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-240)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="serialmanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px">#</th>
                                                <th class="text-left">Serial Number</th>
												<th class="text-left">Gateway Type</th>
                                                <th class="text-left">Gateway Model</th>
                                                <th class="text-left">Gateway Status</th>
                                                <th class="text-left">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="devSerialmanageTBLJSONS != null">
                                            <tr v-for="(row,index) in devSerialmanageTBLJSONS.details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.ser_num}}</td>
												<td class="text-left">{{row.dev_type}}</td>
                                                <td class="text-left">{{row.dev_model}}</td>
                                                <td class="text-left">{{row.status}}
													<small style="color:#1439ed" v-show="row.dev_name != ''"> <b>({{ row.dev_name }})</b></small>
												</td>
                                                <td class="text-left" style="cursor:pointer" title="Delete" @click="devSerialDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="devSerialmanageTBLJSONS == null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
				<div class="col-sm-4" style="padding-right: 5px;" v-show="false">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5 style="margin-top: 10px;"><i class="fa fa-cogs text-c-blue" aria-hidden="true"></i> Meter Serial Management</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <button id="serialmanagementaddBTN"  class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Serial" @click="addMetSerialmanagementpopup()">
                                        <i class="fa fa-plus"></i> Add Meter Serial
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="metmanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-240)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="metmanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px">#</th>
                                                <th>Serial Number</th>
                                                <th>Configured / Not Configured</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="metSerialmanageTBLJSONS != null">
                                            <tr v-for="(row,index) in metSerialmanageTBLJSONS.details" :key="row.id">
                                                <td>{{index+1}}</td>
                                                <td>{{row.ser_num}}</td>
                                                <td class="text-left">{{row.status}} <small v-show="row.dev_name != ''" style="color:#1439ed"> <b>({{ row.dev_name }}</b></small> <small style="color:#1439ed" v-show="row.met_name != ''"> <b> / {{ row.met_name }})</b></small></td>
                                                <td style="cursor:pointer" title="Delete" @click="metSerialDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="metSerialmanageTBLJSONS == null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6" style="padding-right: 5px;">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5 style="margin-top: 10px;"><i class="fa fa-tasks text-c-blue" aria-hidden="true"></i> OBIS Management</h5>
                                </div>
                                <div class="col-lg-6 text-right">
                                    <button id="obismanagementaddBTN"  class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Add Device" @click="obismanagementAddFunction()">
                                        <i class="fa fa-plus"></i> Add OBIS Details
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <template>
                                <div class="table-responsive" id="obismanagementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-240)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="obismanagementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px"># </th>
                                                <th class="text-left">OBIS Code</th>
                                                <th class="text-left">OBIS Name</th>
												<th class="text-left">Type</th>
                                                <th class="text-left">Edit</th>
                                                <th class="text-left">Delete </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="obismanageTBLJSONS != null">
                                            <tr v-for="(row,index) in obismanageTBLJSONS.details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.obis}}</td>
                                                <td class="text-left">{{row.def}}</td>
												<td class="text-left">{{row.type}}</td>
                                                <td style="width:22px;cursor:pointer;" title="Edit"  @click="obisEditFunction(row.id,index)">
                                                    <img src="assets/images/edit.png" width="20" />
                                                </td>
                                                <td style="width:22px;cursor:pointer;" title="Delete" @click="obisDeleteFunction(row.id,index)">
                                                    <i class="fa fa-trash" style="color:red;font-size:18px;vertical-align: middle;"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="obismanageTBLJSONS ==  null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- [ sample-page ] end -->
            </div>
            <!-- [ Main Content ] end -->
        </div>
    </div>
    <modal name="serialmangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{serialmangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_locationcloseBTN" type="button" class="btn btn-default" @click="hideserialmanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-5">
                      Gateway Serial Number
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-7">
                        <input type="text" v-model="serialNameTxt" placeholder="enter a gateway serial number" id="serialNameTxt" maxlength="32" class="mx-input" name="serialNameTxt" />
                    </div>
                </div>
				  <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-5">
                        Gateway Type
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-7">
                        <select type="text"  id="devTypeTxt" class="mx-input" name="devTypeTxt" v-model="devTypeTxt" @change="devtypeChange(devTypeTxt)">
                            <option name="Choose Gateway Type" selected disabled>Choose Gateway Type</option>
                            <option name="Modem">Modem</option>
                            <option name="RTU">RTU</option>
                            <option name="DCU">DCU</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-5">
                        Gateway Model
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-7">
                        <select type="text" id="devModelTxt" class="mx-input" name="devModelTxt" v-model="devModelTxt">
                            <option name="Choose Gateway Model" selected disabled>Choose Gateway Model</option>
                            <option name="MIM" v-show="devTypeTxt == 'Modem'">MIM</option>
                            <option name="IOT Router" v-show="devTypeTxt == 'Modem'">IOT Router</option>
                            <option name="Intelligent Router" v-show="devTypeTxt == 'Modem'">Intelligent Router</option>
                            <option name="1200" v-show="devTypeTxt == 'RTU'">1200</option>
                            <option name="1500" v-show="devTypeTxt == 'RTU'">1500</option>
                            <option name="2000" v-show="devTypeTxt == 'RTU'">2000</option>
                            <option name="DCU" v-show="devTypeTxt == 'DCU'">DCU</option>
                        </select>
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="saveSerialConfigFunction" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveSerialConfigFunction()">{{serialpopupsavebuttontext}}</a>
                <a id="serialmanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideserialmanagementpopup()">Cancel</a>
            </div>
        </div>
    </modal>

	<modal name="meterserialmangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{meterserialmangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_metrcloseBTN" type="button" class="btn btn-default" @click="hideMetSerialmanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-5">
                       Meter Serial Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-7">
                        <input type="text" v-model="metserNameTxt" placeholder="enter a meter serial name" id="metserNameTxt" maxlength="32" class="mx-input" name="metserNameTxt" />
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="savemetSerialConfigFunction" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveMETserConfigFunction()">{{metserialpopupsavebuttontext}}</a>
                <a id="metserialmanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideMetSerialmanagementpopup()">Cancel</a>
            </div>
        </div>
    </modal>
    <modal name="obismangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{obismangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_obiscloseBTN" type="button" class="btn btn-default" @click="hideobisdevicemanagementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        OBIS Code
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
						<input type="text"  v-model="obisCodeTxt" placeholder="enter a OBIS code" id="obisCodeTxt" maxlength="32" :disabled="obisSerialFlag" class="mx-input" name="obisCodeTxt" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        OBIS Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <input type="text"  v-model="obisNameTxt" placeholder="enter a OBIS name" id="obisNameTxt" maxlength="32" class="mx-input" name="obisNameTxt" />
                    </div>
                </div>
				<div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-4">
                        Type
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-8">
                        <select type="text" id="obisTypeTxt" class="mx-input" name="obisTypeTxt" v-model="obisTypeTxt">
                            <option name="Choose Type" selected disabled>Choose Type</option>
                            <option name="Billing">Billing</option>
							<option name="Event">Event</option>
							<option name="Instantaneous">Instantaneous</option>
							<option name="Load survey">Load survey</option>
							<option name="Midnight">Midnight</option>
                        </select>
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createobismanagementBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveObisConfigFunction()">{{obispopupsavebuttontext}}</a>
                <a id="obismanagementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hideobisdevicemanagementpopup()">Cancel</a>
            </div>
            <p></p>
        </div>
    </modal>
</div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js'
export default {
    name: "SerialObisManagement",
    components: {
        VueElementLoading,
    },
    data() {
        return {
            pageLayoutHeight: 0,
            userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
            isLoading: false,
            serialmangementpopupTitle: "Add Serial",
            serialmangementEditID: null,
            serialNameTxt: "",
            locationNameTxt: "",
			meterserialmangementpopupTitle: "Add Meter Serial",
			metserialpopupsavebuttontext:'Save',
			metserialmangementEditID: null,
			metserNameTxt:'',
            serialpopupsavebuttontext: "Save",
            deviceRowIndex: 0,
            devSerialmanageTBLJSONS: null,
			metSerialmanageTBLJSONS: null,
            locationArrayList: [],
            obismangementpopupTitle: "Add Gateway",
            obismanagemntEditID: null,
            devlocIdTXT: "",
            devLocNameTxt: "",
            obisNameTxt: "",
			obisTypeTxt:"Choose Type",
            deviceSerialTxt: "",
			devTypeTxt: "Choose Gateway Type",
            devModelTxt: "Choose Gateway Model",
			obisEditTxt:'',
			obisSerialFlag:false,
            obispopupsavebuttontext: "Save",
			obisCodeTxt:'',
            obismanageTBLJSONS: null,
			deviceSerialArr:[],
			meterSerialArr:[],
            meterDetailspopupTitle: "",
            deviceMeterTBLJSONS: null,
            addMeterDetailspopupTitle: '',
            devMetId: '',
            deviceMeternameTXT: '',
            deviceMeterSerialTXT: 'Choose Meter Serial',
			deviceMeterSerialEditTXT:'',
			metSerialFlag:false,
            deviceMeterAddressTXT: '',
            deviceMeterPasswordTXT: '',
            addMeterDetailsTitlesavebuttontext: 'Save',
            typevalue1: "password",
            imgvalue1: "assets/images/password_close.png",
        };
    },
    props: {},
    async mounted() {
        this.isLoading = true;
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        this.pageLayoutHeight = window.innerHeight;
        await this.getDevSerialList();
		//await this.getMetSerialList();
		await this.getObisCodeDetailsList();
        this.isLoading = false;
    },
    methods: {
        async getDevSerialList() {
            let devSerialManagementListJSON = await commMethods.apiGETmethod(apiURL.devSerialManagementListURL, null, this.userSessionData);
            console.log('JSON :::devSerialManagementListJSON::: ' + JSON.stringify(devSerialManagementListJSON.data));
            if (devSerialManagementListJSON != undefined && devSerialManagementListJSON.data.status == "found") {
                this.devSerialmanageTBLJSONS = devSerialManagementListJSON.data;
            } else {
                this.devSerialmanageTBLJSONS = null;
            }
        },
        async getMetSerialList() {
            let metSerialManagementListJSON = await commMethods.apiGETmethod(apiURL.metSerialManagementListURL, null, this.userSessionData);
            console.log('JSON :::metSerialManagementListJSON::: ' + JSON.stringify(metSerialManagementListJSON.data));
            if (metSerialManagementListJSON != undefined && metSerialManagementListJSON.data.status == "found") {
                this.metSerialmanageTBLJSONS = metSerialManagementListJSON.data;
            } else {
                this.metSerialmanageTBLJSONS = null;
            }
        },
		async getObisCodeDetailsList() {
            let obisCodeManagementListJSON = await commMethods.apiGETmethod(apiURL.obisCodeManagementListURL, null, this.userSessionData);
            console.log('JSON :::obisCodeManagementListJSON::: ' + JSON.stringify(obisCodeManagementListJSON.data));
            if (obisCodeManagementListJSON != undefined && obisCodeManagementListJSON.data.status == "found") {
                this.obismanageTBLJSONS = obisCodeManagementListJSON.data;
            } else {
                this.obismanageTBLJSONS = null;
            }
        },
		devtypeChange(devType){
			document.getElementById("devModelTxt").selectedIndex = 0;
			this.devModelTxt = "Choose Gateway Model";
		},
        beforeOpen() {},
        beforeClose() {},
        opened(e) {},
        closed(e) {},
        showserialmanagementpopup() {
            this.$modal.show("serialmangementpopup");
        },
        hideserialmanagementpopup() {
            this.$modal.hide("serialmangementpopup");
        },
		showMetSerialmanagementpopup() {
            this.$modal.show("meterserialmangementpopup");
        },
        hideMetSerialmanagementpopup() {
            this.$modal.hide("meterserialmangementpopup");
        },
        addserialmanagementpopup() {
            this.showserialmanagementpopup();
            this.serialNameTxt = "";
			 this.devTypeTxt = "Choose Gateway Type";
            this.devModelTxt = "Choose Gateway Model";
            this.serialpopupsavebuttontext = "Save";
            this.serialmangementpopupTitle = "Add Gateway Serial";
        },
        async devSerialDeleteFunction(id, devSerialIndex) {
            this.isLoading = true;
            var sernum = this.devSerialmanageTBLJSONS.details[devSerialIndex].ser_num;
			var status = this.devSerialmanageTBLJSONS.details[devSerialIndex].status;
			if(status == "Configured"){
				this.warningMessage("Unable to delete the gateway serial number ("+sernum+"). Selected gateway is already configured. Please remove the gateway from the configuration.");
				this.isLoading = false;
				return false;
			}
            var alertval = confirm("Do you want to delete in this Serial Number - ( " + sernum + " )");
            if (alertval) {
                var devSerialManagementDELETEURL = apiURL.devSerialManagementDELETEURL.replace("$$ser_num$$", sernum);
                console.log('JSON :::devSerialManagementDELETEURL::: ' + devSerialManagementDELETEURL);
                let devSerialManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(devSerialManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::devSerialManagementDELETEJSON::: ' + JSON.stringify(devSerialManagementDELETEJSON.data));
                if (devSerialManagementDELETEJSON != undefined && devSerialManagementDELETEJSON.data.status == "success") {
					await this.getDevSerialList();
                    this.successMessage("Serial Number ("+sernum + ") - Deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(devSerialManagementDELETEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
		addMetSerialmanagementpopup(){
			this.showMetSerialmanagementpopup();
            this.metserNameTxt = "";
            this.metserialpopupsavebuttontext = "Save";
            this.meterserialmangementpopupTitle = "Add Meter Serial";
		},
		async metSerialDeleteFunction(id, devSerialIndex) {
            this.isLoading = true;
            var sernum = this.metSerialmanageTBLJSONS.details[devSerialIndex].ser_num;
			var status = this.metSerialmanageTBLJSONS.details[devSerialIndex].status;
			if(status == "Configured"){
				this.warningMessage("Unable to delete meter serial number. Selected meter serial number is already configured!");
				this.isLoading = false;
				return false;
			}
            var alertval = confirm("Do you want to delete in this Meter Serial Number - ( " + sernum + " )");
            if (alertval) {
                var metSerialManagementDELETEURL = apiURL.metSerialManagementDELETEURL.replace("$$ser_num$$", sernum);
                console.log('JSON :::metSerialManagementDELETEURL::: ' + metSerialManagementDELETEURL);
                let metSerialManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(metSerialManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::metSerialManagementDELETEJSON::: ' + JSON.stringify(metSerialManagementDELETEJSON.data));
                if (metSerialManagementDELETEJSON != undefined && metSerialManagementDELETEJSON.data.status == "success") {
					await this.getMetSerialList();
                    this.successMessage("Meter Serial Number ("+sernum + ") - Deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(metSerialManagementDELETEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
		async saveMETserConfigFunction() {
            this.isLoading = true;
            if (this.metserNameTxt == "") {
                this.warningMessage("Please enter meter serial name");
                this.isLoading = false;
                return false;
            }
            //New Mode POST FUNCTION
            if (this.metserialpopupsavebuttontext == "Save") {
                if (this.metSerialmanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.metSerialmanageTBLJSONS.details.filter(function (item) {
                        return item.ser_num == _this.metserNameTxt;
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("Meter Serial number already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
				var metSerialManagementADDURL = apiURL.metSerialManagementADDURL.replace('$$ser_num$$',this.metserNameTxt);
                var metSerialManagementADDJSON = await commMethods.apiGETmethod(metSerialManagementADDURL, null, this.userSessionData);
                console.log('metSerialManagementADDJSON :: ' + JSON.stringify(metSerialManagementADDJSON));
                if (metSerialManagementADDJSON != undefined && metSerialManagementADDJSON.data.status == "success") {
                    this.successMessage("Meter Serial Number ("+this.metserNameTxt + ") - Added Successfully");
                    await this.getMetSerialList();
                    this.hideMetSerialmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(metSerialManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        async saveSerialConfigFunction() {
            this.isLoading = true;
            if (this.serialNameTxt == "") {
                this.warningMessage("Please enter serial number");
                this.isLoading = false;
                return false;
            }
            //New Mode POST FUNCTION
            if (this.serialpopupsavebuttontext == "Save") {
                if (this.devSerialmanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.devSerialmanageTBLJSONS.details.filter(function (item) {
                        return item.ser_num == _this.serialNameTxt;
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("Serial number already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
				var devSerialManagementADDURL = apiURL.devSerialManagementADDURL.replace('$$ser_num$$',this.serialNameTxt).replace('$$dev_type$$',this.devTypeTxt).replace('$$dev_model$$',this.devModelTxt);
                var devSerialManagementADDJSON = await commMethods.apiGETmethod(devSerialManagementADDURL, null, this.userSessionData);
                console.log('devSerialManagementADDJSON :: ' + JSON.stringify(devSerialManagementADDJSON));
                if (devSerialManagementADDJSON != undefined && devSerialManagementADDJSON.data.status == "success") {
                    this.successMessage("Serial Number ("+this.serialNameTxt + ") - Added Successfully");
                    await this.getDevSerialList();
                    this.hideserialmanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(devSerialManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        changeLocationMethod(locname) {
            if (this.devSerialmanageTBLJSONS != null) {
                var newarr = this.devSerialmanageTBLJSONS.details.filter(function (item) {
                    return item.loc_name == locname;
                });
                // console.log('newarr :'+JSON.stringify(newarr));
                if (newarr != undefined || newarr != null) {
                    this.devlocIdTXT = newarr[0].id;
                }
            }
        },
        async obismanagementAddFunction() {
			this.isLoading = true;
            this.showobismanagementpopup();
            this.obismangementpopupTitle = "Add OBIS Details";
			this.obisCodeTxt = "";
            this.obisNameTxt = "";
			this.obisTypeTxt = "Choose Type";
			this.obismanagemntEditID = '';
			this.obisSerialFlag = false;
            this.obispopupsavebuttontext = "Save";
			this.isLoading = false;
        },
        obisEditFunction(id, rowindex) {
            this.showobismanagementpopup();
            this.obismangementpopupTitle = "Update OBIS Details";
            this.obismanagemntEditID = id;
            this.obisCodeTxt = this.obismanageTBLJSONS.details[rowindex].obis;
            this.obisNameTxt = this.obismanageTBLJSONS.details[rowindex].def;
			this.obisTypeTxt = this.obismanageTBLJSONS.details[rowindex].type;
			this.obisSerialFlag = true;
            this.obispopupsavebuttontext = "Update";
        },
        async obisDeleteFunction(id, rowIndex) {
            var alertval = confirm("Do you want to delete in this OBIS Code - ( " + this.obismanageTBLJSONS.details[rowIndex].obis + " )");
            var obiscode = this.obismanageTBLJSONS.details[rowIndex].obis;
            if (alertval) {
                this.isLoading = true;
                var obisCodeManagementDELETEURL = apiURL.obisCodeManagementDELETEURL.replace("$$code$$", obiscode);
                console.log('JSON :::obisCodeManagementDELETEURL::: ' + obisCodeManagementDELETEURL);
                let obisCodeManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(obisCodeManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::obisCodeManagementDELETEJSON::: ' + JSON.stringify(obisCodeManagementDELETEJSON.data));
                if (obisCodeManagementDELETEJSON != undefined && obisCodeManagementDELETEJSON.data.status == "success") {
                    await this.getObisCodeDetailsList();
                    this.successMessage("OBIS Code ("+obiscode + ") - Deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(obisCodeManagementDELETEJSON.data.status);
                    this.isLoading = true;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        showobismanagementpopup() {
            this.$modal.show("obismangementpopup");
        },
        hideobisdevicemanagementpopup() {
            this.$modal.hide("obismangementpopup");
        },
        async saveObisConfigFunction() {
            this.isLoading = true;
            if (this.obisCodeTxt == "") {
                this.warningMessage("Please enter obis code");
                this.isLoading = false;
                return false;
            }
            if (this.obisNameTxt == "") {
                this.warningMessage("Please enter obis name");
                this.isLoading = false;
                return false;
            }
			if (this.obisTypeTxt == "Choose Type") {
                this.warningMessage("Please choose type");
                this.isLoading = false;
                return false;
            }
            //New Mode POST FUNCTION
            if (this.obispopupsavebuttontext == "Save") {
                if (this.obismanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.obismanageTBLJSONS.details.filter(function (item) {
                        return item.obis == _this.obisCodeTxt
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("OBIS Code already exists!");
                        this.isLoading = false;
                        return false;
                    }
					var newarr1 = this.obismanageTBLJSONS.details.filter(function (item) {
                        return item.def == _this.obisNameTxt
                    });
                    if (newarr1.length != 0) {
                        this.warningMessage("OBIS name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var newmodeJSON = {
                    obis: this.obisCodeTxt,
                    def: this.obisNameTxt,
					type: this.obisTypeTxt
                }
                console.log(apiURL.obisCodeManagementADDURL + ':: URL :: ' + JSON.stringify(newmodeJSON));
                var obisCodeManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.obisCodeManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('obisCodeManagementADDJSON :: ' + JSON.stringify(obisCodeManagementADDJSON));
                if (obisCodeManagementADDJSON != undefined && obisCodeManagementADDJSON.data.status == "success") {
                    this.successMessage("ObIS Code Saved Successfully");
                    await this.getObisCodeDetailsList();
                    this.hideobisdevicemanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(obisCodeManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                if (this.obismanageTBLJSONS != null) {
                    var _this = this;
                    var newarr = this.obismanageTBLJSONS.details.filter(function (item) {
                        return item.obis == _this.obisCodeTxt && item.id != _this.obismanagemntEditID
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("OBIS Code already exists!");
                        this.isLoading = false;
                        return false;
                    }
					var newarr = this.obismanageTBLJSONS.details.filter(function (item) {
                        return item.def == _this.obisNameTxt && item.type == _this.obisTypeTxt && item.id != _this.obismanagemntEditID
                    });
                    if (newarr.length != 0) {
                        this.warningMessage("OBIS name already exists!");
                        this.isLoading = false;
                        return false;
                    }
                }
                var editmodeJSON = {
					obis: this.obisCodeTxt,
                    def: this.obisNameTxt,
					type:this.obisTypeTxt
                }
                console.log(apiURL.obisCodeManagementUPDATEURL + ':: URL :: ' + JSON.stringify(editmodeJSON));
                var obisCodeManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.obisCodeManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('obisCodeManagementUPDATEJSON :: ' + JSON.stringify(obisCodeManagementUPDATEJSON));
                if (obisCodeManagementUPDATEJSON != undefined && obisCodeManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("OBIS Code Updated Successfully");
                    await this.getObisCodeDetailsList();
                    this.hideobisdevicemanagementpopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(obisCodeManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        showmeterDetailspopup() {
            this.$modal.show("meterDetailspopup");
        },
        hidemeterDetailspopup() {
            this.$modal.hide("meterDetailspopup");
        },
        addMeterDetailsFunction(id, rowindex) {
            this.showmeterDetailspopup();
            this.deviceRowIndex = rowindex;
            this.meterDetailspopupTitle = this.obismanageTBLJSONS.details[rowindex].dev_name+" - Meter Details";
            this.deviceMeterTBLJSONS = this.obismanageTBLJSONS.details[rowindex];
            console.log('---deviceMeterTBLJSONS-->>>>>>' + JSON.stringify(this.deviceMeterTBLJSONS))
        },
        showAddmeterDetailspopup() {
            this.$modal.show("addMeterDetailspopup");
        },
        hideAddMeterDetailspopup() {
            this.$modal.hide("addMeterDetailspopup");
        },
        async addMeterpopup() {
			this.isLoading = true;
			await this.getDeviceaAndMETSerDetailsList();
            this.showAddmeterDetailspopup();
            this.addMeterDetailspopupTitle = "Add Meter";
            this.devMetId = "";
            this.deviceMeternameTXT = "";
			this.metSerialFlag = false;
            this.deviceMeterSerialTXT = "Choose Meter Serial";
			this.deviceMeterSerialEditTXT = "";
            this.deviceMeterAddressTXT = "";
            this.deviceMeterPasswordTXT = "";
            this.addMeterDetailsTitlesavebuttontext = "Save";
			this.isLoading = false;
        },
        meterDetailEditFunction(id, deviceindex, rowindex) {
            this.showAddmeterDetailspopup();
            this.addMeterDetailspopupTitle = "Update Meter";
            this.devMetId = id;
			this.metSerialFlag = true;
            this.deviceMeternameTXT = this.obismanageTBLJSONS.details[deviceindex].met_details[rowindex].met_name;
            this.deviceMeterSerialTXT = this.obismanageTBLJSONS.details[deviceindex].met_details[rowindex].met_sn;
            this.deviceMeterAddressTXT = this.obismanageTBLJSONS.details[deviceindex].met_details[rowindex].met_addr;
            this.deviceMeterPasswordTXT = this.obismanageTBLJSONS.details[deviceindex].met_details[rowindex].met_pass;
			this.deviceMeterSerialEditTXT = this.obismanageTBLJSONS.details[deviceindex].met_details[rowindex].met_sn;
            this.addMeterDetailsTitlesavebuttontext = "Update";
        },
        async meterDetailDeleteFunction(id, deviceindex, rowindex) {
            var metname = this.obismanageTBLJSONS.details[deviceindex].met_details[rowindex].met_name;
            var devid = this.obismanageTBLJSONS.details[deviceindex].id;
            var alertval = confirm("Do you want to delete in this Meter - ( " + this.obismanageTBLJSONS.details[deviceindex].met_details[rowindex].met_name + " )");
            if (alertval) {
                this.isLoading = true;
                var meterManagementDELETEURL = apiURL.meterManagementDELETEURL.replace("$$id$$", id).replace("$$dev_id$$", devid);
                console.log('JSON :::meterManagementDELETEURL::: ' + meterManagementDELETEURL);
                let meterManagementDELETEJSON = await commMethods.apiGETWthUsersmethod(meterManagementDELETEURL, null, this.userSessionData);
                console.log('JSON :::meterManagementDELETEJSON::: ' + JSON.stringify(meterManagementDELETEJSON.data));
                if (meterManagementDELETEJSON != undefined && meterManagementDELETEJSON.data.status == "success") {
                    await this.getDeviceDetailsList();
                    await this.addMeterDetailsFunction(devid, deviceindex);
                    this.successMessage(metname + " - Deleted successfully");
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(meterManagementDELETEJSON.data.status);
                    this.isLoading = true;
                    return false;
                }
            } else {
                this.isLoading = false;
                return false;
            }
        },
        async saveMeterDetailsFunction() {
            this.isLoading = true;
            if (this.deviceMeternameTXT == "") {
                this.warningMessage("Please enter meter name");
                this.isLoading = false;
                return false;
            }
            if (this.deviceMeterSerialTXT == "") {
                this.warningMessage("Please enter meter serial number");
                this.isLoading = false;
                return false;
            }
            if (this.deviceMeterAddressTXT == "") {
                this.warningMessage("Please enter meter address");
                this.isLoading = false;
                return false;
            }
            if (this.deviceMeterPasswordTXT == "") {
                this.warningMessage("Please enter meter password");
                this.isLoading = false;
                return false;
            }
            //New Mode POST FUNCTION
            var devname = this.obismanageTBLJSONS.details[this.deviceRowIndex].dev_name;
            var devid = this.obismanageTBLJSONS.details[this.deviceRowIndex].id;
            if (this.addMeterDetailsTitlesavebuttontext == "Save") {
                // deviceMeterTBLJSONS.met_details
                var _this = this;
                var newarr = this.deviceMeterTBLJSONS.met_details.filter(function (item) {
                    return item.met_name == _this.deviceMeternameTXT
                });
                if (newarr.length != 0) {
                    this.warningMessage("Meter name already exists!");
                    this.isLoading = false;
                    return false;
                }
                var newmodeJSON = {
                    dev_id: devid,
                    dev_name: devname,
                    met_name: this.deviceMeternameTXT,
                    met_sn: this.deviceMeterSerialTXT,
                    met_addr: this.deviceMeterAddressTXT,
                    met_pass: this.deviceMeterPasswordTXT
                }
                console.log(apiURL.meterManagementADDURL + ':: URL :: ' + JSON.stringify(newmodeJSON));
                var meterManagementADDJSON = await commMethods.apiPOSTmethod(apiURL.meterManagementADDURL, newmodeJSON, this.userSessionData);
                console.log('meterManagementADDJSON :: ' + JSON.stringify(meterManagementADDJSON));
                if (meterManagementADDJSON != undefined && meterManagementADDJSON.data.status == "success") {
                    this.successMessage("Saved Successfully");
                    await this.getDeviceDetailsList();
                    await this.addMeterDetailsFunction(devid, this.deviceRowIndex);
                    this.hideAddMeterDetailspopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(meterManagementADDJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            } else {
                var _this = this;
                var newarr = this.deviceMeterTBLJSONS.met_details.filter(function (item) {
                    return item.met_name == _this.deviceMeternameTXT && item.id != _this.devMetId
                });
                if (newarr.length != 0) {
                    this.warningMessage("Meter name already exists!");
                    this.isLoading = false;
                    return false;
                }
                var editmodeJSON = {
                    met_id: this.devMetId,
                    dev_id: devid,
                    dev_name: devname,
                    met_name: this.deviceMeternameTXT,
                    met_sn: this.deviceMeterSerialTXT,
                    met_addr: this.deviceMeterAddressTXT,
                    met_pass: this.deviceMeterPasswordTXT
                }
                console.log(apiURL.meterManagementUPDATEURL + ':: URL :: ' + JSON.stringify(editmodeJSON));
                var meterManagementUPDATEJSON = await commMethods.apiPOSTmethod(apiURL.meterManagementUPDATEURL, editmodeJSON, this.userSessionData);
                console.log('meterManagementUPDATEJSON :: ' + JSON.stringify(meterManagementUPDATEJSON));
                if (meterManagementUPDATEJSON != undefined && meterManagementUPDATEJSON.data.status == "success") {
                    this.successMessage("Updated Successfully");
                    await this.getDeviceDetailsList();
                    await this.addMeterDetailsFunction(devid, this.deviceRowIndex);
                    this.hideAddMeterDetailspopup();
                    this.isLoading = false;
                    return false;
                } else {
                    this.warningMessage(meterManagementUPDATEJSON.data.status);
                    this.isLoading = false;
                    return false;
                }
            }
        },
        showpassword1() {
            if (this.typevalue1 === "password") {
                this.typevalue1 = "text";
                this.imgvalue1 = "assets/images/password_open.png";
            } else {
                this.typevalue1 = "password";
                this.imgvalue1 = "assets/images/password_close.png";
            }
        },
        successMessage(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        infoMessage(msg) {
            this.$notice.info({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        errorMessage(msg) {
            this.$notice.error({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
    },
    watch: {},
};
</script>

<style scoped>
table tr th,
table tr td {
    text-align: center;
    font-weight: 500;
}

.eyeimg {
    right: 20px;
    position: absolute;
    top: 12px;
    width: 20px;
    cursor: pointer;
}
</style>
