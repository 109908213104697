<template>
	<div>
		<vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#000" size="70" text="Please wait..." :is-full-screen="true" />
<div class="auth-wrapper"  v-show="checkpagevalidity">
    <div class="auth-content" >
        <div class="card">
            <div class="row align-items-center text-center">
                <div class="col-md-12">
                    <div class="card-body">
                        <h4 class="mb-4 f-w-500"><b>{{GUIConfig.GUItitle}}</b></h4>
						<img src="/../../assets/images/cmsLogo.png" alt="" class="img-fluid mb-2" width="100"> &nbsp; &nbsp;
                        <img src="/../../assets/images/reset1.png" alt="" class="img-fluid mb-2" width="70">
                        <h4 class="mb-3 f-w-400">Reset Password</h4>
						<br/>
                        <div class="form-group mb-3">
                            <label class="floating-label">User Name</label>
                            <input type="text" class="form-control" id="user_nameTXT" v-model="username" placeholder="User Name" disabled>
                        </div>
                        <div class="form-group mb-4">
                            <label class="floating-label">New Password <i class="fa fa-info-circle text-c-red" style="cursor: progress;" title="Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters"></i></label>
                            <input :type="typevalue" class="form-control" id="passwordTXT" v-model="newpassword" placeholder="New Password" @keyup="passwordEventListener()" title="Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters" required >
                            <img id="cnpasswdimg1" :src="imgvalue" class="eyeimg" @click="showPassword" />
							
							<span v-if="newpassword != ''" id="StrengthDisp" class="badge displayBadge">Weak</span>
                        </div>
						<div class="form-group mb-4">
                            <label class="floating-label">Confirm Password</label>
                            <input :type="typevalue2" class="form-control" id="passwordTXT" v-model="confirmpassword" placeholder="Confirm Password" v-on:keyup.enter="resetAction()">
                            <img id="cnpasswdimg2" :src="imgvalue2" class="eyeimg" @click="showPassword2" />
                        </div>
                        <button class="btn btn-block btn-primary mb-1" @click="resetAction()">Reset Password</button>
                        <div class="form-group mb-1">
                            <p class="mb-2 text-muted text-left">Creative Micro Systems : <a href="https://www.cmsgp.com" target="_blank" class="f-w-400">www.cmsgp.com</a></p>
                        </div>
						<div class="form-group mb-1 text-left" v-show="msg">
							<hr />
							<span id="responseid" style="color:green;font-weight: bold;"><i class="fa fa-check-circle" aria-hidden="true"></i> Reset Successfully, Use below link :</span>
							<br/>
							<a @click="calllogin()" target="_blank" style="color: #3b32c5;text-decoration: underline;">To Continue <i class="fa fa-arrow-alt-circle-right"></i> </a>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div>
	 </div>
</div>
<div class="auth-wrapper" v-show="!checkpagevalidity">
	<img src="https://internetdevels.com/sites/default/files/public/blog_preview/404_page_cover.jpg"/>
	<br>
	<div>
		<br>
		<a @click="calllogin()" class="btn btn-link btn-btn-primary text-white">To Go Login <i class="fa fa-arrow-alt-circle-right"></i> </a>
	  </div>
  </div>

</div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import GUIConfig from "@/GUI_Config";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js';
import swal from 'sweetalert';
let timeout;

let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
export default {
    props: {},
    name: 'ResetView',
    components: {
        VueElementLoading,
        swal
    },
    data() {
        return {
            isLoading: false,
            GUIConfig: GUIConfig,
			checkpagevalidity:true,
            username: this.$route.params.username,
			resetkey: this.$route.params.key,
            newpassword: '',
			confirmpassword:'',
            userrole: '',
            loginJson: '',
            sessionloginID: null,
            typevalue: "password",
            imgvalue: "/../../assets/images/password_close.png",
			typevalue2: "password",
            imgvalue2: "/../../assets/images/password_close.png",
			msg:false,
			redirectlink:null,
        }
    },
    mounted() {
		//console.log('-----mounted-----'+this.resetkey);
        document.getElementById("user_nameTXT").focus();
    },
    async created() {
		console.log('-----created-----'+this.resetkey);
		await this.getpageValidity();
	},
    methods: {
		calllogin(){
			this.isLoading = true;
					setTimeout(() => {
						this.isLoading = false;
						this.$router.replace({
                        path: "/"
                    });
					}, 1000);
		},
		async getpageValidity() {
			this.isLoading = true;
			var userinfo = {
				user_name:this.username,
				access_token:this.resetkey,
			};
			var resetkeyValidityURL = apiURL.resetkeyValidityURL.replace("$$key$$", this.resetkey);
            let resetkeyValidityJSON = await commMethods.apiGETmethod(resetkeyValidityURL, null, userinfo);
            console.log('JSON :::resetkeyValidityJSON::: ' + JSON.stringify(resetkeyValidityJSON.data));
            if (resetkeyValidityJSON != undefined && resetkeyValidityJSON.data.status == "Valid") {
                this.checkpagevalidity = true;
				this.isLoading = false;
                return true;
            } else {
				this.checkpagevalidity = false;
                this.isLoading = false;
                return true;
            }
        },
        async resetAction() {
            this.isLoading = true;
            if (this.username == "") {
                this.warningMessage("Please enter username");
                this.isLoading = false;
                return true;
            } else if (this.newpassword == "") {
                this.warningMessage("Please enter password");
                this.isLoading = false;
                return true;
            }
			else if (this.confirmpassword == "") {
                this.warningMessage("Please enter confirm password");
                this.isLoading = false;
                return true;
            }
			let strengthBadge = document.getElementById('StrengthDisp');
            if (strengthBadge) {
                console.log('strengthBadge TXT :' + strengthBadge.textContent);
                if (strengthBadge.textContent != 'Strong') {
                    this.warningMessage("Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters");
                    this.isLoading = false;
                    return false;
                }
            }
                var resetjson = {
                    user_name: this.username.toString(),
                    password: this.newpassword.toString(),
                };
				var userinfo = {
				user_name:this.username,
				access_token:this.resetkey,
				};
                // console.log('resetpasswordJSON :: ' + apiURL.resetpasswordJSON);
                var resetpasswordJSON = await commMethods.apiPOSTmethod(apiURL.resetpasswordURL, resetjson, userinfo);
                console.log('JSON :: ' + JSON.stringify(resetpasswordJSON));
                if (resetpasswordJSON != undefined && resetpasswordJSON.data.status == "success") {
                   // this.successMessage("Reset Successfully!");
				   	this.redirectlink = resetpasswordJSON.data.url;
					this.msg= true;
                    this.isLoading = false;
                    return true;
                } else if (resetpasswordJSON != undefined && resetpasswordJSON.data.status != "success") {
                    this.warningMessage(resetpasswordJSON.data.status);
					this.redirectlink = null;
					this.msg = false;
                    this.isLoading = false;
                    return true;
                } else {
                    this.isLoading = false;
					this.redirectlink = null;
					this.msg= false;
                    this.errorMessage(resetpasswordJSON.data.status);
                    return true;
                }
        },
		
        StrengthChecker(PasswordParameter) {
            // We then change the badge's color and text based on the password strength
            let password = document.getElementById('passwordTXT');
            let strengthBadge = document.getElementById('StrengthDisp');
            if (strengthBadge) {
                if (strongPassword.test(PasswordParameter)) {
                    strengthBadge.style.backgroundColor = "green"
                    strengthBadge.textContent = 'Strong'
					strengthBadge.style.color = "white";
                } else if (mediumPassword.test(PasswordParameter)) {
                    strengthBadge.style.backgroundColor = 'blue'
                    strengthBadge.textContent = 'Medium';
					strengthBadge.style.color = "white";
                } else {
                    strengthBadge.style.backgroundColor = 'red'
                    strengthBadge.textContent = 'Weak';
					strengthBadge.style.color = "black";
                }
            }
        },
        passwordEventListener() {
            console.log('000000<>>');
            //The badge is hidden by default, so we show it
            let password = document.getElementById('passwordTXT');
            let strengthBadge = document.getElementById('StrengthDisp');
            if (strengthBadge) {
                strengthBadge.style.display = 'block';
            }
            clearTimeout(this.timeout);

            //We then call the StrengChecker function as a callback then pass the typed password to it

            this.timeout = setTimeout(() => this.StrengthChecker(password.value), 100);

            //Incase a user clears the text, the badge is hidden again

            if (password.value.length !== 0) {
                if (strengthBadge) {
                    strengthBadge.style.display != 'block';
                }
            } else {
                if (strengthBadge) {
                    strengthBadge.style.display = 'none';
                }
            }
        },
        showPassword() {
            if (this.typevalue === "password") {
                this.typevalue = "text";
                this.imgvalue = "/../../assets/images/password_open.png";
            } else {
                this.typevalue = "password";
                this.imgvalue = "/../../assets/images/password_close.png";
            }
        },
		showPassword2() {
            if (this.typevalue2 === "password") {
                this.typevalue2 = "text";
                this.imgvalue2 = "/../../assets/images/password_open.png";
            } else {
                this.typevalue2 = "password";
                this.imgvalue2 = "/../../assets/images/password_close.png";
            }
        },
        getRandomInt(max) {
            return "session_" + Math.floor(Math.random() * Math.floor(max));
        },
        checksplChar(inputstring) {
            var regex = /^[0-9a-zA-Z\_]+$/
            return regex.test(inputstring);
        },
        successMessage(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        infoMessage(msg) {
            this.$notice.info({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        errorMessage(msg) {
            this.$notice.error({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        }
    }
}
</script>

<style scoped>
.form-control:disabled, .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
}
.eyeimg {
    right: 10px;
    position: absolute;
    top: 12px;
    width: 20px;
    cursor: pointer;
}

.forgetpass:hover {
    text-decoration: underline;
}

.floating-label {
    top: -10px !important;
    font-size: 0.75rem;
    color: #00535c;
}
</style>
