
import DashView from './components/Dash.vue';
import LoginView from './components/Login.vue';
import ResetView from './components/ResetPassword.vue';
// Dashboard Views  Components-
import MainDashboardView from './components/Dashboard/Dashboard.vue';

import ConfigFirmwareView from './components/Dashboard/Config&Firmware.vue';
import DiagnosticsView from './components/Dashboard/Diagnostics.vue';

// Management Views  Components-
import LocationDeviceManagementView from './components/Management/LocationDeviceManagement.vue';
import UserManagementView from './components/Management/UserManagement.vue';
import SerialObisManagementView from './components/Management/SerialObisManagement.vue';

//
import OnDemandCommandView from './components/Config/OnDemandCommand.vue';

// Report Views  Components-
import AutomaticGenerateReportView from './components/Reports/AutomaticGenerateReport.vue';
import CustomizeReportView from './components/Reports/CustomizeReport.vue';
import AuditReportView from './components/Reports/AuditReport.vue';
import EventsView from './components/Reports/ViewEvents.vue';

import testingView from './components/Management/testing.vue';

const routes = [{
        path: '/',
        name: 'Login',
        component: LoginView
    },
	{
        path: '/reset/:username/:key',
        name: 'Reset',
        component: ResetView
    },
    {
        path: '/ev',
        component: DashView,
        children: [{
                path: 'index',
                alias: '',
                component: MainDashboardView,
                name: 'Main Dashboard',
                meta: {
                    description: ''
                }
            },
			{
                path: 'configupload',
                alias: '',
                component: ConfigFirmwareView,
                name: 'Config Upload',
                meta: {
                    description: ''
                }
            },{
                path: 'diagnostics',
                alias: '',
                component: DiagnosticsView,
                name: 'Diagnostics',
                meta: {
                    description: ''
                }
            },
			{
                path: 'locdevicemanagement',
                alias: '',
                component: LocationDeviceManagementView,
                name: 'Location Device Management',
                meta: {
                    description: ''
                }
            },
			{
                path: 'usermanagement',
                alias: '',
                component: UserManagementView,
                name: 'User Management',
                meta: {
                    description: ''
                }
            },
			{
                path: 'serialobismanagement',
                alias: '',
                component: SerialObisManagementView,
                name: 'Serial Obis Management',
                meta: {
                    description: ''
                }
            },
			{
                path: 'ondemandcommand',
                alias: '',
                component: OnDemandCommandView,
                name: 'On Demand Command',
                meta: {
                    description: ''
                }
            },
			{
                path: 'automaticgeneratereport',
                alias: '',
                component: AutomaticGenerateReportView,
                name: 'Automatic Generate Report',
                meta: {
                    description: ''
                }
            },
			{
                path: 'customizereport',
                alias: '',
                component: CustomizeReportView,
                name: 'Customize Report',
                meta: {
                    description: ''
                }
            },
			{
                path: 'auditreport',
                alias: '',
                component: AuditReportView,
                name: 'Audit Report',
                meta: {
                    description: ''
                }
            },
            {
                path: 'viewevents',
                alias: '',
                component: EventsView,
                name: 'Events View',
                meta: {
                    description: ''
                }
            },
            {
                path: 'testing',
                alias: '',
                component: testingView,
                name: 'Testing View',
                meta: {
                    description: ''
                }
            },
            
            
        ]
    }
];











export default routes;