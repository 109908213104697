<template>
<div class="pcoded-main-container">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="row">
                <!-- [ sample-page ] start -->
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header" style="padding: 10px 0px 0px 10px;">
                            <div class="row">
                                <div class="col-lg-2">
                                    <h5 style="margin-top: 10px;">Audit Trail Report</h5>
                                </div>
                                <div class="col-lg-9">
                                    <label><b>Choose Type :</b> &nbsp;</label>
                                    <select id="auditTypeSelTXT" v-model="auditTypeSelTXT" class="mx-input" style="width: 15%;">
                                        <option value="Choose Type" disabled>Choose Type</option>
                                        <option value="ALL" selected>ALL</option>
                                        <option value="User Management">User Management</option>
                                        <option value="Location Management">Location Management</option>
                                        <option value="Gateway Management">Gateway Management</option>
                                        <option value="Config / Firmware Upload">Config / Firmware Upload</option>
                                        <!-- <option value="Events">Events</option>
                                        <option value="Alerts">Alerts</option> -->
                                        <option value="Login / Password">Login / Password</option>
										<option value="Support">Support</option>
                                    </select>&nbsp;&nbsp;
                                    <label><b>Start Date :</b> &nbsp;</label>
                                    <date-picker v-model="startdateReportFRMT" format="DD MMM YYYY" value-type="format" placeholder="Start Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="startPickerChange" valueType="format"></date-picker>
                                    &nbsp;&nbsp;
                                    <label><b>End Date : </b>&nbsp;</label>
                                    <date-picker v-model="enddateReportFRMT" format="DD MMM YYYY" value-type="format" placeholder="End Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="endPickerChange" valueType="format"></date-picker>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-outline-info has-ripple" style="margin-top: -2px;" @click="reportGenDetails()"><i class="mr-2 fa fa-check-circle"></i>Submit</button>
                                </div>
                                <div class="col-lg-1 text-right" v-show="reportAuditTblJson.length != 0">
                                    <button type="button" class="btn btn-icon" title="export" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="feather icon-more-horizontal"></i>
                                    </button>
                                    <ul class="list-unstyled card-option dropdown-menu dropdown-menu-right">
                                        <li class="dropdown-item" @click="downloadExport('excel')"><a><span><i class="fa fa-file-excel" style="color: green;"></i> Excel</span></a></li>
                                        <li class="dropdown-item" @click="downloadExport('pdf')"><a><span><i class="fa fa-file-pdf text-c-red"></i> Pdf</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <div class="table-border-style">
                                    <div class="table-responsive" v-bind:style="{'height':(pageLayoutHeight-270)+'px'}" style="border:1px solid lightgray">
                                        <table class="table table-striped table-bordered">
                                            <thead style="position: sticky;top: -1px;z-index:1;">
                                                <tr>
                                                    <th class="text-left">#</th>
                                                    <th class="text-left">Date Time</th>
                                                    <th class="text-left">User Name</th>
                                                    <th class="text-left">User Role</th>
                                                    <th class="text-left">Type</th>
                                                    <th class="text-left">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(tblitems,index) in reportAuditTblJson" v-bind:key="index">
                                                <tr>
                                                    <td class="text-left">{{ index+1 }}</td>
                                                    <td class="text-left">{{tblitems.date_time}}</td>
                                                    <td class="text-left">{{tblitems.user_name}}</td>
                                                    <td class="text-left">{{tblitems.user_role}}</td>
                                                    <td class="text-left">{{tblitems.type}}</td>
                                                    <td class="text-left">{{tblitems.desc}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div v-if="reportAuditTblJson.length == 0" style="text-align:center;width: 100%;">
                                            <span>No records found</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
                <!-- [ sample-page ] end -->
            </div>
            <!-- [ Main Content ] end -->
        </div>
    </div>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import commMethods from "@/api/common_methods.js";
var myDate = new Date();
export default {
    name: "AuditReport",
    components: {
        DatePicker,
        VueElementLoading,
    },
    data() {
        return {
            pageLayoutHeight: 0,
            isLoading: false,
            auditTypeSelTXT: "ALL",
			userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
            startdateReportFRMT: this.dateOnlyFRMT(myDate),
            enddateReportFRMT: this.dateOnlyFRMT(myDate),
            locationmanageTBLJSONS: null,
            locationArrayList: [],
            deviceArrayList: [],
            meterArrList: [],
            reportAuditTblJson: [],
        };
    },
    props: {},
    async mounted() {
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        this.isLoading = true;
        this.pageLayoutHeight = window.innerHeight;
        await this.reportGenDetails();
        this.isLoading = false;
    },
    methods: {
        async reportGenDetails() {
            this.isLoading = true;
            if (this.startdateReportFRMT == "") {
                this.warningMessage('Please choose start date');
                this.isLoading = false;
                return false;
            }
            if (this.enddateReportFRMT == "") {
                this.warningMessage('Please choose end date');
                this.isLoading = false;
                return false;
            }
            var eDate = new Date(this.enddateReportFRMT);
            var sDate = new Date(this.startdateReportFRMT);
            if (this.startdateReportFRMT != '' && this.enddateReportFRMT != '' && sDate > eDate) {
                this.warningMessage("Please ensure that the End Date is greater than or equal to the Start Date.");
                this.isLoading = false;
                return false;
            }
			const diffTime = Math.abs(eDate - sDate);
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
			console.log(diffDays + " days");
				if (diffDays > 90) {
                    this.warningMessage("Selected date range should not exceed 90 days!");
                    this.isLoading = false;
                    return false;
                }
            var reportJson = {
                "type": this.auditTypeSelTXT,
                "start_date": this.startdateReportFRMT, // 23 Jan 2023
                "end_date": this.enddateReportFRMT, // 24 Jan 2023
            };
            console.log(JSON.stringify(reportJson) + '----AUDIT-----reportGenDetails :::' + apiURL.auditgenerateDetailsReportURL);
            let auditgenerateDetailsReportJSON = await commMethods.apiPOSTmethod(apiURL.auditgenerateDetailsReportURL, reportJson, this.userSessionData);
            console.log('JSON :::auditgenerateDetailsReportJSON::: ' + JSON.stringify(auditgenerateDetailsReportJSON.data));
            if (auditgenerateDetailsReportJSON != undefined && auditgenerateDetailsReportJSON.data.status == "found") {
                this.reportAuditTblJson = auditgenerateDetailsReportJSON.data.details;
                this.isLoading = false;
            } else {
                this.reportAuditTblJson = [];
                this.isLoading = false;
            }
        },
        async downloadExport(exporttype) {
            this.isLoading = true;
            var reportJson = {
                "type": this.auditTypeSelTXT,
                "start_date": this.startdateReportFRMT, // 23 Jan 2023
                "end_date": this.enddateReportFRMT, // 24 Jan 2023
                "export_type": exporttype // pdf
            };
            console.log(JSON.stringify(reportJson) + '---------downloadExport :::' + apiURL.auditExportReportURL);
            let auditExportReportJSON = await commMethods.apiPOSTmethod(apiURL.auditExportReportURL, reportJson, this.userSessionData);
            console.log('JSON :::auditExportReportJSON::: ' + JSON.stringify(auditExportReportJSON.data));
            if (auditExportReportJSON != undefined && auditExportReportJSON.data.status == "found") {
                await this.downloadFile(auditExportReportJSON.data.file_name, apiURL.serverURL + auditExportReportJSON.data.file_path);
                this.successMessage(auditExportReportJSON.data.file_name + " - Download Successfully..");
                this.isLoading = false;
            } else {
                this.warningMessage(auditExportReportJSON.data.status);
                this.isLoading = false;
            }
        },
        downloadFile(fileName, filepath) {
            console.log(fileName + ": Download FILE PATH :" + filepath);
            const link1 = document.createElement("a");
            link1.href = filepath;
            link1.setAttribute("download", fileName);
            document.body.appendChild(link1);
            link1.click();
        },
        notAfterToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        startPickerChange(value) {
            this.startdateReportFRMT = value;
            console.log("----STRAT------->" + value);
        },
        endPickerChange(value) {
            this.enddateReportFRMT = value;
            console.log("----END------->" + value);
        },
        dateOnlyFRMT(dateval) {
            var date = new Date(dateval);
            var month = date.getMonth();
            var day = date.getDate();
            var year = date.getFullYear();
            var hour = date.getHours();
            var min = date.getMinutes();
            var sec = date.getSeconds();
            var monthlimt = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var dateStr = day <= 9 ? "0" + day : day;
            return dateStr + " " + monthlimt[month] + " " + year;
        },
        successMessage(msg) {
            this.$notice.success({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
        infoMessage(msg) {
            this.$notice.info({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
        errorMessage(msg) {
            this.$notice.error({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
    },
    watch: {},
};
</script>

<style scoped>
.card-folders.card-body>.breadcrumb {
    margin-left: -1.25em;
    margin-right: -1.25em;
    margin-top: -1.25em;
    border-radius: 0;
}

.folder-container {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    width: 100px;
    padding: 0;
    align-self: start;
    background: none;
    border: none;
    outline-color: transparent !important;
    cursor: pointer;
}

.folder-icon {
    font-size: 3em;
    line-height: 1.5em;
}

.folder-icon-color {
    color: #FFC107;
    text-shadow: 1px 1px 0px #FFC107;
}

.folder-name {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.flex-column.folder-container {
    display: flex;
    width: auto;
    min-width: 100px;
    text-align: left;
    margin: 0;
    margin-bottom: 1rem;
}

.flex-column.folder-icon .flex-column.folder-name {
    display: inline-flex;
}

.flex-column.folder-icon {
    font-size: 1.4em;
    margin-right: 1rem;
}

.file-icon-color {
    color: #999;
    text-shadow: 1px 1px 0px grey;
}

.mx-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 30px;
    padding: 0px 0px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.2;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.context-menu {
    position: absolute;
}

.menu {
    display: flex;
    flex-direction: column;
    background-color: #ddd;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(141, 131, 131, 0.05);
    padding: 3px 0;
}

.menu>span>a {
    font: inherit;
    border: 0;
    padding: 5px 20px 5px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
}

.menu>span>a:hover {
    background: #f1f3f7;
    color: #19a12fcc;
}

.menu>span>a>i {
    padding-right: 10px;
}

.menu>span.trash>a:hover {
    color: red;
}

table tr th,
table tr td {
    text-align: center;
    font-weight: bolder;
}
</style>
