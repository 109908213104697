import Vue from 'vue';
import axios from 'axios';
import CripNotice from "crip-vue-notice";
import VueSessionStorage from 'vue-sessionstorage';
import apiURL from "@/API_Config";
const config = {
	headers : {
			"Content-Type": "application/json",

}};

Vue.use(VueSessionStorage);
Vue.use(CripNotice);
export default {
    async apiGETmethod(url, data, userobj) {
		let apiRes = null;
		data = null;
        if (!url) {
            console.error('API function call requires url argument');
            return false;
        }
		// userobj = JSON.parse(userobj);
		// console.log("USER DETAILS :", userobj.token);
		if(userobj != null){
			config.headers['Authorization'] = userobj.access_token;
		}
		// sessionStorage.setItem('storedData', code++);
		// loginsession =  sessionStorage.getItem("loginsession");
     //   console.log(JSON.stringify(config)+' :-------> GET METHOD URL :------->: ' + url);
        try {
			//  apiRes =  await axios.get(url,config);
			apiRes = await axios
			.get(url,config)
			.then((response) => {
				//console.log("200 API RES: "+JSON.stringify(response));
				console.log("GET - API status code: "+response.status);
				if(response.status == 200){
					return response;
				}
			}).catch((error) => {
				if (error.response) {
				console.log("GET API ERR: "+ error );
				console.log("GET API data: "+error.response.data);
				console.log("GET API status: "+error.response.status);
				console.log("GET API headers: "+error.response.headers);
				if(error.response.status == 401){
					// unauthorized
					alert('Your session has expired. please log in');
					location.href = "/";
					return false;
				}else if(error.response.status == 403){
					location.href = "/";
					alert('Your session has expired. please log in');
					return false;
				}else{
					location.href = "/";
					alert(error);
					return false;
				}
				}
			});
			  return apiRes;
        } catch (err) {
			console.log(' --err.response.status----->:' + err.response.status);
            console.error(err);
        } finally {
			console.log('finally : '+ apiRes);
		}
    },
	async apiGETWthUsersmethod(url, data, userobj) {
		let apiRes = null;
		data = null;
		console.log('--url>>>'+url);
        if (!url) {
            console.error('API function call requires url argument');
            return false;
        }
		//userobj = JSON.parse(userobj);
		if(userobj != null){
			config.headers['Authorization'] = userobj.access_token;
			if(url.includes("get/user/list")){
				url = url+"?active_user="+userobj.user_name+"&active_role="+userobj.role;
			}else{
				url = url+"&active_user="+userobj.user_name+"&active_role="+userobj.role;
			}
		}
      //  console.log(JSON.stringify(config)+' :-------> GET METHOD WITH USERS URL :------->: ' + url);
        try {
			// apiRes =  await axios.get(url,config);
			apiRes = await axios
			.get(url,config)
			.then((response) => {
				console.log("GET - WthUsers API status code: "+response.status);
				//console.log("GET WthUsers API RES: "+JSON.stringify(response));
				return response;
			})
			.catch((error) => {
				if (error.response) {
					console.log("GET WthUsers API ERR: "+ error );
					console.log("GET WthUsers API data: "+error.response.data);
					console.log("GET WthUsers API status: "+error.response.status);
					console.log("GET WthUsers API headers: "+error.response.headers);
					if(error.response.status == 401){
						location.href = "/";
						alert('Your session has expired. please log in');
						return false;
					}else if(error.response.status == 403){
						location.href = "/";
						alert('Your session has expired. please log in');
						return false;
					}else{
						location.href = "/";
						alert(error);
						return false;
					}
				//return error;
				}
			});
			 return apiRes;
        } catch (err) {
			console.log(' --err.response.status----->:' + err.response.status);
            console.error(err);
        } finally {
			console.log('finally : '+ apiRes);
		}
    },
    async apiPOSTmethod(url, data, userobj) {
		let apiRes = null;
        if (!url) {
            console.error('API function call requires url argument');
            return false;
        }
        try {
			// userobj = JSON.parse(userobj);
			if(userobj != null){
				config.headers['Authorization'] = userobj.access_token;
				data["active_user"] = userobj.user_name;
				data["active_role"] = userobj.role;
			}
			//console.log(JSON.stringify(config)+' :-------> POST METHOD URL :------->: ' + url);
			//sessionStorage.setItem('loginsession', JSON.stringify(userobj));
			 //  apiRes = await axios.post(url, data, config);
			 apiRes = await axios
                .post(url, data, config)
                .then((response) => {
					console.log("POST - API status code: "+response.status);
					if(response.status == 200){
						return response;
					}
                }).catch(async (error) => {
					if (error.response) {
					console.log("POST API ERR: "+ error );
					console.log("POST API data: "+error.response.data);
					console.log("POST API status: "+error.response.status);
					console.log("POST API headers: "+error.response.headers);
					if(error.response.status == 401){
						location.href = "/";
						alert('Your session has expired. please log in');
						return false;
						// var inputJson = {
						// 	user_details: userobj
						// };
					    // var refreshJSON = await this.apiPOSTmethod(apiURL.refreshTokenURL, inputJson, userobj);
						// if(refreshJSON != undefined && refreshJSON.data.status == "found"){
						// config.headers['Authorization'] = refreshJSON.data.user_details.access_token;
						// sessionStorage.setItem('loginsession', JSON.stringify(refreshJSON.data.user_details));
						// console.log(' :-------> AFTER  :------->: ' + JSON.stringify(config));
						// await this.apiPOSTmethod(url, data, refreshJSON.data.user_details);
						// return { status: "-" };
						// }
					}else if(error.response.status == 403){
						location.href = "/";
						alert('Your session has expired. please log in');
						return false;
					}else{
						alert(error);
						location.href = "/";
						return false;
					}
					}
				});
				return apiRes;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
			console.log(apiRes);
		}
    },

	async findMaxValueInArr(dataArray,key) {
		if(dataArray.length != 0){
			var maxvVvalue = dataArray.reduce((maxValue, currentObject) => {
				return Math.max(maxValue, parseFloat(currentObject[key]));
			}, Number.NEGATIVE_INFINITY);
			return  maxvVvalue;
		}
	}
};