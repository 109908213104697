<template>
<section>
	<vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <!-- POPUP START * -->
    <modal name="changePasswordpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="200" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content ">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> Change Password</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="save_closeBTN" type="button" class="btn btn-default" @click="closechangepasspopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="margin-top:0px;">
                <div class="row">
                    <div class="col-lg-4">
                        <span class="inputTEXTLBLcss">User Name :</span>
                    </div>
                    <div class="col-lg-8">
                        <input v-model="currusername" id="currusername" placeholder="User Name" class="mx-input" maxlength="32" disabled />
                    </div>
                </div>
                <p></p>
                <div class="row">
                    <div class="col-lg-4">
                        <span class="inputTEXTLBLcss">Current Password :</span>
                    </div>
                    <div class="col-lg-8">
                        <input v-model="changecurrpassTXT" :type="currtypevalue" id="changecurrpassTXT" placeholder="Current Password" class="mx-input" />
                        <img id="changecurrpassTXTimg" :src="currimgvalue" class="eyeimgpass" title="show password" @click="showPassword1()">
                    </div>
                </div>
                <p></p>
                <div class="row">
                    <div class="col-lg-4">
                        <span class="inputTEXTLBLcss">New Password <i class="fa fa-info-circle text-c-red" title="Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters"></i>:</span>
                    </div>
                    <div class="col-lg-8">
                        <input v-model="changenewpassTXT" :type="newtypevalue" id="changenewpassTXT" placeholder="New Password" class="mx-input" @keyup="passwordEventListener()" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />
                        <img id="changenewpassTXTimg" :src="newimgvalue" class="eyeimgpass" title="show password" @click="showPassword2()">
                        <br>
                        <span v-if="changenewpassTXT != ''" id="StrengthDisp" class="badge displayBadge">Weak</span>
                    </div>
                </div>
                <p></p>
                <div class="row">
                    <div class="col-lg-4">
                        <span class="inputTEXTLBLcss">Confirm Password :</span>
                    </div>
                    <div class="col-lg-8">
                        <input v-model="changeconfrimPassTXT" :type="confrmtypevalue" id="changeconfrimPassTXT" placeholder="Confirm Password" class="mx-input" />
                        <img id="changeconfrimPassTXTimg" :src="confrmimgvalue" class="eyeimgpass" title="show password" @click="showPassword3()">
                    </div>
                </div>
            </div>
            <div class="modal-footer flex-center p-1">
                <a id="chngepassSaveBTN" class="btn btn-sm btn-primary" style="color:#fff" @click="savechangepasspopup()"><i class="fa fa-save"></i> Save</a>
                <a id="chngepasscloseBTN" type="button" class="btn btn-sm btn-outline-info" @click="closechangepasspopup()">Cancel</a>
            </div>
        </div>

    </modal>

    <modal name="profilepopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="200" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" width="40%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content ">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> Profile</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="save_closeBTN" type="button" class="btn btn-default" @click="closeProfilepopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="margin-top:0px;">
                <div class="row">
                    <div class="col-lg-12 mb-4 mb-sm-0">
                        <div class="card card-style1 border-0">
                            <div class="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                                <div class="row align-items-center">
                                    <div class="col-lg-6 mb-4 mb-lg-0">
                                        <img src="/../../assets/images/profile.png" alt="...">
                                    </div>
                                    <div class="col-lg-6 px-xl-10" v-if="userSessionData != null">
                                        <div class="bg-primary d-lg-inline-block py-1-9 px-1-9 px-sm-6 rounded" style="width: 100%;height: 70px;padding: 10px;">
                                            <h3 class="h2 text-white mb-0">{{userSessionData.user_name}}</h3>
                                        </div>
                                        <p></p>
                                        <ul class="list-unstyled mb-1-9">
                                            <li class="mb-2 mb-xl-3 display-28"><i class="fa fa-user-circle text-c-blue"></i><span class="display-26 text-secondary me-2 font-weight-600"> Full Name:</span> <b>{{userSessionData.user_full_name}}</b></li>
                                            <li class="mb-2 mb-xl-3 display-28"><i class="fa fa-user text-c-blue"></i><span class="display-26 text-secondary me-2 font-weight-600"> Role:</span> <b>{{userSessionData.role}}</b></li>
                                            <li class="mb-2 mb-xl-3 display-28"><i class="fa fa-envelope text-c-blue"></i><span class="display-26 text-secondary me-2 font-weight-600"> Email:</span> <b>{{userSessionData.email}}</b></li>
                                            <li class="display-28"><i class="fa fa-phone text-c-blue"></i><span class="display-26 text-secondary me-2 font-weight-600"> Phone:</span> <b>{{userSessionData.ph_no}}</b></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 px-xl-10" v-else>
                                        <div class="bg-primary d-lg-inline-block py-1-9 px-1-9 px-sm-6 rounded" style="width: 100%;height: 70px;padding: 10px;">
                                            <h3 class="h2 text-white mb-0">No User Info</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <!-- POPUP END * -->
    <!-- [ Pre-loader ] End -->
    <!-- [ navigation menu ] start -->
    
    <nav class="pcoded-navbar theme-horizontal menu-light brand-blue">       
            <div class="">
                <ul class="nav pcoded-inner-navbar sidenav-inner">
                    <li class="nav-item pcoded-menu-caption">
                        <label>Navigation</label>
                    </li>
                    <li class="nav-item" id="myDashboardMENU" @mouseenter="adddropmenu('myDashboardMENU')" @mouseleave="removedropmenu('myDashboardMENU')" @click="calRouterPage('myDashboardMENU','/index')">
                        <a class="nav-link "><span class="pcoded-micon"><i class="feather icon-home"></i></span><span class="pcoded-mtext">Dashboard </span></a>
                    </li>
                <!-- <li class="nav-item pcoded-hasmenu" id="myManagementMENU" @click="adddropmenu('myManagementMENU')" @mouseenter="adddropmenu('myManagementMENU')" @mouseleave="removedropmenu('myManagementMENU')">
                        <a class="nav-link "><span class="pcoded-micon"><i class="feather icon-layout"></i></span><span class="pcoded-mtext">Management</span></a>
                        <ul class="pcoded-submenu">
                            <li id="myLocManagementMENU" @click="calRouterPage('myLocManagementMENU','/locdevicemanagement')"><a>Location & Device Management </a></li>
                            <li id="myUserManagementMENU" @click="calRouterPage('myUserManagementMENU','/usermanagement')"><a>User & SMS / Email Management </a></li>
							<li v-if="userSessionData.role == 'Super User'" id="mySerialManagementMENU" @click="calRouterPage('mySerialManagementMENU','/serialobismanagement')"><a>Serial & OBIS Management </a></li>
                        </ul>
                    </li> -->
					<li class="dropdown" id="myManagementMENU" >
						<a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="feather icon-layout"></i> <b style="color:#00535c">Management </b><span class="caret"></span></a>
						<ul class="dropdown-menu">
							<li id="myLocManagementMENU" style="padding: 8px;cursor: pointer;" @click="calRouterPage('myLocManagementMENU','/locdevicemanagement')"><span><i class="feather icon-settings"></i>  <b>Location & Gateway Management</b> </span></li>
                            <li id="myUserManagementMENU" style="padding: 8px;cursor: pointer;"  @click="calRouterPage('myUserManagementMENU','/usermanagement')"><span><i class="feather icon-settings"></i> <b> User & SMS / Email Management</b> </span></li>
							<li v-if="userSessionData.role == 'Super User'" id="mySerialManagementMENU" style="padding: 8px;cursor: pointer;"  @click="calRouterPage('mySerialManagementMENU','/serialobismanagement')"><span><i class="feather icon-settings"></i> <b>Serial & OBIS Management </b></span></li>
						</ul>
					  </li>
                    <li class="nav-item" id="myFirmwareuploadMENU" @click="calRouterPage('myFirmwareuploadMENU','/configupload')" @mouseenter="adddropmenu('myFirmwareuploadMENU')" @mouseleave="removedropmenu('myFirmwareuploadMENU')">
                        <a class="nav-link " v-if="userSessionData.role == 'Super User'"><span class="pcoded-micon"><i class="feather icon-upload"></i></span><span class="pcoded-mtext">Config / Firmware Upload & Reset</span></a>
						<a class="nav-link " v-else-if="userSessionData.role == 'Admin'"><span class="pcoded-micon"><i class="feather icon-upload"></i></span><span class="pcoded-mtext">Config Upload & Reset</span></a>
						<a class="nav-link " v-else><span class="pcoded-micon"><i class="feather icon-refresh-ccw"></i></span><span class="pcoded-mtext">Restart Gateway</span></a>
                    </li>
                    <li class="nav-item" id="myDiagnosticsMENU" @click="calRouterPage('myDiagnosticsMENU','/diagnostics')" @mouseenter="adddropmenu('myDiagnosticsMENU')" @mouseleave="removedropmenu('myDiagnosticsMENU')">
                        <a class="nav-link "><span class="pcoded-micon"><i class="feather icon-aperture"></i></span><span class="pcoded-mtext">Diagnostics</span></a>
                    </li>
					<li class="nav-item" id="myOndemandcommandMENU" @click="calRouterPage('myOndemandcommandMENU','/ondemandcommand')" @mouseenter="adddropmenu('myOndemandcommandMENU')" @mouseleave="removedropmenu('myOndemandcommandMENU')">
                        <a class="nav-link "><span class="pcoded-micon"><i class="feather icon-activity"></i></span><span class="pcoded-mtext">On Demand Commands</span></a>
                    </li>
                    <li class="nav-item" id="myViewEventMENU" @click="calRouterPage('myViewEventMENU','/viewevents')" @mouseenter="adddropmenu('myViewEventMENU')" @mouseleave="removedropmenu('myViewEventMENU')">
                        <a class="nav-link "><span class="pcoded-micon"><i class="fa fa-align-center"></i></span><span class="pcoded-mtext">View Events</span></a>
                    </li>
                    <!-- <li class="nav-item pcoded-hasmenu" id="myReportsMENU" @click="adddropmenu('myReportsMENU')" @mouseenter="adddropmenu('myReportsMENU')" @mouseleave="removedropmenu('myReportsMENU')">
                        <a class="nav-link "><span class="pcoded-micon"><i class="feather icon-box"></i></span><span class="pcoded-mtext">Reports</span></a>
                        <ul class="pcoded-submenu">
                            <li id="myDailyMonthlyReportMENU" @click="calRouterPage('myDailyMonthlyReportMENU','/automaticgeneratereport')"><a>Daily / Monthly Report</a></li>
                            <li id="myReportMENU" @click="calRouterPage('myReportMENU','/customizereport')" v-show="false"><a>Report</a></li>
                            <li id="myAuditReportMENU" @click="calRouterPage('myAuditReportMENU','/auditreport')"><a>Audit Trail Report</a></li>
                        </ul>
                    </li> -->
					<li class="dropdown" id="myManagementMENU">
						<a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-file"></i> <b style="color:#00535c">Reports </b><span class="caret"></span></a>
						<ul class="dropdown-menu">
							<li id="myDailyMonthlyReportMENU" style="padding: 8px;cursor: pointer;" @click="calRouterPage('myDailyMonthlyReportMENU','/automaticgeneratereport')"><span><i class="feather icon-file"></i>  <b>Daily / Monthly Report</b> </span></li>
                            <li id="myReportMENU" style="padding: 8px;cursor: pointer;" @click="calRouterPage('myReportMENU','/customizereport')" v-show="false"><span><i class="feather icon-file"></i>  <b>Report</b> </span></li>
                            <li id="myAuditReportMENU" style="padding: 8px;cursor: pointer;" @click="calRouterPage('myAuditReportMENU','/auditreport')"><span><i class="feather icon-file"></i>  <b>Audit Trail Report</b> </span></li>
						</ul>
					  </li>
            <li class="nav-item">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </li>
            <li>
              <a class="btn btn-space btn-outline-danger" style="padding: 5px 10px;cursor: text;color: crimson;"> <b>Comm Gateway :</b>
                        <span class="badge badge-info ml-1" style="font-size: 86%;">{{commGw}} / {{totalGw}}</span>
              </a>
                            <!-- <span class="badge badge-pill badge-danger p-2"  style="font-size: 14px;">No of Comm Gateway : 10 / 10</span> -->
                    </li>
                    
                    <li>
                      <a class="btn btn-space btn-outline-danger" style="padding: 5px 10px;cursor: text;color: crimson;"> <b>Comm Meter :</b>
                        <span class="badge badge-info ml-1" style="font-size: 86%;">{{commMet}} / {{totalMet}}</span>
              </a>
                    </li>
                    <div>
                    </div>
                </ul>
                <ul class="nav pcoded-inner-navbar sidenav-inner">
                </ul>
            </div>
    </nav>
    <header class="navbar pcoded-header navbar-expand-lg navbar-light header-blue">
        <div class="container-fluid">
            <div class="m-header">
                <a class="mobile-menu" id="mobile-collapse"><span></span></a>
                <a href="https://www.cmsgp.com" target="_blank" class="b-brand">
                    <!-- ========   change your logo hear   ============ -->
                    <img src="/../../assets/images/cmsLogo.png" alt="cmsgp" class="logo" style="width: 65px;background: white;" title="Creative Micro Systems">
                    <img src="/../../assets/images/cmsLogo.png" alt="cmsgp" class="logo-thumb" style="width: 65px;background: white;" title="Creative Micro Systems">
                </a>
                <!-- <a class="mob-toggler">
                    <i class="feather icon-more-vertical"></i>
                </a> -->
            </div>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto left"><img src="/../../assets/images/ev-logo.png" />
                    <h4 style="margin-top: 10px;color:#fff"> &nbsp;&nbsp; {{GUIConfig.GUItitle}}</h4>
                </ul>
                <ul class="navbar-nav ml-auto">
                    <li>
                        <i class="fa fa-clock text-c-green"></i>
                        <span> <b> {{currDateTime}} </b> </span>
                        &nbsp;&nbsp; &nbsp;&nbsp;
                    </li>
					      <li title="Mail to support" @click="showMailToSupport()">
                <div class="dropdown drp-user">
                <a>
								<i class="fa fa-envelope-open" aria-hidden="true"></i>
                            </a>
                        </div>
                    </li>
                    <li title="help" v-show="false">
                        <div class="dropdown drp-user">
                            <a>
                                <i class="fa fa-question-circle"></i>
                            </a>
                        </div>
                    </li>
                    <li v-if="alertArrayList.length != 0">
                        <div class="dropdown">
                            <a class="dropdown-toggle" style="width: 54px !important;" data-toggle="dropdown"><i class="icon feather icon-bell"></i><a class="img-radius bg-c-red badge badge-light-danger text-white" style="top:5px;position: absolute;">{{alertArrayList.length}}</a></a>
                            <div class="dropdown-menu dropdown-menu-right notification">
                                <div class="noti-head">
                                    <h6 class="d-inline-block m-b-0"><i class="fa fa-bell"></i> Alerts</h6>
                                    <div class="float-right" v-show="false">
                                        <a>clear all</a>
                                    </div>
                                </div>
                                <ul class="noti-body">
                                    <li class="notification" v-for="(row,index) in alertArrayList" :key="index">
                                        <div class="media">
                                            <img class="" src="/../../assets/images/alert.png" alt="alert" style="width:20px">
                                            <div class="media-body">
                                                <p class="m-b-0"><strong>{{row.alert_name}}</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>{{row.date_time}}</span></p>
                                                <span style="font-size: 12px;">{{row.alert_msg}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown drp-user">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                <i class="feather icon-user"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right profile-notification">
                                <div class="pro-head">
                                    <img src="/../../assets/images/admin.png" class="img-radius" alt="User-Profile-Image">
                                    <span>{{userSessionData.user_name}}</span>
                                    <!-- <a  class="dud-logout" title="Logout"  @click="logout()">

<i class="feather icon-log-out"></i>

</a> -->
                                </div>
                                <ul class="pro-body">
                                    <li><a class="dropdown-item" @click="openProfilePopup()"><i class="feather icon-user"></i> Profile</a></li>
                                    <li @click="calChangePassPopup()"><a class="dropdown-item"><i class=" fa fa-key"></i> Change Password</a></li>
                                    <li><a class="dropdown-item" @click="logoutAction()"><i class="feather icon-log-out"></i> Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </header>
	<modal name="mailtosupportPopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="350" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"><i class="fa fa-wrench" aria-hidden="true"></i> Mail to Support</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hidemailtosupportPopupcloseBTN" type="button" class="btn btn-default" @click="hideMailToSupport()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:0px;background:#fff">
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-3">
                        User Name
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-9">
                        <input type="text" v-model="mailUsernameTXT" placeholder="Enter the User Name" id="mailUsernameTXT" maxlength="32" class="mx-input" disabled name="mailUsernameTXT" />
                    </div>
                </div>
                <div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-3">
                		Category
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-9">
						<select id="mailCategoryTXT" v-model="mailCategoryTXT" class="mx-input">
							<option value="Features" selected>Features</option>
                            <option value="Report">Report</option>
                            <option value="Complaint">Complaint</option>
							<option value="Enquiry">Enquiry</option>
							<option value="Others">Others</option>
                        </select>
                    </div>
                </div>
				<div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-3">
                		Message
                        <span style="color: red">*</span>:
                    </div>
                    <div class="col-lg-9">
						<textarea v-model="mailMessageTXT" placeholder="Enter Your Message" type="text" style="width: 365px;height: 250px;min-height:100px;max-height:300px;" class="mx-input"></textarea>
                    </div>
                </div>
				<div class="row" style="margin:0px;padding: 5px;">
                    <div class="col-lg-3">
                		Attachment (Optional) :
                    </div>
                    <div class="col-lg-9">
						<input id="file_input" type="file" @change="uploadFile" ref="file" accept="image/*" />
                    </div>
                </div>
				<div class="row" style="margin-left: 10px;">
    <div class="item" title="worst">
      <label for="0">
      <input class="radio" type="radio" v-model="feedbacksts" name="feedback" id="0" value="0">
      <span>😔</span>
    </label>
    </div>

    <div class="item"  title="normal">
      <label for="1">
      <input class="radio" type="radio" v-model="feedbacksts" name="feedback" id="1" value="1">
      <span>🙁</span>
    </label>
    </div>

    <div class="item"  title="average">
      <label for="2">
      <input class="radio" type="radio" v-model="feedbacksts" name="feedback" id="2" value="2">
      <span>😃</span>
    </label>
    </div>

    <div class="item" title="good">
      <label for="3">
      <input class="radio" type="radio" v-model="feedbacksts" name="feedback" id="3" value="3">
      <span>😁</span>
    </label>
    </div>

    <div class="item" title="excellent">
      <label for="4">
      <input class="radio" type="radio" v-model="feedbacksts" name="feedback" id="4" value="4">
      <span>😍</span>
    </label>
    </div>

  </div>
            </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="saveMessagepopupBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="saveMailtosupportPopupFunction()"><i class="fa fa-envelope" aria-hidden="true"></i> Send Message</a>
            </div>
        </div>
    </modal>
    <!--Device  Add POPUP START-->
</section>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import GUIConfig from "@/GUI_Config";
import apiURL from "@/API_Config";
import commMethods from "@/api/common_methods.js";
import swal from "sweetalert";
import VueElementLoading from "vue-element-loading";
let timeout;

let strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
);
let mediumPassword = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
);

export default {
  watch: {},
  name: "Header",
  components: { swal, VueElementLoading },
  data() {
    return {
      timeout: null,
      strongPassword: new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      ),
      mediumPassword: new RegExp(
        "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
      ),
      GUIConfig: GUIConfig,
      userSessionData: JSON.parse(sessionStorage.getItem("loginsession")),
      isLoading: false,
      currDateTime: null,
      totalGw:0,
      commGw:0,
      totalMet:0,
      commMet:0,
      feedbacksts: 4,
      currusername: "",
      alertArrayList: [],
      headerInterval: null,
      userObj: "",
      changecurrpassTXT: "",
      changenewpassTXT: "",
      changeconfrimPassTXT: "",
      currtypevalue: "password",
      currimgvalue: "assets/images/password_close.png",
      newtypevalue: "password",
      newimgvalue: "assets/images/password_close.png",
      confrmtypevalue: "password",
      confrmimgvalue: "assets/images/password_close.png",
      mailUsernameTXT: "",
      mailCategoryTXT: "Features",
      attach_sts: "false",
      mailMessageTXT: "",
      attachImages: "",
      attach_filename: "",
      gwNdMetInterval:null,
	  uploadinterval:null,
      btnArrList: [
        {
          id: "myDashboardMENU",
          path: "/ev/index",
        },
        {
          id: "myLocManagementMENU",
          path: "/ev/locdevicemanagement",
        },
        {
          id: "myUserManagementMENU",
          path: "/ev/usermanagement",
        },
        {
          id: "mySerialManagementMENU",
          path: "/ev/serialobismanagement",
        },
        {
          id: "myFirmwareuploadMENU",
          path: "/ev/configupload",
        },
        {
          id: "myDiagnosticsMENU",
          path: "/ev/diagnostics",
        },
		{
          id: "myOndemandcommandMENU",
          path: "/ev/ondemandcommand",
        },
        {
          id: "myDailyMonthlyReportMENU",
          path: "/ev/automaticgeneratereport",
        },
        {
          id: "myReportMENU",
          path: "/ev/customizereport",
        },
        {
          id: "myAuditReportMENU",
          path: "/ev/auditreport",
        },
        {
          id: "myViewEventMENU",
          path: "/ev/viewevents",
        },
          {
            id: "myserialdevManagementMENU",
            path: "/rms/serialmanagement",
            menu_id:"managementLIMenu"
          },
      ],
    };
  },
  computed: {
    coPilotNumbers() {
      return this.generateRandomNumbers(12, 1000000, 10000);
    },
    personalNumbers() {
      return this.generateRandomNumbers(12, 1000000, 10000);
    },
    isMobile() {
      return window.innerWidth <= 800 && window.innerHeight <= 600;
    },
  },
  async mounted() {
    this.userSessionData = JSON.parse(sessionStorage.getItem("loginsession"));
    if (
      sessionStorage.getItem("loginsession") == undefined ||
      sessionStorage.getItem("loginsession") == null
    ) {
      await this.clearALLSession();
      return false;
    }
    await this.pathaddclassList(this.$route.path);
    // await this.callAlertLoad();
    this.$root.$on("clearALLSessionFunction", () => {
      // your code goes here
      this.clearALLSession();
    });
    this.$root.$on("terminateSesstionFunction", async (msg) => {
      // your code goes here
     await this.terminateSesstion(msg);
    });
    
    await this.callGWNdMETStatusLoad();
    // setTimeout(async () => {
    // 	await this.getRefreshTokenDetails();
    // }, 4000);
  },
  created() {
    this.currDateTimeInterval = setInterval(() => {
      this.currDateTime = this.datewithTime(new Date());
    }, 1000);
  },
  destroyed() {
    clearInterval(this.headerInterval);
    clearInterval(this.currDateTimeInterval);
    clearInterval(this.gwNdMetInterval);
    if (this.refreshTokenInterval != null) {
      clearInterval(this.refreshTokenInterval);
    }
    sessionStorage.clear();
  },
  methods: {
    async callGWNdMETStatusLoad(){
    await this.getGWNdMETStatusDetails();
    var api_sent = false;
    var response_received = true;
    this.gwNdMetInterval = setInterval(async () => {
      console.log('callGWNdMETStatusLoad :api_sent :'+ api_sent + ': response_received :'+ response_received);
      if (api_sent == false && response_received == true) {
        console.log("callGWNdMETStatusLoad API Request Sent");
        api_sent = true;
        response_received = false;
        var startDate = new Date();
		    await this.getGWNdMETStatusDetails();
        var endDate = new Date();
        var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        console.log("Total Elapsed : ", seconds);
        console.log("****callGWNdMETStatusLoad**API*****END****");
        response_received = true;
        api_sent = false;
      }
    }, 40000);
    },
    async getGWNdMETStatusDetails() {
            let overallcommListJSON = await commMethods.apiGETmethod(apiURL.overallcommListURL, null,this.userSessionData);
            console.log('JSON :::overallcommListJSON::: ' + JSON.stringify(overallcommListJSON));
            if (overallcommListJSON != undefined && overallcommListJSON.data.status == "found") {
              this.totalGw = overallcommListJSON.data.total_gw;
              this.commGw = overallcommListJSON.data.comn_gw;
              this.totalMet= overallcommListJSON.data.total_met;
              this.commMet= overallcommListJSON.data.comn_met;
            }else{
              this.totalGw = 0;
              this.commGw = 0;
              this.totalMet = 0;
              this.commMet = 0;
            }
        },
    async logoutAction() {
  	 var alertval = confirm('Are you sure you want to logout ?')
    if (alertval) {
      this.isLoading = true;
	  	var logoutSTRJSON =  {
				user_name: this.userSessionData.user_name,
        password: this.userSessionData.password
		};
    await this.$root.$emit('clearDashSectionFunction');
		let logoutJSON = await commMethods.apiPOSTmethod(apiURL.logoutURL,logoutSTRJSON,this.userSessionData);
			console.log('logoutJSON  :: '+JSON.stringify(logoutJSON.data));
			if (logoutJSON != undefined && logoutJSON.data.status == "success") {
				this.clearALLSession();
        this.isLoading = false;
			}else{
				this.errorMessage(logoutJSON.data.status);
        this.isLoading = false;
				return false;
			}
	  }
    },
    terminateSesstion(msg){
      clearInterval(this.headerInterval);
      clearInterval(this.currDateTimeInterval);
      clearInterval(this.gwNdMetInterval);
      if (this.refreshTokenInterval != null) {
        clearInterval(this.refreshTokenInterval);
      }
      const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
    // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
          window.clearInterval(i);
      }
      sessionStorage.clear();
      this.logout();
      alert(msg);
      return false;
    },
    clearALLSession() {
      clearInterval(this.headerInterval);
      clearInterval(this.currDateTimeInterval);
      clearInterval(this.gwNdMetInterval);
      if (this.refreshTokenInterval != null) {
        clearInterval(this.refreshTokenInterval);
      }
      const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);
      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
          window.clearInterval(i);
      }
      sessionStorage.clear();
      this.logout();
    },
    uploadFile() {
      this.isLoading = true;
	  this.uploadinterval = setInterval(() => {
	  if (this.attach_filename != "") {
       	 this.isLoading = false;
		 clearInterval(this.uploadinterval);
      }
	  }, 100);
      this.attachImages = this.$refs.file.files[0];
      this.attach_filename = this.$refs.file.files[0].name;
      console.log("--this.attach_filename->>" + this.attach_filename);
	 

    },
    showMailToSupport() {
      this.mailUsernameTXT = this.userSessionData.user_name;
      this.mailCategoryTXT = "Features";
      this.mailMessageTXT = "";
      this.$modal.show("mailtosupportPopup");
    },
    hideMailToSupport() {
      this.$modal.hide("mailtosupportPopup");
    },
    async saveMailtosupportPopupFunction() {
      this.isLoading = true;
      if (this.mailUsernameTXT == "") {
        this.warningMessage("Please enter user name");
        this.isLoading = false;
        return false;
      }
      if (this.mailMessageTXT == "") {
        this.warningMessage("Please enter message");
        this.isLoading = false;
        return false;
      }

      if (this.attachImages != "") {
        this.attach_sts = "true";
      } else {
        this.attach_sts = "false";
      }
      var formObj = {
        user_name: this.mailUsernameTXT,
        role: this.userSessionData.role,
        category: this.mailCategoryTXT,
        msg: this.mailMessageTXT,
        attach_sts: this.attach_sts,
        file_name: this.attach_filename,
        feedback_sts: this.feedbacksts,
      };
      console.log("---attachImages--->>", this.attachImages);
      console.log("---formObj--->>", formObj);

      try {
        var url = apiURL.supportMailURL;
		var _this = this;
	  	var formData = new FormData();
  		formData.append("config_file", _this.attachImages);
  		//formData.append("id", 7878);
  		var request = new XMLHttpRequest();
		console.log("--------URL------------>>>>"+apiURL.supportMailURL);
  		request.open("POST", apiURL.supportMailURL);
		request.setRequestHeader( "Authorization",  _this.userSessionData.access_token);
        request.setRequestHeader("From", JSON.stringify(formObj));
  		request.send(formData);
  		request.onreadystatechange = function (response) {
	    console.log("--------request------------>>>>"+JSON.stringify(request));
		console.log("--------response------------>>>>",response);
    	if (request.readyState === 4) {
      	if (request.status === 200 && request.statusText === 'OK') {
			console.log("-----supportMail---response------------>>>>"+JSON.stringify(response));
			swal({
                    title: "Success!",
                    text: "The message was successfully sent to support team.",
                    icon: "success",
                    button: "Close",
                  });
                  _this.hideMailToSupport();
                  _this.isLoading = false;
                  return true;
      } else {
		swal({
                    title: "Warning!",
                    text: dataObj.status,
                    icon: "warning",
                    button: "Close",
                  });
                  _this.isLoading = false;
                  _this.hideMailToSupport();
                  return true;
      }
      }
  	}

        // var read = new FileReader();
		// var formData = new FormData();
        // read.readAsArrayBuffer(this.attachImages);
        // read.onloadend = function() {
        //   var xmlhttp = new XMLHttpRequest();
        //   xmlhttp.onreadystatechange = function() {
        //     if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        //       try {
        //         var dataObj = JSON.parse(xmlhttp.responseText);
        //         // console.log('submitFile1 dataObj ' + JSON.stringify(dataObj));
        //         if (dataObj.status === "success") {
        //           swal({
        //             title: "Success!",
        //             text: "The message was successfully sent support team.",
        //             icon: "success",
        //             button: "Close",
        //           });
        //           _this.hideMailToSupport();
        //           _this.isLoading = false;
        //           return true;
        //         } else {
        //           swal({
        //             title: "Warning!",
        //             text: dataObj.status,
        //             icon: "warning",
        //             button: "Close",
        //           });
        //           _this.isLoading = false;
        //           _this.hideMailToSupport();
        //           return true;
        //         }
        //       } catch (error) {
        //         _this.isLoading = false;
        //         alert(error);
        //       }
        //     }
        //   };
        //   console.log("apiURL.supportMailURL :" + url);
        //   xmlhttp.open("POST", url, true);
        //   xmlhttp.setRequestHeader("Cache-Control", "no-cache");
        //   xmlhttp.setRequestHeader("Content-Type", "multipart/form-data");
        //   xmlhttp.setRequestHeader( "Authorization",  _this.userSessionData.access_token);
        //   xmlhttp.setRequestHeader("From", JSON.stringify(formObj));
        //   xmlhttp.send(read.result);
        // };

        // var formData = new FormData();
        // formData.append("attachfilesData", _this.attachImages);
        // formData.append("user_name", _this.mailUsernameTXT);
        // formData.append("role", _this.userSessionData.role.toString());
        // formData.append("category", _this.mailCategoryTXT.toString());
        // formData.append("msg", _this.mailMessageTXT.toString());
        // formData.append("attach_sts", _this.attach_sts.toString());
        // formData.append("feedback_sts", _this.feedbacksts.toString());
        //console.log("---formData--->>", formData.getAll("user_name"));
        // var xhr = new XMLHttpRequest();
        // xhr.onreadystatechange = function() {
        //   if (xhr.readyState === 4 && xhr.status === 200) {
        //     var dataObj = JSON.parse(xhr.responseText);
        //     console.log("dataObj :" + JSON.stringify(dataObj));
        // 	if (dataObj.status === 'success') {
        // 	    		swal({
        //                 title: "Success!",
        //                 text: "The message was successfully sent support team.",
        //                 icon: "success",
        //                 button: "Close",
        //             });
        // 			_this.hideMailToSupport();
        // 			_this.isLoading = false;
        //             return true;
        // 		}else{
        // 			swal({title: "Warning!",
        // 						text: dataObj.status,
        // 						icon: "warning",
        // 						button: "Close",
        // 						});
        // 						_this.isLoading = false;
        // 			_this.hideMailToSupport();
        //             return true;
        // 		}
        //   }
        // };
        // console.log("===supportMailURL :" + apiURL.supportMailURL);
        // xhr.open("POST", apiURL.supportMailURL, true);
        // xhr.setRequestHeader("Content-Type", "multipart/form-data");
        // xhr.setRequestHeader("Authorization", this.userSessionData.access_token);
        // xhr.setRequestHeader("From", JSON.stringify(formObj));
        // xhr.send(formData);
      } catch (error) {
        console.error("--error->>" + error);
      }
    },
    async getRefreshTokenDetails() {
      console.log("<--|-CALL getRefreshTokenDetails-|-->");
      this.userSessionData = JSON.parse(sessionStorage.getItem("loginsession"));
      console.log("AFTER JSON  :" + JSON.stringify(this.userSessionData));
      var api_sent = false;
      var response_received = true;
      this.refreshTokenInterval = setInterval(async () => {
        console.log(
          "getRefreshTokenDetails :api_sent :" +
            api_sent +
            ": response_received :" +
            response_received
        );
        if (api_sent == false && response_received == true) {
          console.log("getRefreshTokenDetails API Request Sent");
          api_sent = true;
          response_received = false;
          var startDate = new Date();
          var inputJson = {
            user_details: this.userSessionData,
          };
          let refreshJSON = await commMethods.apiPOSTmethod(
            apiURL.refreshTokenURL,
            inputJson,
            this.userSessionData
          );
          console.log("JSON :::refreshJSON::: " + JSON.stringify(refreshJSON));
          if (refreshJSON != undefined && refreshJSON.data.status == "found") {
            sessionStorage.setItem(
              "loginsession",
              JSON.stringify(refreshJSON.data.user_details)
            );
          }
          var endDate = new Date();
          var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
          console.log("Total Elapsed : ", seconds);
          console.log("****getRefreshTokenDetails**API*****END****");
          response_received = true;
          api_sent = false;
        }
      }, 3500);
    },
    async callAlertLoad() {
      await this.getAlertList();
      var api_sent = false;
      var response_received = true;
      this.headerInterval = setInterval(async () => {
        console.log(
          "callAlertLoad :api_sent :" +
            api_sent +
            ": response_received :" +
            response_received
        );
        if (api_sent == false && response_received == true) {
          console.log("callAlertLoad API Request Sent");
          api_sent = true;
          response_received = false;
          var startDate = new Date();
          await this.getAlertList();
          var endDate = new Date();
          var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
          console.log("Total Elapsed : ", seconds);
          console.log("****callAlertLoad**API*****END****");
          response_received = true;
          api_sent = false;
        }
      }, GUIConfig.runIntervalTime);
    },
    pathaddclassList(path) {
      console.log("path:: ", path);
      for (var t = 0; t < this.btnArrList.length; t++) {
        if (path == this.btnArrList[t].path) {
          document
            .getElementById(this.btnArrList[t].id)
            .classList.add("activeRouter");
        } else {
          var element = document.getElementById(this.btnArrList[t].id);
          if (typeof element !== "undefined" && element !== null) {
            element.classList.remove("activeRouter");
          }
        }
      }
    },
    removeaddclassList(id, path) {
      for (var t = 0; t < this.btnArrList.length; t++) {
        var element = document.getElementById(this.btnArrList[t].id);
        if (typeof element !== "undefined" && element !== null) {
          element.classList.remove("active", "activeRouter");
        }
      }
      document.getElementById(id).classList.add("activeRouter");
    },
    removeALLclassList() {
      for (var t = 0; t < this.btnArrList.length; t++) {
        var element = document.getElementById(this.btnArrList[t].id);
        if (typeof element !== "undefined" && element !== null) {
          element.classList.remove("active", "activeRouter");
        }
      }
    },
    async savechangepasspopup() {
      this.isLoading = true;
      if (this.changecurrpassTXT == "") {
        this.isLoading = false;
        this.warningMessage("Please enter a current password.");
        return false;
      }
      if (this.changenewpassTXT == "") {
        this.isLoading = false;
        this.warningMessage("Please enter a new password.");
        return false;
      }
      let strengthBadge = document.getElementById("StrengthDisp");
      if (strengthBadge) {
        console.log("strengthBadge TXT :" + strengthBadge.textContent);
        if (strengthBadge.textContent != "Strong") {
          this.warningMessage(
            "Password must contain at least one number and one uppercase and one special character (eg: @, $), and at least 8 characters"
          );
          this.isLoading = false;
          return false;
        }
      }
      if (this.changeconfrimPassTXT == "") {
        this.isLoading = false;
        this.warningMessage("Please enter a confrim new password.");
        return false;
      }
      if (this.changenewpassTXT != this.changeconfrimPassTXT) {
        this.isLoading = false;
        this.warningMessage("Passwords do not match.");
        return false;
      }
      var changePassjson = {
        user_name: this.currusername.toString(),
        oldpassword: this.changecurrpassTXT.toString(),
        newpassword: this.changenewpassTXT.toString(),
      };
      console.log("-changePassjson->>>>" + JSON.stringify(changePassjson));
      let chnagepassJson = await commMethods.apiPOSTmethod(
        apiURL.changepwdURL,
        changePassjson,
        this.userSessionData
      );
      console.log("JSON :: " + JSON.stringify(chnagepassJson));
      if (
        chnagepassJson != undefined &&
        chnagepassJson.data.status == "success"
      ) {
        this.closechangepasspopup();
        this.successMessage("Password successfully updated.");
        this.isLoading = false;
        return false;
      } else {
        this.isLoading = false;
        this.errorMessage("Incorrect password.");
        return false;
      }
    },
    async getAlertList() {
      let alertsListJSON = await commMethods.apiGETmethod(
        apiURL.alertsListURL,
        null,
        this.userSessionData
      );
      console.log(
        "JSON :::getAlertList::: " + JSON.stringify(alertsListJSON.data)
      );
      if (
        alertsListJSON != undefined &&
        alertsListJSON.data.status == "found"
      ) {
        this.alertArrayList = alertsListJSON.data.details;
      } else {
        this.alertArrayList = [];
      }
    },
    openProfilePopup() {
      this.$modal.show("profilepopup");
    },
    closeProfilepopup() {
      this.$modal.hide("profilepopup");
    },
    calChangePassPopup() {
      this.currusername = this.userSessionData.user_name;
      this.changecurrpassTXT = "";
      this.changenewpassTXT = "";
      this.changeconfrimPassTXT = "";
      this.$modal.show("changePasswordpopup");
    },
    closechangepasspopup() {
      this.$modal.hide("changePasswordpopup");
    },
    StrengthChecker(PasswordParameter) {
      // We then change the badge's color and text based on the password strength
      let password = document.getElementById("changenewpassTXT");
      let strengthBadge = document.getElementById("StrengthDisp");
      if (strengthBadge) {
        if (strongPassword.test(PasswordParameter)) {
          strengthBadge.style.backgroundColor = "green";
          strengthBadge.textContent = "Strong";
          strengthBadge.style.color = "white";
        } else if (mediumPassword.test(PasswordParameter)) {
          strengthBadge.style.backgroundColor = "blue";
          strengthBadge.textContent = "Medium";
          strengthBadge.style.color = "white";
        } else {
          strengthBadge.style.backgroundColor = "red";
          strengthBadge.textContent = "Weak";
          strengthBadge.style.color = "black";
        }
      }
    },
    passwordEventListener() {
      //The badge is hidden by default, so we show it
      let password = document.getElementById("changenewpassTXT");
      let strengthBadge = document.getElementById("StrengthDisp");
      if (strengthBadge) {
        strengthBadge.style.display = "block";
      }
      clearTimeout(this.timeout);

      //We then call the StrengChecker function as a callback then pass the typed password to it

      this.timeout = setTimeout(
        () => this.StrengthChecker(password.value),
        100
      );

      //Incase a user clears the text, the badge is hidden again

      if (password.value.length !== 0) {
        if (strengthBadge) {
          strengthBadge.style.display != "block";
        }
      } else {
        if (strengthBadge) {
          strengthBadge.style.display = "none";
        }
      }
    },
    showPassword1() {
      if (this.currtypevalue === "password") {
        this.currtypevalue = "text";
        this.currimgvalue = "assets/images/password_open.png";
      } else {
        this.currtypevalue = "password";
        this.currimgvalue = "assets/images/password_close.png";
      }
    },
    showPassword2() {
      if (this.newtypevalue === "password") {
        this.newtypevalue = "text";
        this.newimgvalue = "assets/images/password_open.png";
      } else {
        this.newtypevalue = "password";
        this.newimgvalue = "assets/images/password_close.png";
      }
    },
    showPassword3() {
      if (this.confrmtypevalue === "password") {
        this.confrmtypevalue = "text";
        this.confrmimgvalue = "assets/images/password_open.png";
      } else {
        this.confrmtypevalue = "password";
        this.confrmimgvalue = "assets/images/password_close.png";
      }
    },
    datewithTime(dateval) {
      var date = new Date(dateval);
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var year = date.getFullYear();
      var hour = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();

      var monthStr = month <= 9 ? "0" + month : month;
      var dateStr = day <= 9 ? "0" + day : day;
      var hourStr = hour <= 9 ? "0" + hour : hour;
      var minStr = min <= 9 ? "0" + min : min;
      var secStr = sec <= 9 ? "0" + sec : sec;
      return (
        dateStr +
        "-" +
        monthStr +
        "-" +
        year +
        " " +
        hourStr +
        ":" +
        minStr +
        ":" +
        secStr
      );
    },
    beforeOpen() {},
    beforeClose() {},
    opened(e) {},
    closed(e) {},
    successMessage(msg) {
      this.$notice.success({
        title: "Notification",
        description: msg,
        duration: 3,
        onClose() {
          // tslint:disable-next-line:no-console
        },
      });
    },
    warningMessage(msg) {
      this.$notice.warning({
        title: "Notification",
        description: msg,
        duration: 3,
        onClose() {
          // tslint:disable-next-line:no-console
        },
      });
    },
    infoMessage(msg) {
      this.$notice.info({
        title: "Notification",
        description: msg,
        duration: 3,
        onClose() {
          // tslint:disable-next-line:no-console
        },
      });
    },
    errorMessage(msg) {
      this.$notice.error({
        title: "Notification",
        description: msg,
        duration: 3,
        onClose() {
          // tslint:disable-next-line:no-console
        },
      });
    },
    adddropmenu(menuid) {
      console.log("-------ADD CSS-------->");
      var element = document.getElementById(menuid);
      element.classList.add("active");
      element.classList.add("pcoded-trigger");
    },
    removedropmenu(menuid) {
      console.log("-------LEAVE CSS-------->");
      var element = document.getElementById(menuid);
      element.classList.remove("active");
      element.classList.remove("pcoded-trigger");
    },
    calRouterPage(id, path) {
      this.removedropmenu(id);
      this.adddropmenu(id);
      this.$router.replace({
        path: "/ev" + path,
      });
      this.removeaddclassList(id, path);
    },
    logout() {
      this.$router.replace({
        path: "/",
      });
    },
  },
};
</script>

<style scoped>
.flexboxcontainer{
    display:flex;
    /* grid-template-columns: repeat(auto-fit, minmax(500px,1fr)); */
    flex-wrap:wrap;
}
.pcoded-navbar.theme-horizontal {
    display: block;
    height: 50px;
    width: 100%;
    z-index: 1023;
    position: fixed;
    border-radius: 0;
    top: 50px;
    margin-top: 0;
}
@media only screen and (min-width: 901px) and (max-width: 1600px) {
  .pcoded-navbar.theme-horizontal {
    display: block;
    height: 110px;
    width: 100%;
    z-index: 1023;
    position: fixed;
    border-radius: 0;
    top: 50px;
    margin-top: 0;
}
}
@media only screen and (min-width: 501px) and (max-width: 900px) {
  .pcoded-navbar.theme-horizontal {
    display: block;
    height: 150px;
    width: 100%;
    z-index: 1023;
    position: fixed;
    border-radius: 0;
    top: 50px;
    margin-top: 0;
}
}
@media only screen and (min-width: 101px) and (max-width: 500px) {
  .pcoded-navbar.theme-horizontal {
    display: block;
    height: 300px;
    width: 100%;
    z-index: 1023;
    position: fixed;
    border-radius: 0;
    top: 50px;
    margin-top: 0;
}
}
.item {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.radio {
  display: none;
}
.radio ~ span {
  font-size: 1rem;
  filter: grayscale(100);
  cursor: pointer;
  transition: 0.3s;
}

.radio:checked ~ span {
  filter: grayscale(0);
  font-size: 1.5rem;
}

.activeRouter {
  /*
    background: #d5efd3; */
  background: linear-gradient(120deg, #dae6ff 0%, #95c0c5 100%);
  border-radius: 10px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li {
  margin: 3px;
  padding: 0;
}

.eyeimgpass {
  left: 89%;
  position: absolute;
  top: 30%;
  width: 20px;
  cursor: pointer;
}
</style>
