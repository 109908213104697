
import Vue from 'vue';
import VueRouter from 'vue-router';
import { sync } from 'vuex-router-sync';
import routes from './routes';
import store from './store';
import AppView from './components/App.vue';
import VueSessionStorage from 'vue-sessionstorage';
import VueApexCharts from "vue3-apexcharts";
import vmodal from 'vue-js-modal';
import CripNotice from "crip-vue-notice";
Vue.use(vmodal);
Vue.use(VueApexCharts);
Vue.use(VueRouter);
Vue.use(CripNotice);
Vue.use(VueSessionStorage);
// createApp(App).mount('#app')


var router = new VueRouter({
    routes: routes,
    el: 'root',
    //mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior: function(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 };
    }
});

// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
    if (
        to.matched.some(record => record.meta.requiresAuth) &&
        (!router.app.$store.state.token || router.app.$store.state.token === 'null')
    ) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        window.console.log('Not authenticated');
        next({
            path: '/',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
});

sync(store, router);

// Check local storage to handle refreshes
if (window.localStorage) {
    var localUserString = window.sessionStorage.getItem('user') || 'null';
    // console.log('localUserString :', localUserString);
    var localUser = JSON.parse(localUserString);
    if (localUser && store.state.user !== localUser) {
        store.commit('SET_USER', localUser);
        store.commit('SET_TOKEN', window.sessionStorage.getItem('token'));
    }
}

new Vue({
    el: '#root',
    router: router,
    store: store,
    render: h => h(AppView)
});
