<template>
<div class="pcoded-main-container">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="row">
                <!-- [ sample-page ] start -->
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header" style="padding: 10px 0px 0px 10px;">
                            <div class="row">
                                <div class="col-lg-2">
                                    <h5 style="margin-top: 10px;">Report</h5>
                                </div>
                                <div class="col-lg-9" style="margin-left: -30px;">
                                    <label><b>Choose Location :</b> &nbsp;</label>
                                    <select id="locationSelDrpTXT" v-model="locationSelDrpTXT" class="mx-input" style="width: 10%;" @change="changeLocationMthod(locationSelDrpTXT)">
                                        <option value="Choose Location" selected disabled>Choose Location</option>
                                        <option v-for="(item,index) in locDetailsArr" :key="index" :value="item.id+'$$'+item.loc_name">{{item.loc_name}}</option>
                                    </select>&nbsp;&nbsp;
                                    <label><b>Choose Device :</b> &nbsp;</label>
                                    <select id="deviceSelDrpTXT" v-model="deviceSelDrpTXT" class="mx-input" style="width: 10%;" @change="changeDeviceMthod($event)">
                                        <option selected disabled value="Choose Device">Choose Device</option>
                                        <option v-for="(item,index) in devDetailsArr" :key="index" :value="item.id+'$$'+item.dev_name">{{item.dev_name}}</option>
                                    </select>&nbsp;&nbsp;
                                    <label><b>Choose Meter :</b> &nbsp;</label>
                                    <select id="meterSelDrpTXT" v-model="meterSelDrpTXT" class="mx-input" style="width: 10%;" @change="changeMeterMthod($event)">
                                        <option selected disabled value="Choose Meter">Choose Meter</option>
                                        <option v-for="(item,index) in metDetailsArr" :key="index" :value="item.id+'$$'+item.met_name">{{item.met_name}}</option>
                                    </select>&nbsp;&nbsp;
                                    <label><b>Start Date :</b> &nbsp;</label>
                                    <date-picker v-model="startDATE_RANGE_TXT" format="DD MMM YYYY" value-type="format" placeholder="Start Date" :disabled-date="notAfterToday" :editable="false" style="width: 10%;" :clearable="false" @change="startPickerChange" valueType="format"></date-picker>
                                    &nbsp;&nbsp;
                                    <label><b>End Date : </b>&nbsp;</label>
                                    <date-picker v-model="endDATE_RANGE_TXT" format="DD MMM YYYY" style="width: 10%;" value-type="format" placeholder="End Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="endPickerChange" valueType="format"></date-picker>
                                    &nbsp;&nbsp;
                                    <button type="button" class="btn btn-sm btn-outline-info has-ripple" style="margin-top: -2px;" @click="reportGENDetails()"><i class="mr-2 fa fa-check-circle"></i>Submit</button>
                                </div>
                                <div class="col-lg-1 text-right" v-show="reportTblJson.length != 0">
                                    <button type="button" class="btn btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="feather icon-more-horizontal"></i>
                                    </button>
                                    <ul class="list-unstyled card-option dropdown-menu dropdown-menu-right">
                                        <li class="dropdown-item" @click="downloadExport('excel')"><a><span><i class="fa fa-file-excel" style="color: green;"></i> Excel</span></a></li>
                                        <li class="dropdown-item" @click="downloadExport('pdf')"><a><span><i class="fa fa-file-pdf text-c-red"></i> Pdf</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="">
                                <div class="table-border-style">
                                    <div class="table-responsive" v-bind:style="{'height':(pageLayoutHeight-270)+'px'}" style="border:1px solid lightgray">
                                        <table class="table table-striped table-bordered">
                                            <thead style="position: sticky;top: -1px;z-index:1;">
                                                <tr>
                                                    <th style="text-align: center;">#</th>
                                                    <th style="text-align: center;">Date Time</th>
                                                    <th style="text-align: center;">Active Energy</th>
                                                    <th style="text-align: center;">Reactive Energy</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(tblitems,index) in reportTblJson" v-bind:key="index">
                                                <tr>
                                                    <td style="text-align: center;">{{ index+1 }}</td>
                                                    <td style="text-align: center;">{{tblitems.date_time}}</td>
                                                    <td style="text-align: center;">{{tblitems.act}}</td>
                                                    <td style="text-align: center;">{{tblitems.react}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div v-if="reportTblJson.length == 0" style="text-align:center;width: 100%;">
                                            <span>No records found</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
                <!-- [ sample-page ] end -->
            </div>
            <!-- [ Main Content ] end -->
        </div>
    </div>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js'
var myDate = new Date();
export default {
    name: "CustomizeReport",
    components: {
        DatePicker,
        VueElementLoading,
    },
    data() {
        return {
            pageLayoutHeight: 0,
            userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
            isLoading: false,
            locationSelDrpTXT: 'Choose Location',
            deviceSelDrpTXT: 'Choose Device',
            meterSelDrpTXT: 'Choose Meter',
            startDATE_RANGE_TXT: this.dateOnlyFRMT(myDate),
            endDATE_RANGE_TXT: this.dateOnlyFRMT(myDate),
            selDEVID: null,
            selDEVNAME: null,
            selLOCID: null,
            selLOCNAME: null,
            selMETID: null,
            selMETNAME: null,
            locDetailsArr: [],
            devDetailsArr: [],
            metDetailsArr: [],
            reportTblJson:[],
        };
    },
    props: {},
    async mounted() {
        this.isLoading = true;
        this.pageLayoutHeight = window.innerHeight;
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        await this.getLOCListDetails();
		await this.reportGENDetails();
        this.isLoading = false;
    },

    methods: {
        async getLOCListDetails() {
            let locationManagementListJSON = await commMethods.apiGETmethod(apiURL.locationManagementListURL, null, this.userSessionData);
            console.log('JSON :::locationManagementListJSON::: ' + JSON.stringify(locationManagementListJSON.data));
            if (locationManagementListJSON != undefined && locationManagementListJSON.data.status == "found") {
                this.locDetailsArr = locationManagementListJSON.data.details;
                this.locationSelDrpTXT = +this.locDetailsArr[0].id +'$$'+ this.locDetailsArr[0].loc_name;
                this.selLOCID = this.locDetailsArr[0].id;
                this.selLOCNAME = this.locDetailsArr[0].loc_name;
                await this.getDevListDetails(this.selLOCID);
            } else {
                this.locDetailsArr = [];
                this.locationSelDrpTXT = "Choose Location";
                this.selLOCID = null;
                this.selLOCNAME = null;
            }
        },
        async getDevListDetails(loc_id) {
            console.log('loc_id::: ' + loc_id);
            let dashboardLocDevMetListJSON = await commMethods.apiGETmethod(apiURL.deviceManagementListURL, null, this.userSessionData);
            console.log('JSON :::dashboardLocDevMetListJSON::: ' + JSON.stringify(dashboardLocDevMetListJSON.data));
            if (dashboardLocDevMetListJSON != undefined && dashboardLocDevMetListJSON.data.status == "found") {
                for (var i = 0; i < dashboardLocDevMetListJSON.data.details.length; i++) {
                    if (dashboardLocDevMetListJSON.data.details[i].loc_id == loc_id) {
                        this.devDetailsArr.push(dashboardLocDevMetListJSON.data.details[i]);
                        this.deviceSelDrpTXT = dashboardLocDevMetListJSON.data.details[0].id +'$$'+ dashboardLocDevMetListJSON.data.details[0].dev_name;
                        this.selDEVID = dashboardLocDevMetListJSON.data.details[0].id;
                        this.selDEVNAME = dashboardLocDevMetListJSON.data.details[0].dev_name;
						await this.getMetListDetails(this.selDEVID);
                    }
                }
            } else {
                this.devDetailsArr = [];
                this.deviceSelDrpTXT = "Choose Device";
                this.selDEVID = null;
                this.selDEVNAME = null;
            }
        },
        async getMetListDetails(dev_id) {
            console.log('dev_id::: ' + dev_id);
			console.log('DEtails::: ' + JSON.stringify(this.devDetailsArr));
			if(this.devDetailsArr.length != 0){
            for (var i = 0; i < this.devDetailsArr.length; i++) {
                if (this.devDetailsArr[i].id == dev_id) {
                    this.metDetailsArr = this.devDetailsArr[i].met_details;
                    this.meterSelDrpTXT = this.metDetailsArr[0].id +'$$'+ this.metDetailsArr[0].met_name;
                    this.selMETID = this.metDetailsArr[0].id;
                    this.selMETNAME = this.metDetailsArr[0].met_name;
					console.log(this.selMETID+':::::::MET:::------ ' + this.selMETNAME);
					// break;
                }
            }}else{
				this.metDetailsArr = [];
                this.meterSelDrpTXT = "Choose Meter";
                this.selMETID = null;
                this.selMETNAME = null;
			}
        },
        async reportGENDetails() {
            this.isLoading = true;
            if (this.locationSelDrpTXT == "Choose Location") {
                this.warningMessage('Please choose location');
                this.isLoading = false;
                return false;
            }
            if (this.deviceSelDrpTXT == "Choose Device") {
                this.warningMessage('Please choose device');
                this.isLoading = false;
                return false;
            }
            if (this.meterSelDrpTXT == "Choose Meter") {
                this.warningMessage('Please choose meter');
                this.isLoading = false;
                return false;
            }
            if (this.startDATE_RANGE_TXT == "") {
                this.warningMessage('Please choose start date');
                this.isLoading = false;
                return false;
            }
            if (this.endDATE_RANGE_TXT == "") {
                this.warningMessage('Please choose end date');
                this.isLoading = false;
                return false;
            }
            var reportJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "met_id": this.selMETID,
                "met_name": this.selMETNAME,
                "start_date": this.startDATE_RANGE_TXT, // 23 Jan 2023
                "end_date": this.endDATE_RANGE_TXT, // 24 Jan 2023
            };
            console.log(JSON.stringify(reportJson) + '---------CustReportDetails :::' + apiURL.custgenerateDetailsReportURL);
            let custgenerateDetailsReportJSON = await commMethods.apiPOSTmethod(apiURL.custgenerateDetailsReportURL, reportJson, this.userSessionData);
            console.log('JSON :::custgenerateDetailsReportJSON::: ' + JSON.stringify(custgenerateDetailsReportJSON.data));
            if (custgenerateDetailsReportJSON != undefined && custgenerateDetailsReportJSON.data.status == "found") {
                this.reportTblJson = custgenerateDetailsReportJSON.data.details;
            } else {
                this.reportTblJson = [];
            }
        },
        async downloadExport(exporttype) {
            this.isLoading = true;
            var reportJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
				"met_id": this.selMETID,
                "met_name": this.selMETNAME,
                "start_date": this.startDATE_RANGE_TXT, // 23 Jan 2023
                "end_date": this.endDATE_RANGE_TXT, // 24 Jan 2023
                "export_type": exporttype
            };
            console.log(JSON.stringify(reportJson) + '---------downloadExport :::' + apiURL.custExportReportURL);
            let custExportReportJSON = await commMethods.apiPOSTmethod(apiURL.custExportReportURL, reportJson, this.userSessionData);
            console.log('JSON :::custExportReportJSON::: ' + JSON.stringify(custExportReportJSON.data));
            if (custExportReportJSON != undefined && custExportReportJSON.data.status == "found") {
                await this.downloadFile(custExportReportJSON.data.file_path);
                this.successMessage(custExportReportJSON.data.file_name + " - Download Successfully..");
                this.isLoading = false;
            } else {
                this.warningMessage(custExportReportJSON.data.status);
                this.isLoading = false;
            }
        },
        downloadFile(fileName, filepath) {
            console.log(fileName + ": Download FILE PATH :" + filepath);
            const link1 = document.createElement("a");
            link1.href = filepath;
            link1.setAttribute("download", fileName);
            document.body.appendChild(link1);
            link1.click();
        },
        async changeLocationMthod(locationTXT) {
            console.log('---------locationTXT :::' + locationTXT);
            this.isLoading = true;
            this.selLOCID = locationTXT.split('$')[0];
            this.selLOCNAME = locationTXT.split('$')[1];
            await this.getDevListDetails(this.selLOCID);
            this.isLoading = false;
        },
        changeDeviceMthod(deviceTXT) {
            console.log('---------deviceTXT :::' + deviceTXT);
        },
        changeMeterMthod(meterTXT) {

        },
        notAfterToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        startPickerChange(value) {
            this.startDATE_RANGE_TXT = value;
            console.log("----STRAT------->" + value);
        },
        endPickerChange(value) {
            this.endDATE_RANGE_TXT = value;
            console.log("----END------->" + value);
        },
        dateOnlyFRMT(dateval) {
            var date = new Date(dateval);
            var month = date.getMonth();
            var day = date.getDate();
            var year = date.getFullYear();
            var hour = date.getHours();
            var min = date.getMinutes();
            var sec = date.getSeconds();
            var monthlimt = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var dateStr = day <= 9 ? "0" + day : day;
            return dateStr + " " + monthlimt[month] + " " + year;
        },
        successMessage(msg) {
            this.$notice.success({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
        infoMessage(msg) {
            this.$notice.info({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
        errorMessage(msg) {
            this.$notice.error({
                title: "Notification",
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                },
            });
        },
    },
    watch: {},
};
</script>

<style scoped>
.card-folders.card-body>.breadcrumb {
    margin-left: -1.25em;
    margin-right: -1.25em;
    margin-top: -1.25em;
    border-radius: 0;
}

.folder-container {
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    width: 100px;
    padding: 0;
    align-self: start;
    background: none;
    border: none;
    outline-color: transparent !important;
    cursor: pointer;
}

.folder-icon {
    font-size: 3em;
    line-height: 1.5em;
}

.folder-icon-color {
    color: #FFC107;
    text-shadow: 1px 1px 0px #FFC107;
}

.folder-name {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.flex-column.folder-container {
    display: flex;
    width: auto;
    min-width: 100px;
    text-align: left;
    margin: 0;
    margin-bottom: 1rem;
}

.flex-column.folder-icon .flex-column.folder-name {
    display: inline-flex;
}

.flex-column.folder-icon {
    font-size: 1.4em;
    margin-right: 1rem;
}

.file-icon-color {
    color: #999;
    text-shadow: 1px 1px 0px grey;
}

.mx-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 30px;
    padding: 0px 0px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.2;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.context-menu {
    position: absolute;
}

.menu {
    display: flex;
    flex-direction: column;
    background-color: #ddd;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(141, 131, 131, 0.05);
    padding: 3px 0;
}

.menu>span>a {
    font: inherit;
    border: 0;
    padding: 5px 20px 5px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
}

.menu>span>a:hover {
    background: #f1f3f7;
    color: #19a12fcc;
}

.menu>span>a>i {
    padding-right: 10px;
}

.menu>span.trash>a:hover {
    color: red;
}

table tr th,
table tr td {
    text-align: center;
    font-weight: bolder;
}
</style>
