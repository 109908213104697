const serverURL = "/"; // http://122.166.196.34:81/   <->  http://15.206.196.77:9780/

export default {
	serverURL:serverURL,
	loginURL: serverURL+'login',
	changepwdURL:serverURL+'changepwd',
	refreshTokenURL:serverURL+'api/auth/refreshtoken',
	checkforgetaddURL:serverURL+'check/forget',
	resetpasswordURL:serverURL+'user/reset/password',
	resetkeyValidityURL:serverURL+'user/reset/validity?key=$$key$$',
	supportMailURL:serverURL+'mail/support',
	// Dashboard Screen URL's
	dashboardTreeviewListURL:serverURL+'get/dashboard/tree/details',
	dashboardLocDevMetListURL:serverURL+'get/location/list',
	dashboardDayProfileURL:serverURL+'get/dailyprofile/chart',
	dashboardMonthProfileURL:serverURL+'get/monthprofile/chart',
	dashboardInstParamsURL:serverURL+'inst/params/details',
	dashboardModemParamsURL:serverURL+'modem/params/details',
	dashboardMeterEventsURL:serverURL+'meter/events/details',
	dashboardModemEventsURL:serverURL+'modem/events/details',
	alertsListURL:serverURL+'get/alerts/details',
	overallcommListURL:serverURL+'overall/comm/status',
	// Diagnostics Screen URL's
	diagnosticsTreeviewListURL:serverURL+'get/diagnostics/tree/details',
	diagnosticsSignalDayProfileURL:serverURL+'diagnostics/signal/dailyprofile/chart',
	diagnosticsSignalMonthProfileURL:serverURL+'diagnostics/signal/monthprofile/chart',
	diagnosticsConnMonthProfileURL:serverURL+'diagnostics/convpercent/monthprofile/chart',
	diagnosticsDeviceStatusURL:serverURL+'diagnostics/device/status',
	diagnosticsDeviceEventsURL:serverURL+'diagnostics/device/events/details',
	//GET Serial Details URL
	serialnumListURL:serverURL+'get/ser_num/details',
	//User Management URL's
	userManagementListURL:serverURL+'get/user/list',
	userManagementADDURL:serverURL+'add/user',
	userManagementUPDATEURL:serverURL+'update/user',
	userManagementDELETEURL:serverURL+'delete/user?id=$$id$$',
	//SMS Management URL's
	smsManagementListURL:serverURL+'get/email_sms/list',
	smsManagementADDURL:serverURL+'add/email_sms',
	smsManagementUPDATEURL:serverURL+'update/email_sms',
	smsManagementDELETEURL:serverURL+'delete/email_sms?id=$$id$$',
	//Notfi Management URL's
	notfiManagementListURL:serverURL+'get/notifyconfig/list',
	notfiManagementADDURL:serverURL+'add/notifyconfig',
	notfiManagementUPDATEURL:serverURL+'update/notifyconfig',
	notfiManagementDELETEURL:serverURL+'delete/notifyconfig?id=$$id$$',
	//Location Management URL's
	locationManagementListURL:serverURL+'get/locationconfig/list',
	locationManagementADDURL:serverURL+'add/locationconfig',
	locationManagementUPDATEURL:serverURL+'update/locationconfig',
	locationManagementDELETEURL:serverURL+'delete/locationconfig?id=$$id$$',
	//Device Serial Management URL's
	devSerialManagementListURL:serverURL+'get/gateway/sernum',
	devSerialManagementADDURL:serverURL+'add/gateway/sernum?ser_num=$$ser_num$$&dev_type=$$dev_type$$&dev_model=$$dev_model$$',
	devSerialManagementDELETEURL:serverURL+'delete/gateway/sernum?ser_num=$$ser_num$$',
	//Meter Serial Management URL's
	metSerialManagementListURL:serverURL+'get/slave/sernum',
	metSerialManagementADDURL:serverURL+'add/slave/sernum?ser_num=$$ser_num$$',
	metSerialManagementDELETEURL:serverURL+'delete/slave/sernum?ser_num=$$ser_num$$',
	//Obis Code Management URL's
	obisCodeManagementListURL:serverURL+'get/obiscode/details',
	obisCodeManagementADDURL:serverURL+'add/obiscode',
	obisCodeManagementUPDATEURL:serverURL+'update/obiscode',
	obisCodeManagementDELETEURL:serverURL+'delete/obiscode?code=$$code$$',
	//Device Management URL's
	deviceManagementListURL:serverURL+'get/deviceconfig/list',
	deviceManagementADDURL:serverURL+'add/deviceconfig',
	deviceManagementUPDATEURL:serverURL+'update/deviceconfig',
	deviceManagementDELETEURL:serverURL+'delete/deviceconfig?id=$$id$$',
	devicecommunicationListURL:serverURL+'get/device/communication/list',

	

	//Meter Management URL's
	meterManagementListURL:serverURL+'get/meterconfig/list?dev_id=$$dev_id$$',
	meterManagementADDURL:serverURL+'add/meterconfig',
	meterManagementUPDATEURL:serverURL+'update/meterconfig',
	meterManagementDELETEURL:serverURL+'delete/meterconfig?id=$$id$$&dev_id=$$dev_id$$',
	//Config / Firmware Upload URL's
	downloadconfigURL:serverURL+'download/config',
	uploadconfigURL:serverURL+'upload/config',
	uploadfirmwareURL:serverURL+'upload/firmware',
	resetURL:serverURL+'reset',
	timesyncURL:serverURL+'time/synch',
	confgStatusURL:serverURL+'config/status',
	//Automatic Report URL's
	autogenerateDetailsReportURL:serverURL+'autogenerate/report/details',
	individualDownloadReportURL:serverURL+'individual/report/download',
	autogenerateDownloadAllReportURL:serverURL+'autogenerate/report/downloadall',
	viewgenerateDetailsReportURL:serverURL+'view/report/details',
	//Customize Report URL's
	custgenerateDetailsReportURL:serverURL+'cust/report/details',
	custExportReportURL:serverURL+'cust/export/report',
	//Audit Report URL's
	auditgenerateDetailsReportURL:serverURL+'audit/report/details',
	auditExportReportURL:serverURL+'audit/export/report',
	//view events URL's
	viewEventsURL:serverURL+'view/events/details',
	// CMD List URL's
	cmd_listURL:serverURL+'command/command_list',
	cmd_createURL:serverURL+'command/create',
	getcommand_resURL:serverURL+'command/response',

	// session Management URL's
	connectionAliveURL: serverURL + 'ping/user',
	kickoutURL: serverURL + 'kickout/user',
	logoutURL: serverURL + 'logout'
};