<template>
    <div class="pcoded-main-container">
        <vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
        <div class="pcoded-main-container">
            <div class="pcoded-content">
                <!-- [ breadcrumb ] end -->
                <!-- [ Main Content ] start -->
                <div class="row">
                    <!-- [ sample-page ] start -->
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header" style="padding: 10px 0px 0px 10px;">
                                <div class="row">
                                    <div class="col-lg-1">
                                        <h5 style="margin-top: 10px;">View Events</h5>
                                    </div>
                                    <div class="col-lg-11">
                                        <div class="col-lg-12">
                                            <label><b>Choose Type :</b> &nbsp;</label>
                                            <select id="meterevTypeSelDrpTXT" v-model="meterevTypeSelDrpTXT" class="mx-input" style="width: 12%;" @change="changeMeterMthod(meterSelDrpTXT)">
                                                <option selected value="Gateway Events">Gateway Events</option>
                                                <option value="Meter Events">Meter Events</option>
                                            </select> &nbsp;
                                            <label><b>Choose Location :</b> &nbsp;</label>
                                            <select id="locationSelDrpTXT" v-model="locationSelDrpTXT" class="mx-input" style="width: 12%;" @change="changeLocationMthod(locationSelDrpTXT)">
                                                <option value="Choose Location" selected disabled>Choose Location</option>
                                                <option v-for="(item,index) in locDetailsArr" :key="index" :value="item.id+'$'+item.loc_name">{{item.loc_name}}</option>
                                            </select>&nbsp;&nbsp;
                                            <label><b>Choose Gateway :</b> &nbsp;</label>
                                            <select id="deviceSelDrpTXT" v-model="deviceSelDrpTXT" class="mx-input" style="width: 12%;" @change="changeDeviceMthod(deviceSelDrpTXT)">
                                                <option selected disabled value="Choose Gateway">Choose Gateway</option>
                                                <option v-for="(item,index) in devDetailsArr" :key="index" :value="item.id+'$'+item.dev_name">{{item.dev_name}}</option>
                                            </select> &nbsp; &nbsp;
                                            <label v-show="meterevTypeSelDrpTXT == 'Meter Events'"><b>Choose Meter :</b> &nbsp;</label>
                                            <select id="meterSelDrpTXT" v-show="meterevTypeSelDrpTXT == 'Meter Events'" v-model="meterSelDrpTXT" class="mx-input" style="width: 12%;" @change="changeMeterMthod(meterSelDrpTXT)">
                                                <option selected disabled value="Choose Meter">Choose Meter</option>
                                                <option v-for="(item,index) in metDetailsArr" :key="index" :value="item.id+'$'+item.met_name">{{item.met_name}}</option>
                                            </select>     &nbsp; &nbsp; 
                                            <label v-show="meterevTypeSelDrpTXT == 'Meter Events'"><b>Meter Type :</b> &nbsp;&nbsp;&nbsp;</label>
                                            <select id="meterTypeDrpTXT" v-show="meterevTypeSelDrpTXT == 'Meter Events'" v-model="meterTypeDrpTXT" class="mx-input" style="width: 12%;">
                                                <option selected disabled value="Choose Meter Type">Meter Type</option>
                                                <option value="All">All</option>
                                                <option value="Voltage Related Events">Voltage Related Events</option>
                                                <option value="Current Related Events">Current Related Events</option>
                                                <option value="Power Related Events">Power Related Events</option>
                                                <option value="Transaction Related Events">Transaction Related Events</option>
                                                <option value="Other Events">Other Events</option>
                                            </select>                                       
                                        <div v-show="false">
                                             &nbsp; &nbsp;
                                            <input type="radio" id="dailyRadionTXT" v-model="btnRadionTXT" name="rangeButton" value="Daily">
                                            &nbsp;
                                            <label for="dailyRadionTXT"><b>Daily</b></label>&nbsp;&nbsp;
                                            <input type="radio" id="monthlyRadionTXT" v-model="btnRadionTXT" name="rangeButton" value="Monthly">
                                            &nbsp;
                                            <label for="monthlyRadionTXT"><b>Monthly</b></label>&nbsp;
                                            <input type="radio" id="rangeRadionTXT" v-model="btnRadionTXT" name="rangeButton" value="Date Range">
                                            &nbsp;
                                            <label for="rangeRadionTXT"><b>Date Range</b></label>
                                        </div>
                                        </div>
                                        <div style="height: 5px;"></div>
                                        <div class="col-lg-12" style="display: flex;" >
                                         
                                        </div>
                                        <div class="col-lg-12" style="display: flex;" v-show="btnRadionTXT == 'Date Range'">
                                            <label><b>Start Date :</b> &nbsp;</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <date-picker v-model="startDATE_RANGE_TXT" format="DD MMM YYYY" value-type="format" style="width: 12%;" placeholder="Start Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="startDATE_RANGE_PickerChange" valueType="format"></date-picker>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <label><b>End Date : </b>&nbsp;</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <date-picker v-model="endDATE_RANGE_TXT" format="DD MMM YYYY" value-type="format" style="width: 12%;" placeholder="End Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="endDATE_RANGE_PickerChange" valueType="format"></date-picker>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-outline-info has-ripple" style="margin-top: -2px;" @click="automaticReportDetails()"><i class="mr-2 fa fa-check-circle"></i>Submit</button>
                                        </div>
                                        <div class="col-lg-12" style="display: flex;" v-show="btnRadionTXT == 'Monthly'">
                                            <label><b>Choose Month :</b> &nbsp;</label>&nbsp;&nbsp;&nbsp;
                                            <date-picker v-model="startMONTHLY_RANGE_TXT" format="MMM YYYY" style="width: 20.1%;" placeholder="Select Month" type="month" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="startMONTHLY_RANGE_PickerChange" valueType="format"></date-picker>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-outline-info has-ripple" style="margin-top: -2px;" @click="automaticReportDetails()"><i class="mr-2 fa fa-check-circle"></i>Submit</button>
                                        </div>
                                        <div class="col-lg-12" style="display: flex;" v-show="btnRadionTXT == 'Daily'">
                                            <label><b>Choose Date :</b> &nbsp;</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <date-picker v-model="startDAILY_RANGE_TXT" format="DD MMM YYYY" value-type="format" style="width: 20.1%;" placeholder="Select Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="startDAILY_RANGE_PickerChange" valueType="format"></date-picker>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm btn-outline-info has-ripple" style="margin-top: -2px;" @click="automaticReportDetails()"><i class="mr-2 fa fa-check-circle"></i>Submit</button>
                                        </div>
                                    </div>
                                </div>
                                <div style="height: 10px;"></div>
                            </div>
                            <div class="card-body">                                
                                <div class="table-responsive" v-bind:style="{'height':(pageLayoutHeight-290)+'px'}" style="border:1px solid lightgray">
                                        <table class="table table-striped table-bordered">
                                            <thead style="position: sticky;top: -1px;z-index:1;">
                                                <tr>
                                                    <th class="text-left" style="width: 50px;">#</th>
                                                    <th class="text-left" style="width: 250px;">Date Time</th>  
                                                    <th class="text-left">Event Type</th>                                                  
                                                    <th class="text-left">Events</th>
                                                    <th class="text-left" v-if="submitMterevTypeSelDrpTXT == 'Meter Events'">Details</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(tblitems,index) in viewEventTblJson" v-bind:key="index">
                                                <tr>
                                                    <td class="text-left">{{ index+1 }}</td>
                                                    <td class="text-left">{{tblitems.date_time}}</td>
                                                    <td class="text-left">{{ tblitems.event_type }}</td>
                                                    <td class="text-left">{{tblitems.events}}</td>
                                                    <td class="text-left" v-if="submitMterevTypeSelDrpTXT == 'Meter Events'">{{tblitems.details}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div v-if="viewEventTblJson.length == 0" style="text-align:center;width: 100%;">
                                            <span>No Events Found</span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <!-- [ sample-page ] end -->
                </div>
                <!-- [ Main Content ] end -->
            </div>
           
        </div>
    
        <!--** Model Popup ** -->
    
    </div>
    </template>
    
    <script>
    import DatePicker from "vue2-datepicker";
    import "vue2-datepicker/index.css";
    import VueElementLoading from "vue-element-loading";
    import apiURL from "@/API_Config";
    import commMethods from "@/api/common_methods.js"
    var myDate = new Date();
    export default {
        name: "AutomaticGenerateReport",
        components: {
            DatePicker,
            VueElementLoading,
        },
        data() {
            return {
                pageLayoutHeight: 0,
                userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
                isLoading: false,
                locationSelDrpTXT: "Choose Location",
                deviceSelDrpTXT: "Choose Gateway",
                meterSelDrpTXT: "Choose Meter",
                meterevTypeSelDrpTXT:'Gateway Events',
                metDetailsArr: [],
                btnRadionTXT: "Date Range",
                startDATE_RANGE_TXT: this.dateOnlyFRMT(myDate),
                endDATE_RANGE_TXT: this.dateOnlyFRMT(myDate),
                startMONTHLY_RANGE_TXT: this.dateMonthFRMT(myDate),
                startDAILY_RANGE_TXT: this.dateOnlyFRMT(myDate),
                selDEVID: null,
                selDEVNAME: null,
                selLOCID: null,
                selLOCNAME: null,
                selMETID: null,
                selMETNAME: null,
                locDetailsArr: [],
                devDetailsArr: [],
                viewEventTblJson:[],
                infvFileobj: null,
                reportTblJson: null,
                viewTbleType:null,
                viewTbleName:null,
                meterTypeDrpTXT:'All',
                submitMterevTypeSelDrpTXT:null,
            };
        },
        props: {},
        async mounted() {
            this.isLoading = true;
            this.pageLayoutHeight = window.innerHeight;
            this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
            $(document).ready(function () {
                // Grid or list selection
                $("#btn-list").on("click", function () {
                    $("#main-folders").addClass("flex-column");
                    $("#btn-grid").removeClass("active");
                    $(this).addClass("active");
                });
                $("#btn-grid").on("click", function () {
                    $("#main-folders").removeClass("flex-column");
                    $("#btn-list").removeClass("active");
                    $(this).addClass("active");
                });
                $("#btn-list").on("click", function () {
                    $("#main-files").addClass("flex-column");
                    $("#btn-grid").removeClass("active");
                    $(this).addClass("active");
                });
                $("#btn-grid").on("click", function () {
                    $("#main-files").removeClass("flex-column");
                    $("#btn-list").removeClass("active");
                    $(this).addClass("active");
                });
    
                // Open folder and see files
                //   $("button.folder-container").on("click", function() {
                //     $("#filesGroup").removeClass("d-none");
                //     $("#foldersGroup").addClass("d-none");
                //   });
                //   $("a#backToFolders").on("click", function() {
                //     $("#foldersGroup").removeClass("d-none");
                //     $("#filesGroup").addClass("d-none");
                //   });
            });
            await this.getLOCListDetails();
            await this.automaticReportDetails();
            this.isLoading = false;
        },
    
        methods: {
            async getLOCListDetails() {
                let locationManagementListJSON = await commMethods.apiGETmethod(apiURL.locationManagementListURL, null, this.userSessionData);
                console.log('JSON :::getLOCListDetails::: ' + JSON.stringify(locationManagementListJSON.data));
                if (locationManagementListJSON != undefined && locationManagementListJSON.data.status == "found") {
                    this.locDetailsArr = locationManagementListJSON.data.details;
                    this.locationSelDrpTXT = +this.locDetailsArr[0].id + '$' + this.locDetailsArr[0].loc_name;
                    this.selLOCID = this.locDetailsArr[0].id;
                    this.selLOCNAME = this.locDetailsArr[0].loc_name;
                    await this.getDevListDetails(this.selLOCID);
                } else {
                    this.locDetailsArr = [];
                    this.locationSelDrpTXT = "Choose Location";
                    this.selLOCID = null;
                    this.selLOCNAME = null;
                }
            },
            async getDevListDetails(loc_id) {
                this.devDetailsArr = [];
                console.log('loc_id::: ' + loc_id);
                let dashboardLocDevMetListJSON = await commMethods.apiGETmethod(apiURL.deviceManagementListURL, null, this.userSessionData);
                console.log('JSON :::getDevListDetails::: ' + JSON.stringify(dashboardLocDevMetListJSON.data));
                if (dashboardLocDevMetListJSON != undefined && dashboardLocDevMetListJSON.data.status == "found") {
                    for (var i = 0; i < dashboardLocDevMetListJSON.data.details.length; i++) {
                        console.log('de::: ' +  JSON.stringify(dashboardLocDevMetListJSON.data.details[i]));
                        if (dashboardLocDevMetListJSON.data.details[i].loc_id == loc_id) {
                            this.devDetailsArr.push(dashboardLocDevMetListJSON.data.details[i]);
                            this.deviceSelDrpTXT = this.devDetailsArr[0].id + '$' + this.devDetailsArr[0].dev_name;
                            this.selDEVID = this.devDetailsArr[0].id;
                            this.selDEVNAME = this.devDetailsArr[0].dev_name;
                            await this.getMetListDetails(this.selDEVID);
                        }
                    }
                    // if(this.devDetailsArr.length != 0){
                    // 	this.devDetailsArr = [];
                    // 	this.deviceSelDrpTXT = "Choose Gateway";
                    // 	this.selDEVID = null;
                    // 	this.selDEVNAME = null;
                    // 	this.metDetailsArr = [];
                    // 	this.meterSelDrpTXT = "Choose Meter";
                    // 	this.selMETID = null;
                    // 	this.selMETNAME = null;
                    // }
                } else {
                    this.devDetailsArr = [];
                    this.deviceSelDrpTXT = "Choose Gateway";
                    this.selDEVID = null;
                    this.selDEVNAME = null;
                }
            },
            async getMetListDetails(dev_id) {
                console.log('dev_id::: ' + dev_id);
                console.log('DEtails::: ' + JSON.stringify(this.devDetailsArr));
                if (this.devDetailsArr.length != 0) {
                    for (var i = 0; i < this.devDetailsArr.length; i++) {
                        if (this.devDetailsArr[i].id == dev_id) {
                            this.metDetailsArr = this.devDetailsArr[i].met_details;
                            if(this.metDetailsArr.length != 0){
                            this.meterSelDrpTXT = this.metDetailsArr[0].id + '$' + this.metDetailsArr[0].met_name;
                            this.selMETID = this.metDetailsArr[0].id;
                            this.selMETNAME = this.metDetailsArr[0].met_name;
                            console.log(this.selMETID + ':::::::MET:::------ ' + this.selMETNAME);
                            } else {
                            this.metDetailsArr = [];
                            this.meterSelDrpTXT = "Choose Meter";
                            this.selMETID = null;
                            this.selMETNAME = null;
                            }
                        }
                    }
                } else {
                    this.metDetailsArr = [];
                    this.meterSelDrpTXT = "Choose Meter";
                    this.selMETID = null;
                    this.selMETNAME = null;
                }
            },
            beforeOpen() {},
            beforeClose() {},
            opened(e) {},
            closed(e) {},
            async automaticReportDetails() {
                console.log("automaticReportDetails :::calll ::: ");
                this.isLoading = true;
                if (this.locationSelDrpTXT == "Choose Location") {
                    this.warningMessage("Please choose location");
                    this.isLoading = false;
                    return false;
                }
                if (this.deviceSelDrpTXT == "Choose Gateway") {
                    this.warningMessage("Please choose gateway");
                    this.isLoading = false;
                    return false;
                }
                console.log("2222 :::calll ::: ");
                if (this.meterSelDrpTXT == "Choose Meter" && this.meterevTypeSelDrpTXT == "Meter Events") {
                    this.warningMessage("Please choose meter");
                    this.isLoading = false;
                    return false;
                }
                if (this.btnRadionTXT == "Daily") {
                    if (this.startDAILY_RANGE_TXT == "") {
                        this.warningMessage("Please choose date");
                        this.isLoading = false;
                        return false;
                    }
                } else if (this.btnRadionTXT == "Monthly") {
                    if (this.startMONTHLY_RANGE_TXT == "") {
                        this.warningMessage("Please choose month");
                        this.isLoading = false;
                        return false;
                    }
                } else if (this.btnRadionTXT == "Date Range") {
                    if (this.startDATE_RANGE_TXT == "") {
                        this.warningMessage("Please choose start date");
                        this.isLoading = false;
                        return false;
                    }
                    if (this.endDATE_RANGE_TXT == "") {
                        this.warningMessage("Please choose end date");
                        this.isLoading = false;
                        return false;
                    }
                    var eDate = new Date(this.endDATE_RANGE_TXT);
                    var sDate = new Date(this.startDATE_RANGE_TXT);
                    if (this.startDATE_RANGE_TXT != '' && this.endDATE_RANGE_TXT != '' && sDate > eDate) {
                        this.warningMessage("Please ensure that the End Date is greater than or equal to the Start Date.");
                        this.isLoading = false;
                        return false;
                    }
                    const diffTime = Math.abs(eDate - sDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    console.log(diffDays + " days");
                    if (diffDays > 90) {
                        this.warningMessage("Selected date range should not exceed 90 days!");
                        this.isLoading = false;
                        return false;
                    }
                }
                this.submitMterevTypeSelDrpTXT = this.meterevTypeSelDrpTXT;
                var reportJson = {
                    type:this.meterevTypeSelDrpTXT,
                    loc_id: this.selLOCID,
                    loc_name: this.selLOCNAME,
                    dev_id: this.selDEVID,
                    dev_name: this.selDEVNAME,
                    met_id: this.selMETID,
                    met_name: this.selMETNAME,
                    option: this.btnRadionTXT, // Daily , Monthly, Date Range
                    met_type:this.meterTypeDrpTXT,
                    // daily_date: this.startDAILY_RANGE_TXT, // 23 Jan 2023
                    // month_date: this.startMONTHLY_RANGE_TXT, // Jan 2023
                    range_start_date: this.startDATE_RANGE_TXT, // 23 Jan 2023
                    range_end_date: this.endDATE_RANGE_TXT, // 24 Jan 2023
                };
                console.log(JSON.stringify(reportJson) + "---------automaticReportDetails :::" + apiURL.viewEventsURL);
                let viewEventsReportJSON = await commMethods.apiPOSTmethod(apiURL.viewEventsURL, reportJson, this.userSessionData);
                console.log("JSON :::viewEventsReportJSON::: " + JSON.stringify(viewEventsReportJSON.data));
                if (viewEventsReportJSON != undefined && viewEventsReportJSON.data.status == "found") {
                    this.viewEventTblJson = viewEventsReportJSON.data.details;
                    this.isLoading = false;
                } else {
                    this.warningMessage(viewEventsReportJSON.data.status);
                    this.viewEventTblJson = [];
                    this.isLoading = false;
                }
            },
            async changeLocationMthod(locationTXT) {
                this.isLoading = true;
                console.log("---------locationTXT :::" + locationTXT);
                this.selLOCID = locationTXT.split('$')[0];
                this.selLOCNAME = locationTXT.split('$')[1];
                await this.getDevListDetails(this.selLOCID);
                this.isLoading = false;
            },
            async changeDeviceMthod(deviceTXT) {
                this.isLoading = true;
                this.selDEVID = deviceTXT.split('$')[0];
                this.selDEVNAME = deviceTXT.split('$')[1];
                await this.getMetListDetails(this.selDEVID);
                this.isLoading = false;
                console.log("---------deviceTXT :::" + deviceTXT);
            },
            changeMeterMthod(meterTXT) {
                console.log("---------meterTXT :::" + meterTXT);
                this.selMETID = meterTXT.split('$')[0];
                this.selMETNAME = meterTXT.split('$')[1];
            },
            notAfterToday(date) {
                return date > new Date(new Date().setHours(0, 0, 0, 0));
            },
            startDATE_RANGE_PickerChange(value) {
                this.startDATE_RANGE_TXT = value;
            },
            endDATE_RANGE_PickerChange(value) {
                this.endDATE_RANGE_TXT = value;
            },
            startMONTHLY_RANGE_PickerChange(value) {
                this.startMONTHLY_RANGE_TXT = value;
            },
            startDAILY_RANGE_PickerChange(value) {
                this.startDAILY_RANGE_TXT = value;
            },
            async viewTablePopup() {
                console.log('---------call view popup :::');
                //this.isLoading = true;
                this.viewTbleType = this.infvFileobj.model;
                this.viewTbleName =  this.infvFileobj.file_name;
                var reportJson = {
                    "file_details": this.infvFileobj
                };
                console.log(JSON.stringify(reportJson) + '---------viewgenerateDetailsReportURL :::' + apiURL.viewgenerateDetailsReportURL);
                let viewgenerateDetailsReporJSON = await commMethods.apiPOSTmethod(apiURL.viewgenerateDetailsReportURL, reportJson, this.userSessionData);
                console.log('JSON :::viewgenerateDetailsReporJSON::: ' + JSON.stringify(viewgenerateDetailsReporJSON.data));
                if (viewgenerateDetailsReporJSON != undefined && viewgenerateDetailsReporJSON.data.status == "found") {
                    this.reportTblJson = viewgenerateDetailsReporJSON.data.details;
                    await this.showviewTablePopup();
                    this.isLoading = false;
                } else {
                    this.reportTblJson = null;
                    this.warningMessage('Report Content is empty');
                    this.isLoading = false;
                }
            },
            showviewTablePopup() {
                this.$modal.show("viewTablePopup");
            },
            closeviewTablePopup() {
                this.$modal.hide("viewTablePopup");
            },
            async downloadinvifile() {
                if (this.viewEventTblJson != null) {
                    this.isLoading = true;
                    console.log('JSON :::this.infvFileobj.file_path::: ' + apiURL.serverURL + this.infvFileobj.file_path);
                    await this.downloadFile(this.infvFileobj.file_name, apiURL.serverURL + this.infvFileobj.file_path);
                    this.isLoading = false;
                }
            },
            async downloadAllFunction() {
                this.isLoading = true;
                if (this.viewEventTblJson != null) {
                    var reportJson = {
                        type:this.meterevTypeSelDrpTXT,
                        loc_id: this.selLOCID,
                        loc_name: this.selLOCNAME,
                        dev_id: this.selDEVID,
                        dev_name: this.selDEVNAME,
                        met_id: this.selMETID,
                        met_name: this.selMETNAME,
                        option: this.btnRadionTXT, // Daily , Monthly, Date Range
                        met_type:this.meterTypeDrpTXT,
                        // daily_date: this.startDAILY_RANGE_TXT, // 23 Jan 2023
                        // month_date: this.startMONTHLY_RANGE_TXT, // Jan 2023
                        range_start_date: this.startDATE_RANGE_TXT, // 23 Jan 2023
                        range_end_date: this.endDATE_RANGE_TXT, // 24 Jan 2023
                    };
                    console.log(JSON.stringify(reportJson) + "---------downloadAllFunction :::" + apiURL.autogenerateDownloadAllReportURL);
                    let autogenerateDownloadAllReportJSON = await commMethods.apiPOSTmethod(apiURL.autogenerateDownloadAllReportURL, reportJson, this.userSessionData);
                    console.log("JSON :::autogenerateDownloadAllReportJSON::: " + JSON.stringify(autogenerateDownloadAllReportJSON.data));
                    if (autogenerateDownloadAllReportJSON != undefined && autogenerateDownloadAllReportJSON.data.status == "found") {
                        await this.downloadFile(autogenerateDownloadAllReportJSON.data.file_name, apiURL.serverURL + autogenerateDownloadAllReportJSON.data.file_path);
                        this.successMessage("Download Successfully..");
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                    }
                }
            },
            dateOnlyFRMT(dateval) {
                var date = new Date(dateval);
                var month = date.getMonth();
                var day = date.getDate();
                var year = date.getFullYear();
                var hour = date.getHours();
                var min = date.getMinutes();
                var sec = date.getSeconds();
                var monthlimt = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var dateStr = day <= 9 ? "0" + day : day;
                return dateStr + " " + monthlimt[month] + " " + year;
            },
            dateMonthFRMT(dateval) {
                var date = new Date(dateval);
                var month = date.getMonth();
                var day = date.getDate();
                var year = date.getFullYear();
                var hour = date.getHours();
                var min = date.getMinutes();
                var sec = date.getSeconds();
                var monthlimt = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var dateStr = day <= 9 ? "0" + day : day;
                return monthlimt[month] + " " + year;
            },
            downloadFile(fileName, filepath) {
                console.log(fileName + "Download FILE PATH :" + filepath);
                const link1 = document.createElement("a");
                link1.href = filepath;
                link1.setAttribute("download", fileName);
                document.body.appendChild(link1);
                link1.click();
            },
            successMessage(msg) {
                this.$notice.success({
                    title: "Notification",
                    description: msg,
                    duration: 3,
                    onClose() {
                        // tslint:disable-next-line:no-console
                    },
                });
            },
            warningMessage(msg) {
                this.$notice.warning({
                    title: "Notification",
                    description: msg,
                    duration: 3,
                    onClose() {
                        // tslint:disable-next-line:no-console
                    },
                });
            },
            infoMessage(msg) {
                this.$notice.info({
                    title: "Notification",
                    description: msg,
                    duration: 3,
                    onClose() {
                        // tslint:disable-next-line:no-console
                    },
                });
            },
            errorMessage(msg) {
                this.$notice.error({
                    title: "Notification",
                    description: msg,
                    duration: 3,
                    onClose() {
                        // tslint:disable-next-line:no-console
                    },
                });
            },
        },
        watch: {},
    };
    </script>
    
    <style scoped>
    .card-folders.card-body>.breadcrumb {
        margin-left: -1.25em;
        margin-right: -1.25em;
        margin-top: -1.25em;
        border-radius: 0;
    }
    
    .folder-container {
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 1.5rem;
        width: 120px;
        padding: 0;
        align-self: start;
        background: none;
        border: none;
        outline-color: transparent !important;
        cursor: pointer;
    }
    
    .folder-icon {
        font-size: 2.5em;
        line-height: 1.5em;
    }
    
    .folder-icon-color {
        color: #0791e0;
        text-shadow: 1px 1px 0px #07ff30;
    }
    
    .folder-name {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
    
    .flex-column.folder-container {
        display: flex;
        width: auto;
        min-width: 100px;
        text-align: left;
        margin: 0;
        margin-bottom: 1rem;
    }
    
    .flex-column.folder-icon .flex-column.folder-name {
        display: inline-flex;
    }
    
    .flex-column.folder-icon {
        font-size: 1.4em;
        margin-right: 1rem;
    }
    
    .file-icon-color {
        color: #999;
        text-shadow: 1px 1px 0px grey;
    }
    
    .mx-input {
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 30px;
        padding: 0px 0px;
        padding-left: 10px;
        font-size: 14px;
        line-height: 1.2;
        color: #555;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 0px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
    
    .context-menu {
        position: absolute;
    }
    
    .menu {
        display: flex;
        flex-direction: column;
        background-color: #ddd;
        border-radius: 5px;
        box-shadow: 0 10px 10px rgba(141, 131, 131, 0.05);
        padding: 3px 0;
    }
    
    .menu>span>a {
        font: inherit;
        border: 0;
        padding: 5px 20px 5px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        text-decoration: unset;
        color: #000;
        font-weight: 500;
        transition: 0.5s linear;
        -webkit-transition: 0.5s linear;
        -moz-transition: 0.5s linear;
        -ms-transition: 0.5s linear;
        -o-transition: 0.5s linear;
    }
    
    .menu>span>a:hover {
        background: #f1f3f7;
        color: #19a12fcc;
    }
    
    .menu>span>a>i {
        padding-right: 10px;
    }
    
    .menu>span.trash>a:hover {
        color: red;
    }
    </style>
    