<template>
<div class="pcoded-main-container">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="pcoded-main-container">
        <div class="pcoded-content">
            <!-- [ breadcrumb ] end -->
            <!-- [ Main Content ] start -->
            <div class="row">
                <!-- [ sample-page ] start -->
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header" style="padding: 0px 0px 7px 10px;">
                            <div class="row">
                                <div class="col-lg-5">
                                    <h5 style="margin-top: 10px;"> On Demand Commands</h5>
                                </div>
                                <div class="col-lg-5">
                                </div>
                                <div class="col-lg-2 text-right">
                                    <button id="cmdmangementaddBTN"  class="btn btn-sm btn-outline-primary" style="margin-top: 5px;" title="Issue Command" @click="cmdmangementAddFunction()">
                                        <i class="fa fa-plus"></i> Issue Command
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
							<div class="row">
								<div class="col-lg-12">
                                    <div class="col-lg-12">
                                        <label><b>Choose Location :</b> &nbsp;</label>
                                        <select id="locationSelDrpTXT" v-model="locationSelDrpTXT" class="mx-input" style="width: 10%;" @change="changeLocationMthod(locationSelDrpTXT)">
                                            <option value="Choose Location" selected disabled>Choose Location</option>
                                            <option v-for="(item,index) in locDetailsArr" :key="index" :value="item.id+'$$'+item.loc_name">{{item.loc_name}}</option>
                                        </select>&nbsp;&nbsp;
                                        <label><b>Choose Gateway :</b> &nbsp;</label>
                                        <select id="deviceSelDrpTXT" v-model="deviceSelDrpTXT" class="mx-input" style="width: 10%;" @change="changeDeviceMthod(deviceSelDrpTXT)">
                                            <option selected disabled value="Choose Gateway">Choose Gateway</option>
                                            <option v-for="(item,index) in devDetailsArr" :key="index" :value="item.id+'$$'+item.dev_name">{{item.dev_name}}</option>
                                        </select> &nbsp; &nbsp;
                                        <label><b>Choose Meter :</b> &nbsp;</label>
                                        <select id="meterSelDrpTXT" v-model="meterSelDrpTXT" class="mx-input" style="width: 10%;" @change="changeMeterMthod(meterSelDrpTXT)">
                                            <option selected disabled value="Choose Meter">Choose Meter</option>
                                            <option v-for="(item,index) in metDetailsArr" :key="index" :value="item.id+'$$'+item.met_name">{{item.met_name}}</option>
                                        </select> &nbsp; &nbsp;
										<label><b>Start Date :</b> &nbsp;</label>
                                        <date-picker v-model="startDATE_RANGE_TXT" format="DD MMM YYYY" value-type="format" style="width: 10.2%;" placeholder="Start Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="startDATE_RANGE_PickerChange" valueType="format"></date-picker>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <label><b>End Date : </b>&nbsp;</label>
                                        <date-picker v-model="endDATE_RANGE_TXT" format="DD MMM YYYY" value-type="format" style="width: 10.2%;" placeholder="End Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="endDATE_RANGE_PickerChange" valueType="format"></date-picker>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <button type="button" class="btn btn-sm btn-outline-info has-ripple" style="margin-top: -2px;" @click="automaticReportDetails()"><i class="mr-2 fa fa-check-circle"></i>Submit</button>
                                    </div>
                                </div>
							</div>
                            <template>
                                <div class="table-responsive" id="cmdmangementTablePANE" style="border:1px solid lightgray;" v-bind:style="{height:(pageLayoutHeight-280)+'px'}">
                                    <table data-v-f3f3eg9 class="table table-bordered" id="cmdmangementTablePANEID">
                                        <thead class style="position: sticky;top: -1px;z-index:1;">
                                            <tr>
                                                <th class="text-left" style="width:50px">#</th>
                                                <th class="text-left">Command Name</th>
                                                <th class="text-left">Loc Name</th>
                                                <th class="text-left">Gateway Name</th>
                                                <th class="text-left">Meter Name</th>
                                                <th class="text-left">Issued Time</th>
												<th class="text-left">End Time</th>
												<th class="text-left">Command Exec Time (in sec)</th>
												<th class="text-left">Command Status</th>
												<!-- <th class="text-left" v-show="false">View Response</th> -->
                                            </tr>
                                        </thead>
                                        <tbody v-if="rmsondemandConfigJson != null">
                                            <tr v-for="(row,index) in rmsondemandConfigJson.details" :key="row.id">
                                                <td class="text-left">{{index+1}}</td>
                                                <td class="text-left">{{row.command_name}}</td>
                                                <td class="text-left">{{row.loc_name}}</td>
                                                <td class="text-left">{{row.gw_name}}</td>
                                                <td class="text-left">{{row.met_name}}</td>
                                                <td class="text-left">{{row.issued_time}}</td>
												<td class="text-left">{{row.res_time}}</td>
												<td class="text-left">{{row.exec_time}}</td>
												<td class="text-left">{{row.command_status}}</td>
                                                <!-- <td  v-show="false" v-if="row.command_status == 'SUCCESS'">
													<i class="fa fa-eye text-blue" title="View Status" style="cursor:pointer" @click="viewCommandresponseFunction(index,row,row.id,row.command_name,row.loc_id,row.loc_name)"></i>
												</td>
					  							<td v-show="false" v-else><i class="fa fa-eye text-gray" title="Not Applicable" style="cursor:not-allowed"/></td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="rmsondemandConfigJson ==  null" style="text-align:center;width: 100%;">
                                        <span>No records found</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- [ sample-page ] end -->
            </div>
            <!-- [ Main Content ] end -->
        </div>
    </div>
    <modal name="cmdmangementpopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="300" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" style="z-index:1111 !important" width="30%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{cmdmangementpopupTitle}}</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="hide_closeBTN" type="button" class="btn btn-default" @click="hidecmdmangementpopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <!--Body-->
            <div class="modal-body" style="margin-top:-10px;">
				<div class="row">
				  <div class="col-lg-4">
				   Command Name
					<span style="color: red">*</span>:
				  </div>
				  <div class="col-lg-8 text-left">
					<select class="mx-input" id="onDemandCommandTXT" v-model="onDemandCommandTXT">
																  <option value='Choose Command' selected disabled>Choose Command</option>
									<option value='GET_GEN_CONFIG' v-show="false">GET_GEN_CONFIG</option>
									<option value='GET_DLMS_CONFIG' v-show="false">GET_DLMS_CONFIG</option>
									<option value='OD_INST_MESSAGE'>OD_INST_MESSAGE</option>
									<option value='OD_LS_MESSAGE'>OD_LS_MESSAGE</option>
									<option value='OD_EVENT_MESSAGE'>OD_EVENT_MESSAGE</option>
									<option value='OD_MIDNIGHT_MESSAGE'>OD_MIDNIGHT_MESSAGE</option>
									<option value='OD_BILLING_MESSAGE'>OD_BILLING_MESSAGE</option>
									<option value='GET_OLD_DATA' v-show="false">GET_OLD_DATA</option>
                                    <option v-show="userSessionData.role == 'Super User'" value='DOWNLOAD_MDM_LOGS'>DOWNLOAD_MDM_LOGS</option>
									</select>
				  </div>
				</div>
				<p v-show="onDemandCommandTXT == 'OD_LS_MESSAGE'"> </p>
				<div class="row" v-show="onDemandCommandTXT == 'OD_LS_MESSAGE'">
				  <div class="col-lg-4">
					Choose Date <span style="color: red">*</span>:
				  </div>
				  <div class="col-lg-8 text-left">
				   <div style="height:31px;padding-top:4px;">
					<date-picker v-model="demandFromdateTXT" format="DD MMM YYYY" value-type="format" placeholder="Select Date" :disabled-date="notAfterToday" :editable="false" :clearable="false"  valueType="format" style="width:100%"></date-picker>
					</div>
				  </div>
				</div>
				<p></p>
				<div class="row">
				  <div class="col-lg-4">
					Location Name  <span style="color: red">*</span>:
				  </div>
				  <div class="col-lg-8 text-left">
					<select class="mx-input" id="ondemandLocNameTXT" v-model="ondemandLocNameTXT" @change="changePopupLocationMthod(ondemandLocNameTXT)">
						<option value='Choose Location' selected disabled>Choose Location</option>
						<option v-for="(item,index) in popuplocListArray" :key="index" :value="item.id+'$$'+item.loc_name">{{item.loc_name}}</option>
					</select>
				  </div>
				</div>
				<p></p>
				<div class="row">
				  <div class="col-lg-4">
					Gateway Name  <span style="color: red">*</span>:
				  </div>
				  <div class="col-lg-8 text-left">
					<select class="mx-input" id="ondemandGwTXT" v-model="ondemandGwTXT" @change="changePopupDeviceMthod(ondemandGwTXT)">
								<option value='Choose Gateway' selected disabled>Choose Gateway</option>
									<option v-for="(item,index) in popupgwListArray" :key="index" :value="item.id+'$$'+item.dev_name">{{item.dev_name}}</option>
																</select>
				  </div>
				</div>
				<p></p>
				<div class="row">
				  <div class="col-lg-4">
					Meter Name  <span style="color: red">*</span>:
				  </div>
				  <div class="col-lg-8 text-left">
					<select class="mx-input" id="ondemandMetTXT" v-model="ondemandMetTXT" >
						<option value='Choose Meter' selected disabled>Choose Meter</option>
						<option v-for="(item,index) in popupmetListArray" :key="index" :value="item.id+'$$'+item.met_name">{{item.met_name}}</option>
					</select>
				  </div>
				</div>
				 <p></p>
				<div class="row">
				  <div class="col-lg-4">
					Reason  <span style="color: red">*</span>:
				  </div>
				  <div class="col-lg-8 text-left">
					​<textarea class="mx-input"  id="ondemandReasonTXT" v-model="ondemandReasonTXT" rows="10" cols="70" style="min-width: 100%;min-height: 150px;max-width: 100%;"></textarea>
				  </div>
				</div>
				<p></p>
			  </div>
            <!--Footer-->
            <div class="modal-footer flex-center">
                <a id="createcmdmangementBTN" class="btn btn-sm btn-primary" style="color:#fff;border:1px solid lightgray" @click="rmsCommandSaveFunction()">Issue Command</a>
                <a id="cmdmangementclseBTN" type="button" class="btn btn-sm btn-outline-info" @click="hidecmdmangementpopup()">Cancel</a>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import axios from 'axios';
import VueElementLoading from "vue-element-loading";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import GUI_Config from '@/GUI_Config';
var myDate = new Date();
let timeout;

export default {
    name: "On_Demand_Command",
    components: {
		DatePicker,
        VueElementLoading,
    },
    data() {
        return {
            pageLayoutHeight: 0,
            isLoading: false,
            rmsondemandConfigJson: null,
            userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
			locationSelDrpTXT:'Choose Location',
			deviceSelDrpTXT:'Choose Gateway',
			meterSelDrpTXT:'Choose Meter',
			startDATE_RANGE_TXT: this.dateOnlyFRMT(myDate),
            endDATE_RANGE_TXT: this.dateOnlyFRMT(myDate),
			locDetailsArr:[],
			devDetailsArr:[],
			metDetailsArr:[],
            loginuserID: null,
            cmdmangementpopupTitle: 'Add On Demand',
            demandFromdateTXT:myDate,
			ondemandLocNameTXT:"Choose Location",
			ondemandGwTXT:'Choose Gateway',
      		popuplocListArray:[],
      		popupgwListArray:[],
			popupmetListArray:[],
      		ondemandFlag:true,
      		onDemandCommandTXT:'Choose Command',
			ondemandMetTXT:'Choose Meter',
			ondemandReasonTXT:'',
      		disabledDates: {
      		  from: new Date()
      		},
			selDEVID: null,
            selDEVNAME: null,
            selLOCID: null,
            selLOCNAME: null,
            selMETID: null,
            selMETNAME: null,
			commandListTblSTOP:null,
        };
    },
    async mounted() {
        if (this.userSessionData != null) {
            this.loginuserID = this.userSessionData.id;
        }
        console.log('loginuserID ::', this.loginuserID);
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
        this.pageLayoutHeight = window.innerHeight;

    },
	async created(){
		this.isLoading = true;
		await this.getLOCListDetails();
		await this.callODLoad();
		this.isLoading = false;
	},
	destroyed(){
		clearInterval(this.commandListTblSTOP);
	},
    methods: {
        beforeOpen() {},
        beforeClose() {},
        opened(e) {},
        closed(e) {},
		async automaticReportDetails(){
			clearInterval(this.commandListTblSTOP);
			await this.getCommandmanageList();
		},
	  async callODLoad(){
    await this.getCommandmanageList();
    var api_sent = false;
    var response_received = true;
    this.commandListTblSTOP = setInterval(async () => {
      console.log('callODLoad :api_sent :'+ api_sent + ': response_received :'+ response_received);
      if (api_sent == false && response_received == true) {
        console.log("callODLoad API Request Sent");
        api_sent = true;
        response_received = false;
        var startDate = new Date();
		await this.getCommandmanageList();
        var endDate = new Date();
        var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        console.log("Total Elapsed : ", seconds);
        console.log("****callODLoad**API*****END****");
        response_received = true;
        api_sent = false;
      }
    	}, GUI_Config.runIntervalTime);
    },
	async getCommandmanageList() {
   		//this.isLoading = true;
		if(this.metDetailsArr.length != 0){
		var cmmandmanagementJSON = {
          username: this.userSessionData.user_name,
          role: this.userSessionData.role,
        //   command_name: this.onDemandCommandTXT,
          loc_id: this.locationSelDrpTXT.split('$$')[0],
          loc_name: this.locationSelDrpTXT.split('$$')[1],
          gw_id: this.deviceSelDrpTXT.split('$$')[0],
          gw_name: this.deviceSelDrpTXT.split('$$')[1],
          met_id:this.meterSelDrpTXT.split('$$')[0],
          met_name:this.meterSelDrpTXT.split('$$')[1],
          from_date: this.startDATE_RANGE_TXT,
		  end_date: this.endDATE_RANGE_TXT
        };
		console.log('cmmandmanagementJSON::: ' + JSON.stringify(cmmandmanagementJSON));
		let commandListJson = await commMethods.apiPOSTmethod(apiURL.cmd_listURL, cmmandmanagementJSON,this.userSessionData);
            console.log('JSON :::commandListJson::: ' + JSON.stringify(commandListJson.data));
            if (commandListJson != undefined && commandListJson.data.status == "found") {
                this.rmsondemandConfigJson = commandListJson.data;
			//	this.isLoading = true;
            } else {
                this.rmsondemandConfigJson = null;
			//	this.isLoading = true;
            }
		}else{
			this.warningMessage("Meter Not Found");
			return false;
		}

	  },
		async getLOCListDetails() {
            let locationManagementListJSON = await commMethods.apiGETmethod(apiURL.locationManagementListURL, null, this.userSessionData);
            console.log('JSON :::getLOCListDetails::: ' + JSON.stringify(locationManagementListJSON.data));
            if (locationManagementListJSON != undefined && locationManagementListJSON.data.status == "found") {
                this.locDetailsArr = locationManagementListJSON.data.details;
                this.locationSelDrpTXT = +this.locDetailsArr[0].id + '$$' + this.locDetailsArr[0].loc_name;
                this.selLOCID = this.locDetailsArr[0].id;
                this.selLOCNAME = this.locDetailsArr[0].loc_name;
                await this.getDevListDetails(this.selLOCID);
            } else {
                this.locDetailsArr = [];
                this.locationSelDrpTXT = "Choose Location";
                this.selLOCID = null;
                this.selLOCNAME = null;
            }
        },
        async getDevListDetails(loc_id) {
            this.devDetailsArr = [];
            console.log('loc_id::: ' + loc_id);
            let dashboardLocDevMetListJSON = await commMethods.apiGETmethod(apiURL.deviceManagementListURL, null, this.userSessionData);
            console.log('JSON :::getDevListDetails::: ' + JSON.stringify(dashboardLocDevMetListJSON.data));
            if (dashboardLocDevMetListJSON != undefined && dashboardLocDevMetListJSON.data.status == "found") {
                for (var i = 0; i < dashboardLocDevMetListJSON.data.details.length; i++) {
                    console.log('de::: ' +  JSON.stringify(dashboardLocDevMetListJSON.data.details[i]));
                    if (dashboardLocDevMetListJSON.data.details[i].loc_id == loc_id) {
                        this.devDetailsArr.push(dashboardLocDevMetListJSON.data.details[i]);
                        this.deviceSelDrpTXT = this.devDetailsArr[0].id + '$$' + this.devDetailsArr[0].dev_name;
                        this.selDEVID = this.devDetailsArr[0].id;
                        this.selDEVNAME = this.devDetailsArr[0].dev_name;
                        await this.getMetListDetails(this.selDEVID);
                    }
                }
				// if(this.devDetailsArr.length != 0){
				// 	this.devDetailsArr = [];
                // 	this.deviceSelDrpTXT = "Choose Gateway";
                // 	this.selDEVID = null;
                // 	this.selDEVNAME = null;
				// 	this.metDetailsArr = [];
                // 	this.meterSelDrpTXT = "Choose Meter";
                // 	this.selMETID = null;
                // 	this.selMETNAME = null;
				// }
            } else {
                this.devDetailsArr = [];
                this.deviceSelDrpTXT = "Choose Gateway";
                this.selDEVID = null;
                this.selDEVNAME = null;
            }
        },
        async getMetListDetails(dev_id) {
            console.log('dev_id::: ' + dev_id);
            console.log('DEtails::: ' + JSON.stringify(this.devDetailsArr));
            if (this.devDetailsArr.length != 0) {
                for (var i = 0; i < this.devDetailsArr.length; i++) {
                    if (this.devDetailsArr[i].id == dev_id) {
                        this.metDetailsArr = this.devDetailsArr[i].met_details;
						if(this.metDetailsArr.length != 0){
                        this.meterSelDrpTXT = this.metDetailsArr[0].id + '$$' + this.metDetailsArr[0].met_name;
                        this.selMETID = this.metDetailsArr[0].id;
                        this.selMETNAME = this.metDetailsArr[0].met_name;
                        console.log(this.selMETID + ':::::::MET:::------ ' + this.selMETNAME);
						} else {
						this.metDetailsArr = [];
                		this.meterSelDrpTXT = "Choose Meter";
                		this.selMETID = null;
                		this.selMETNAME = null;
						}
                    }
                }
            } else {
                this.metDetailsArr = [];
                this.meterSelDrpTXT = "Choose Meter";
                this.selMETID = null;
                this.selMETNAME = null;
            }
        },
		async getPopupLOCListDetails() {
            let locationManagementListJSON = await commMethods.apiGETmethod(apiURL.locationManagementListURL, null, this.userSessionData);
            console.log('JSON :::getLOCListDetails::: ' + JSON.stringify(locationManagementListJSON.data));
            if (locationManagementListJSON != undefined && locationManagementListJSON.data.status == "found") {
                this.popuplocListArray = locationManagementListJSON.data.details;
                this.ondemandLocNameTXT = +this.locDetailsArr[0].id + '$$' + this.locDetailsArr[0].loc_name;
                await this.getPopupDevListDetails(this.locDetailsArr[0].id);
            } else {
                this.popuplocListArray = [];
                this.ondemandLocNameTXT = "Choose Location";
            }
        },
        async getPopupDevListDetails(loc_id) {
            this.popupgwListArray = [];
            let dashboardLocDevMetListJSON = await commMethods.apiGETmethod(apiURL.deviceManagementListURL, null, this.userSessionData);
            console.log('JSON :::getPopupDevListDetails::: ' + JSON.stringify(dashboardLocDevMetListJSON.data));
            if (dashboardLocDevMetListJSON != undefined && dashboardLocDevMetListJSON.data.status == "found") {
                for (var i = 0; i < dashboardLocDevMetListJSON.data.details.length; i++) {
                    console.log('de::: ' +  JSON.stringify(dashboardLocDevMetListJSON.data.details[i]));
                    if (dashboardLocDevMetListJSON.data.details[i].loc_id == loc_id) {
                        this.popupgwListArray.push(dashboardLocDevMetListJSON.data.details[i]);
                        this.ondemandGwTXT = this.popupgwListArray[0].id + '$$' + this.popupgwListArray[0].dev_name;
						console.log('this.ondemandGwTXT::: ' + this.ondemandGwTXT);
                        await this.getPopupMetListDetails(this.popupgwListArray[0].id);
                    }
                }
            } else {
                this.popupgwListArray = [];
                this.ondemandGwTXT = "Choose Gateway";
            }
        },
        async getPopupMetListDetails(dev_id) {
			this.popupmetListArray=[];
            if (this.popupgwListArray.length != 0) {
                for (var i = 0; i < this.popupgwListArray.length; i++) {
                    if (this.popupgwListArray[i].id == dev_id) {
                        this.popupmetListArray = this.popupgwListArray[i].met_details;
						if(this.popupmetListArray.length != 0){
                        this.ondemandMetTXT = this.popupmetListArray[0].id + '$$' + this.popupmetListArray[0].met_name;
						} else {
						this.popupmetListArray = [];
                		this.ondemandMetTXT = "Choose Meter";
						}
                    }
                }
            } else {
                this.popupmetListArray = [];
                this.ondemandMetTXT = "Choose Meter";
            }
        },
		async changeLocationMthod(locationTXT) {
            this.isLoading = true;
            console.log("---------locationTXT :::" + locationTXT);
            this.selLOCID = locationTXT.split('$$')[0];
            this.selLOCNAME = locationTXT.split('$$')[1];
            await this.getDevListDetails(this.selLOCID);
			clearInterval(this.commandListTblSTOP);
            this.isLoading = false;
        },
        async changeDeviceMthod(deviceTXT) {
            this.isLoading = true;
            this.selDEVID = deviceTXT.split('$$')[0];
            this.selDEVNAME = deviceTXT.split('$$')[1];
            await this.getMetListDetails(this.selDEVID);
			clearInterval(this.commandListTblSTOP);
            this.isLoading = false;
            console.log("---------deviceTXT :::" + deviceTXT);
        },
        changeMeterMthod(meterTXT) {
            console.log("---------meterTXT :::" + meterTXT);
            this.selMETID = meterTXT.split('$$')[0];
            this.selMETNAME = meterTXT.split('$$')[1];
			clearInterval(this.commandListTblSTOP);
        },
		async changePopupLocationMthod(locationTXT) {
            this.isLoading = true;
            await this.getPopupDevListDetails(locationTXT.split('$$')[0]);
            this.isLoading = false;
        },
        async changePopupDeviceMthod(deviceTXT) {
            this.isLoading = true;
            await this.getPopupMetListDetails(deviceTXT.split('$$')[0]);
            this.isLoading = false;
        },
        changePopupMeterMthod(meterTXT) {
        },
		async cmdmangementAddFunction(){

		this.isLoading = true;
      this.onDemandCommandTXT = "Choose Command";
      this.ondemandLocNameTXT = "Choose Location";
      this.ondemandGwTXT = "Choose Gateway";
      this.ondemandMetTXT = "Choose Meter";
      this.demandFromdateTXT = myDate;
      this.ondemandReasonTXT = "";
      this.ondemandBlockPeroidTXT = "5";
	  await this.getPopupLOCListDetails();
      await this.opencmdmangementpopup();
	  clearInterval(this.commandListTblSTOP);
	  this.isLoading = false;
    },
	async rmsCommandSaveFunction(){
      this.isLoading = true;
      console.log("Eneter ::" );
      if(this.onDemandCommandTXT == "Choose Command"){
        this.warningMessage("Please choose any one command name");
        this.isLoading = false;
        return false;
      }
       if(this.onDemandCommandTXT == 'OD_LS_MESSAGE'){
      if(this.demandFromdateTXT == ''){
        this.warningMessage("Please choose date.");
        this.isLoading = false;
        return false;
      }
      }
      if(this.ondemandLocNameTXT == "Choose Location"){
        this.warningMessage("Please choose any one location name");
        this.isLoading = false;
        return false;
      }
      if(this.ondemandGwTXT == "Choose Gateway"){
        this.warningMessage("Please choose any one gateway name");
        this.isLoading = false;
        return false;
      }
        if(this.ondemandMetTXT == "Choose Meter"){
        this.warningMessage("Please choose any one meter name");
        this.isLoading = false;
        return false;
        }
     if(this.ondemandReasonTXT == ""){
        this.warningMessage("Please enter command reason and proceed further.");
        this.isLoading = false;
        return false;
       }
      if(this.ondemandReasonTXT.length < 20){
        this.warningMessage("Please enter minimum 20 characters.");
        this.isLoading = false;
        return false;
      }
      var createCommandmanagementJSON = {
          username: this.userSessionData.user_name,
          role: this.userSessionData.role,
          command_name: this.onDemandCommandTXT,
          loc_id: this.ondemandLocNameTXT.split('$$')[0],
          loc_name: this.ondemandLocNameTXT.split('$$')[1],
          gw_id: this.ondemandGwTXT.split('$$')[0],
          gw_name: this.ondemandGwTXT.split('$$')[1],
          met_id:this.ondemandMetTXT.split('$$')[0],
          met_name:this.ondemandMetTXT.split('$$')[1],
          from_date: this.demandFromdateTXT,
          reason:this.ondemandReasonTXT
        };
        clearInterval(this.commandListTblSTOP);
        console.log("createCommandmanagementJSON ::" + JSON.stringify(createCommandmanagementJSON));

		let cmd_createURLJSON = await commMethods.apiPOSTmethod(apiURL.cmd_createURL, createCommandmanagementJSON, this.userSessionData);
            console.log('JSON :::cmd_createURLJSON::: ' + JSON.stringify(cmd_createURLJSON.data));
            if (cmd_createURLJSON != undefined && cmd_createURLJSON.data.status == "success") {
               await this.callODLoad();
			   await this.hidecmdmangementpopup();
               if(this.onDemandCommandTXT == "GET_OLD_DATA"){
                this.success6Message(this.onDemandCommandTXT + " - Command issued successfully.                                            \nNote: This command status will not reflect on on demand command table.");
               }else{
                this.successMessage(this.onDemandCommandTXT + " - Command issued successfully.");
               }               
               this.isLoading = false;
            } else {
				await  this.callODLoad();
				await this.hidecmdmangementpopup();
              this.warningMessage(cmd_createURLJSON.data.status);
              this.isLoading = false;
            }
    },
        StrengthChecker(PasswordParameter) {
            // We then change the badge's color and text based on the password strength
            let password = document.getElementById('userpasswordID');
            let strengthBadge = document.getElementById('StrengthDisp');
			if (strengthBadge) {
                if (strongPassword.test(PasswordParameter)) {
                    strengthBadge.style.backgroundColor = "green"
                    strengthBadge.textContent = 'Strong'
					strengthBadge.style.color = "white";
                } else if (mediumPassword.test(PasswordParameter)) {
                    strengthBadge.style.backgroundColor = 'blue'
                    strengthBadge.textContent = 'Medium';
					strengthBadge.style.color = "white";
                } else {
                    strengthBadge.style.backgroundColor = 'red'
                    strengthBadge.textContent = 'Weak';
					strengthBadge.style.color = "black";
                }
            }
        },
        passwordEventListener() {
            console.log('000000<>>');
            //The badge is hidden by default, so we show it
            let password = document.getElementById('userpasswordID');
            let strengthBadge = document.getElementById('StrengthDisp');
            if (strengthBadge) {
                strengthBadge.style.display = 'block';
            }
            clearTimeout(this.timeout);

            //We then call the StrengChecker function as a callback then pass the typed password to it

            this.timeout = setTimeout(() => this.StrengthChecker(password.value), 100);

            //Incase a user clears the text, the badge is hidden again

            if (password.value.length !== 0) {
                if (strengthBadge) {
                    strengthBadge.style.display != 'block';
                }
            } else {
                if (strengthBadge) {
                    strengthBadge.style.display = 'none';
                }
            }
        },
		notAfterToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
		startDATE_RANGE_PickerChange(value) {
            this.startDATE_RANGE_TXT = value;
        },
        endDATE_RANGE_PickerChange(value) {
            this.endDATE_RANGE_TXT = value;
        },
        opencmdmangementpopup() {
            this.$modal.show('cmdmangementpopup');
        },
        async hidecmdmangementpopup() {
			this.isLoading = true;
            this.$modal.hide('cmdmangementpopup');
			await this.getCommandmanageList();
			this.isLoading = false;
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
		dateOnlyFRMT(dateval) {
            var date = new Date(dateval);
            var month = date.getMonth();
            var day = date.getDate();
            var year = date.getFullYear();
            var hour = date.getHours();
            var min = date.getMinutes();
            var sec = date.getSeconds();
            var monthlimt = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var dateStr = day <= 9 ? "0" + day : day;
            return dateStr + " " + monthlimt[month] + " " + year;
        },
        userIDblockSpecialChar(e) {
            var keyCode = e.which;
            if (
                !(
                    (keyCode >= 65 && keyCode <= 90) ||
                    (keyCode >= 97 && keyCode <= 122)
                ) &&
                keyCode != 8 &&
                keyCode != 32
            ) {
                e.preventDefault();
            }
        },
        successMessage(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        success6Message(msg) {
            this.$notice.success({
                title: 'Notification',
                description: msg,
                duration: 6,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        warningMessage(msg) {
            this.$notice.warning({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        infoMessage(msg) {
            this.$notice.info({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        errorMessage(msg) {
            this.$notice.error({
                title: 'Notification',
                description: msg,
                duration: 3,
                onClose() {
                    // tslint:disable-next-line:no-console
                }
            })
        },
        EmailAddressvalid(str) {
            var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return pattern.test(str); // returns a boolean
        },
        showpassword() {
            if (this.typevalue === "password") {
                this.typevalue = "text";
                this.imgvalue = "assets/images/password_open.png";
            } else {
                this.typevalue = "password";
                this.imgvalue = "assets/images/password_close.png";
            }
        },
        showpassword1() {
            if (this.typevalue1 === "password") {
                this.typevalue1 = "text";
                this.imgvalue1 = "assets/images/password_open.png";
            } else {
                this.typevalue1 = "password";
                this.imgvalue1 = "assets/images/password_close.png";
            }
        },
    },
    watch: {},
};
</script>

<style>
.crip-notice-content .crip-notice-desc {
    font-size: 12px;
    color: #636b6f;
    line-height: 1.5;
    text-align: initial;
    white-space: pre-wrap !important;
}
</style>
