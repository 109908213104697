<template>
<div class="pcoded-main-container ">
    <vue-element-loading :active="isLoading" spinner="line-wave" color="#00535c" size="40" text="Please wait..." :is-full-screen="true" />
    <div class="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
        <ul class="nav flex-column text-white w-100">
            <span class="h5  my-2 " style="color:#E91E63">
                &nbsp; <b>Locations List : </b>
            </span>
            <li class="h5 text-white my-2">
            
                <v-jstree :data="treeViewData" @item-click="locationTreeItemClick"></v-jstree>
            </li>
        </ul>

    </div>

    <!-- Main Wrapper -->
    <div class="p-1 my-container active-cont">
        <!-- Top Nav -->
        <nav class="navbar top-navbar navbar-light" style="cursor: pointer !important;z-index: 5;">
            <a class="btn btn-sm border-0" id="menu-btn"><i class="feather icon-menu"></i></a>
        </nav>
        <!--End Top Nav -->
        <div class="row">
            <div v-show="false" style="margin-left:50px">
                <!-- {{ treeViewData}} -->
                 {{ selLOCID }} -- {{ selLOCNAME }} -- {{ selDEVID }} -- {{ selDEVNAME }} -- {{ selMETID }} -- {{ selMETNAME }}
            </div>
            <div class="col-lg-12 flexboxcontainer">
                <div class="col-lg-6 rightbox1">
                    <div class="card table-card">
                        <div class="card-header">
                            <h5> Daily Profile <b class="text-c-blue">- {{ dailydateLBL }}  </b></h5>
                            <div class="card-header-right">
                                <div class="btn-group card-option">
                                    <date-picker v-model="dailydateFRMT" format="DD MMM YYYY" value-type="format" placeholder="Select Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="dailyPickerChange" valueType="format"></date-picker>
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="feather icon-more-horizontal"></i>
                                    </button>
                                    <ul class="list-unstyled dropdown-menu dropdown-menu-right">
                                        <li class="dropdown-item" @click="dailychartTypeMethod('bar')">
                                            <a><span><i class="feather icon-bar-chart"></i> Bar
                                                    Chart</span></a>
                                        </li>
                                        <li class="dropdown-item" @click="dailychartTypeMethod('line')">
                                            <a><span><i class="feather icon-activity"></i> Line
                                                    Chart</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div v-if="dailyseriesData.length !=0">
                                <apexchart width="100%" :height="chartDAILYPROFILEPANEHeight" :key="dailychartKey"  :type="dailyChartType" :options="dailychartOptions" :series="dailyseriesData"></apexchart>
                            </div>
							<div v-else class="text-center" v-bind:style="{'height':(chartDAILYPROFILEPANEHeight+15)+'px'}">
								<div> No Records Found</div>
							 </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 rightbox2">
                    <div class="card table-card">
                        <div class="card-header">
                            <h5>Instantaneous Parameter</h5>
                            <div class="card-header-right">
                              <b> <small> Time : <i class="fa fa-clock text-c-green"></i> {{instUpdatedTime}}</small></b> 
                                </div>
                        </div>
                        <div class="card-body p-0" v-bind:style="{'height':(chartDAILYPROFILEPANEHeight+15)+'px'}">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr>
                                            <td class="f-w-500">Serial No</td>
                                            <td class="text-left f-29 "><label class="badgeinst badge-light-primary text-left">{{serialnoTXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>V1</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left">{{volt1TXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>V2</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left">{{volt2TXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>V3</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left">{{volt3TXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>Avg Current (A)</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left"><b>{{avgcurrTXT}}</b></label></td>
                                        </tr>
                                        <tr>
                                            <td>Power Factor</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left">{{pfTXT}} </label></td>
                                        </tr>
                                        <tr>
                                            <td>ACT Power (kW)</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left">{{actPWTXT}}
                                                </label></td>
                                        </tr>
                                        <tr>
                                            <td>REACT Power (kVAR)</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left">{{reactPWTXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>Frequency (Hz)</td>
                                            <td class="text-left"><label class="badgeinst badge-light-primary text-left">{{freqTXT}}</label></td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 rightbox3">
                    <div class="card table-card">
                        <div class="card-header">
                            <h5> <strong style="color:#00535c"><i class="fa fa-microchip"></i> {{ selDEVNAME }}</strong>  - Status</h5>
                        </div>
                        <div class="card-body p-0" v-bind:style="{'height':(chartDAILYPROFILEPANEHeight+15)+'px'}">
                            <div class="table-responsive">
                                <table class="table table-hover mb-1">
                                    <tbody>
                                        <tr>
                                            <td>Connection Status</td>
                                            <td class="text-left" v-if="modemConStsTXT == 'Communicating'"><label class="badge text-white text-left" style="background-color: green;"><i class="fa fa-check"></i> {{modemConStsTXT}}</label></td>
                                            <td class="text-left" v-else-if="modemConStsTXT == 'Not Communicating'" ><label class="badge text-white text-left" style="background-color: red;"><i class="fa fa-times"></i> {{modemConStsTXT}}</label></td>
											<td class="text-left" v-else><label class="badge text-white text-left" style="background-color: gray;"><i class="fa fa-stop"></i> {{modemConStsTXT}}</label></td>
                                        </tr>
                                        <tr v-show="false">
                                            <td>Connected From</td>
                                            <td class="text-left"><label class="badge badge-light-primary text-left">{{modemConFromTXT}}</label></td>
                                        </tr>
										<tr>
                                            <td>Serial No</td>
                                            <td class="text-left"><label class="badge badge-light-primary text-left">{{modemDevSerTXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>Up Time</td>
                                            <td class="text-left"><label class="badge badge-light-primary text-left">{{modemConUptimeTXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>Signal Strength (dB)</td>
                                            <td class="text-left"><label class="badge badge-light-primary text-left">{{modemSignalStrTXT}}</label></td>
                                        </tr>
                                        <tr>
                                            <td>Last Diag Message<br />Date & Time</td>
                                            <td class="text-left"><label class="badge badge-light-primary text-left">{{modemDiagMsgTXT}}</label></td>
                                        </tr>
										<tr>
                                            <td>Meter Status</td>
                                            <td class="text-center"><button class="btn btn-sm btn-primary" @click="viewMeterStatusPopup()" style="width:100%"><i class="fa fa-eye"></i> View Status</button></td>
                                        </tr>
                                        <tr v-show="false">
                                            <td>Firmware Version</td>
                                            <td class="text-left" style="font-size:11px"><label class="badge badge-light-primary">{{modemfwVerTXT}}</label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 flexboxcontainer">
                <div class="col-lg-9 rightbox4">
                    <div class="card table-card">
                        <div class="card-header">
                            <h5>Monthly Profile - <b class="text-c-blue">{{ monthlydateLBL }}</b></h5>
                            <div class="card-header-right">
                                <div class="btn-group card-option">
                                    <date-picker v-model="monthlydateFRMT" format="MMM YYYY" placeholder="Select Month" type="month" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="monthlyPickerChange" valueType="format"></date-picker>
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="feather icon-more-horizontal"></i>
                                    </button>
                                    <ul class="list-unstyled dropdown-menu dropdown-menu-right
  ">
                                        <li class="dropdown-item" @click="monthlychartTypeMethod('bar')">
                                            <a><span><i class="feather icon-bar-chart"></i> Bar
                                                    Chart</span></a>
                                        </li>
                                        <li class="dropdown-item" @click="monthlychartTypeMethod('line')">
                                            <a><span><i class="feather icon-activity"></i> Line
                                                    Chart</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div v-if="monthlyseriesData.length != 0">
                                <apexchart width="100%" :height="chartMONTLHYYPROFILEPANEHeight" :type="monthlyChartType" :options="monthlychartOptions" :series="monthlyseriesData"></apexchart>
                            </div>
							<div v-else class="text-center" v-bind:style="{'height':(chartMONTLHYYPROFILEPANEHeight+15)+'px'}">
								<div> No Records Found</div>
							 </div>
                        </div>
                    </div>
                </div>
				<div class="col-lg-3 rightbox5">
                    <div class="card table-card">
                        <div class="card-header">
                            <h5><strong style="color:#00535c"><i class="fa fa-newspaper"></i>  {{ selMETNAME }}</strong> - Events  <small>(Latest <b>50</b> Events)</small></h5>
                            <div class="card-header-right" v-show="false">
                                <div class="btn-group card-option">
								<date-picker v-model="eventdateFRMT" format="DD MMM YYYY" value-type="format" placeholder="Select Date" :disabled-date="notAfterToday" :editable="false" :clearable="false" @change="eventsPickerChange" valueType="format"></date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <div v-bind:style="{'height':(chartMONTLHYYPROFILEPANEHeight)+'px'}" style="overflow: auto;overflow-x: hidden;border: 1px solid linen;">
								<ul class="timeline" v-if="meterEventArrayList.length != 0">
									<li v-for="(items, index) in meterEventArrayList" :key="index">
										<i class="fa fa-clock"></i>
										<div class="" style="margin-left: 50px">
											<span class="timeline-header text-muted">{{items.date_time}}</span>
											<p class="m-b-0">{{items.msg}}</p>
										</div>
									</li>
								</ul>
								<div class="text-center" v-else>
									<div> No Records Found</div>
								</div>
							</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" style="padding-left: 0px;" v-show="false">
                    <div class="card table-card">
                        <div class="card-body">
                            <ul class="nav nav-tabs mb-3 p-1" id="myTab" role="tablist">
                                <li class="nav-item" @click="eventTab('Meter')">
                                    <a class="nav-link active text f-w-900" id="meterev-tab" data-toggle="tab" href="#meterev" role="tab" aria-controls="meterev" aria-selected="true">Meter Events</a>
                                </li>
                                <li class="nav-item" @click="eventTab('Modem')" v-show="false">
                                    <a class="nav-link text f-w-900" id="modemev-tab" data-toggle="tab" href="#modemev" role="tab" aria-controls="modemev" aria-selected="false">Modem Events</a>
                                </li>
                                <span class="text-right pull-right" style="margin-left: 200px;"> 
                                    <date-picker v-model="eventdateFRMT" format="DD MMM YYYY" value-type="format" placeholder="Select Date" :disabled-date="notAfterToday" :editable="false" :clearable="true" @change="eventsPickerChange" valueType="format"></date-picker>
                                </span>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="meterev" role="tabpanel" aria-labelledby="meterev-tab">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="col-lg-12">
                                                <h6><i class="fa fa-hourglass-half text-c-red"></i> Meter Events <small>(Latest <b>50</b> Events)</small>:</h6>
                                                <div v-bind:style="{'height':((pageLayoutHeight-395)/2)+'px'}" style="overflow: auto;overflow-x: hidden;border: 1px solid linen;">
                                                    <ul class="timeline" v-if="meterEventArrayList.length != 0">
                                                        <li v-for="(items, index) in meterEventArrayList" :key="index">
                                                            <i class="fa fa-clock"></i>
                                                            <div class="" style="margin-left: 50px">
                                                                <span class="timeline-header text-muted">{{items.date_time}}</span>
                                                                <p class="m-b-0">{{items.msg}}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul class="timeline text-center" v-else>
                                                        <div> No Records Found</div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="modemev" role="tabpanel" aria-labelledby="modemev-tab">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="col-lg-12">
                                                <h6><i class="fa fa-hourglass-half text-c-red"></i> Modem Events <small>(Latest <b>50</b> Events)</small>:</h6>
                                                <div v-bind:style="{'height':((pageLayoutHeight-395)/2)+'px'}" style="overflow: auto;overflow-x: hidden;border: 1px solid linen;">
                                                    <ul class="timeline" v-if="modemEventArrayList.length != 0">
                                                        <li v-for="(items, index) in modemEventArrayList" :key="index">
                                                            <i class="fa fa-clock"></i>
                                                            <div class="" style="margin-left: 50px">
                                                                <span class="timeline-header text-muted">{{items.date_time}}</span>
                                                                <p class="m-b-0">{{items.msg}}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul class="timeline text-center" v-else>
                                                        <div> No Records Found</div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<modal name="meterStatuspopup" transition="nice-modal-fade" classes="demo-modal-class" :min-width="200" :min-height="200" :pivot-y="0.5" :adaptive="true" :draggable="true" :scrollable="true" :reset="true" width="25%" height="auto" @before-open="beforeOpen" @opened="opened" @closed="closed" @before-close="beforeClose" :clickToClose="false">
        <div class="size-modal-content ">
            <div class="modal-header">
                <div class="row">
                    <div class="col-lg-6" style="margin-top: 12px;">
                        <h6 style="color:#00535c"> {{selDEVNAME}} - Meter Status</h6>
                    </div>
                    <div class="col-lg-6 text-right">
                        <button id="save_closeBTN" type="button" class="btn btn-default" @click="closeMeterStatuspopup()" style="padding: 0.4rem 0.5rem;font-size: 20px;background:transparent; border:1px solid transparent">
                            <span aria-hidden="true" class="white-text">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="margin-top:0px;">
                <div class="row">
						<div class="col-lg-12">
                            <div class="text-center" style="height:220px;overflow: auto;overflow-x: hidden;border: 1px solid linen;">
								<div style="display: flex;align-items: center;flex-wrap: wrap;padding: 10px;" v-if="deviceMetStatusArrayList.length != 0">
									<div v-for="(items,genindex) in deviceMetStatusArrayList" :key="genindex">
									<div class="item" title="Communicating"  v-if="items.met_status == 'Communicating'">
										 <small class="text"><b>{{items.met_name}}</b></small>
										 <img src="/assets/images/online_meter.png"  title="Communicating"  width="35" height="35" >
									 </div>
									 <div class="item" title="Not Communicating"  v-else-if="items.met_status == 'Not Communicating'">
										<small class="text"><b>{{items.met_name}}</b></small>
										<img src="/assets/images/offline_meter.png"  title="Not Communicating"  width="35" height="35">
									 </div>
									 <div class="item" title="Not Configured"  v-else>
										<small class="text"><b>{{items.met_name}}</b></small>
										<img src="/assets/images/notcomm_meter.png"  title="Not Configured"  width="35" height="35">
									 </div>
									</div>
									 </div>
                                <!-- <div  v-if="deviceMetStatusArrayList.length != 0">
									<div v-for="(items, index) in deviceMetStatusArrayList" :key="index">
										<td class="pull-left" style="padding: 0.2rem 0.5rem;"><img src="assets/images/meter.png" width="22" /> {{items.met_name}}</td>
										<td class="text-left" style="padding: 0.2rem 0.5rem;" v-if="items.met_status == 'Communicating'"><label class="badge text-white" style="background-color: green;">{{items.met_status}}</label></td>
										<td class="text-left" style="padding: 0.2rem 0.5rem;" v-else><label class="badge text-white" style="background-color: red;">{{items.met_status}}</label></td>
									</div>
                                </div> -->
                                <div class="text-center" v-else>
                                    <div> No Records Found</div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>

    </modal>
    </div>
</div>
</template>

<script>
import VJstree from "vue-jstree";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueElementLoading from "vue-element-loading";
import GUIConfig from "@/GUI_Config";
import apiURL from "@/API_Config";
import commMethods from '@/api/common_methods.js'
var myDate = new Date();
export default {
    name: "Dashboard",
    components: {
        DatePicker,
        VueElementLoading,
        VJstree,
    },
    data() {
        return {
            pageLayoutHeight: 0,
			userSessionData :JSON.parse(sessionStorage.getItem('loginsession')),
			chartDAILYPROFILEPANEHeight: 0,
			chartMONTLHYYPROFILEPANEHeight: 0,
			iNSTPANEHeight: 0,
			mODEMPANEHeight: 0,
			eVENTPANEHeight: 0,
            isLoading: false,
            GUIConfig: GUIConfig,
            treeViewData: [],
            serialnoTXT: "-",
            volt1TXT: "-",
            volt2TXT: "-",
            volt3TXT: "-",
            avgvoltTXT: "-",
            avgcurrTXT: "-",
            pfTXT: "-",
            actPWTXT: "-",
            reactPWTXT: "-",
            freqTXT: '-',
            instUpdatedTime: "",
            modemConStsTXT: "",
            modemConFromTXT: "",
			modemDevSerTXT:"",
            modemConUptimeTXT: "",
            modemSignalStrTXT: "",
            modemDiagMsgTXT: "",
            modemFrmTXT: "",
            modemCommStsTXT: "",
            modemfwVerTXT: "",
            dailydateLBL: this.dateOnlyFRMT(myDate),
            dailydateFRMT: this.dateOnlyFRMT(myDate),
            monthlydateLBL: this.dateMonthFRMT(myDate),
            monthlydateFRMT:  this.dateMonthFRMT(myDate),
            meterEventArrayList: [],
            modemEventArrayList: [],
            eventtTabsName: '',
            dailychartKey:0,
            eventdateFRMT: this.dateOnlyFRMT(myDate),
            dailyChartType: "bar",
            dailychartOptions: {
                chart: {
                    id: "dailyprofilechart",
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [20, 100, 100],
                    },
                },
                grid: {
                    borderColor: "#f1f1f1",
                    row: {
                        colors: ["#f4f4f4", "#f4f4f4"],
                        opacity: 0.5,
                    },
                    column: {
                        colors: ["#f4f4f4", "#f7f7f7"],
                        opacity: 0.5,
                    },
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
					title: {
						text: "",
						// offsetX: -50,
    					// offsetY: 90,
						margin: 0,
					},
                    // type: 'category',
                    type: "numeric",
                    tickPlacement: 'on',
                    min:  this.dateOnlyNUMFRMT(this.dailydateFRMT),
                    max:  this.dateOnlyNUMFRMT(this.dailydateFRMT),
                    //range: .1,                   
                    labels: {
                        show: true,
                        rotate: -35,
                        rotateAlways: true,
						// offsetX: 0,
          				// offsetY: 0,
						//datetimeUTC: true,
                     
                        // format: 'HH:mm',
                        formatter: function (value, timestamp) {
                            var today = new Date(timestamp);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth() + 1;
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            if (mm < 10) {
                                mm = "0" + mm;
                            }
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            //hh = 1;
                            //var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                            var date = hh+ ":" + '00';
                            return date;
                        },
                    },
                },
                yaxis: {
                    max:0,
					labels: {
                        formatter: function (value) {
                            return value.toFixed(2);
                        }
                    },
                    title: {
                        text: "Energy",
                    },
                }, 
                markers: {
                    size: [4, 4],
                },
                // colors: ["#00535c", "#7a023a"],
                colors: ["#E91E63", "#00535c","#0D47A1","#5bc0de"],
                chart: {
                    zoom: {
                        enabled: true,
                        type: "x",
                        autoScaleYaxis: false,
                        zoomedArea: {
                            fill: {
                                color: "#90CAF9",
                                opacity: 0.4,
                            },
                            stroke: {
                                color: "#0D47A1",
                                opacity: 0.4,
                                width: 1,
                            },
                        },
                    },
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 1000,
                        animateGradually: {
                            enabled: true,
                            delay: 350,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 850,
                        },
                    },
                    height: 385,
                    stacked: false,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    curve: "smooth", // ['smooth', 'straight']
                    lineCap: "butt",
                    colors: undefined,
					width: 1.5,
                    dashArray: 0,
                },

                legend: {
                    position: "top",
                    horizontalAlign: "center",
                },
                tooltip: {
                    x: {
                        show: true,
                        //format: "dd/MM/yyyy HH:mm",
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth() + 1;
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            if (mm < 10) {
                                mm = "0" + mm;
                            }
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            var date = dd + "-" + mm + "-" + yyyy + "  " + hh + ":" + min + ":" + sec;
                            return date;
                        },
                    },
                },
            },
            dailyseriesData: [],
            // month graph
            monthlyChartType: "bar",
            monthlychartOptions: {
                chart: {
                    id: "monthlyprofilechart",
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [20, 100, 100],
                    },
                },
                grid: {
                    borderColor: "#f1f1f1",
                    row: {
                        colors: ["#f4f4f4", "#f4f4f4"],
                        opacity: 0.5,
                    },
                    column: {
                        colors: ["#f4f4f4", "#f7f7f7"],
                        opacity: 0.5,
                    },
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
					//type: "datetime",
                //    min:"01-06-2023",
                //    max:"30-06-2023",
					// tickPlacement: 'on',
					title: {
						text: "Days",
						offsetX: 0,
    					offsetY: 105,
						// margin: 10,
					},
                    labels: {
                        show: true,
                        rotate: -25,
                        rotateAlways: true,
                        hideOverlappingLabels: true,
                        //format: 'dd MMM',
						// offsetX: -50,
          				// offsetY: 0,
                        formatter: function (value, timestamp) {
                            var today = new Date(timestamp);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth();
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            //  if (mm < 10) {mm = "0" + mm;}
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            // var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                            var monthlimt = [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ];
                            var date =  monthlimt[Number(mm)]+ " "+dd;
                            return date;
                        },
                    },
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center",
                },
                yaxis: [{
					labels: {
                        formatter: function (value) {
                            return value.toFixed(2);
                        }
                    },
                    title: {
                        text: "Energy",
                    },
                }, ],
                markers: {
                    size: [4, 4],
                },
                colors: ["#E91E63", "#00535c", "#0D47A1","#5bc0de"],
                chart: {
                    zoom: {
                        enabled: false
                    },
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 350,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 850,
                        },
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    curve: "smooth", // ['smooth', 'straight']
                    lineCap: "butt",
                    colors: undefined,
                    width: 1,
                    dashArray: 1,
                },
                title: {
                    text: "",
                    align: "left",
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth() + 1;
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            if (mm < 10) {
                                mm = "0" + mm;
                            }
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            var date = dd + "-" + mm + "-" + yyyy;
                            return date;
                        },
                    },
                },
            },
            monthlyseriesData: [],
            selLOCID: null,
            selLOCNAME: null,
            selDEVID: null,
            selDEVNAME: null,
            selMETID: null,
            selMETNAME: null,
			dashboardInterval:null,
			meterstsInterval:null,
			deviceMetStatusArrayList:[],
            dailyProfileChartMAX:0,
        }
    },
    props: {},
    created(){
       
    },
    async mounted() {
        console.log("====screen width========="+window.innerWidth);
        console.log("====screen height========="+window.innerHeight);
        this.isLoading = true;
        this.pageLayoutHeight = window.innerHeight;
        if(window.innerHeight <= 768){
            this.chartDAILYPROFILEPANEHeight = ((this.pageLayoutHeight+50)/2);
		    this.chartMONTLHYYPROFILEPANEHeight = ((this.pageLayoutHeight+50)/2);
        }else{
            this.chartDAILYPROFILEPANEHeight = ((this.pageLayoutHeight-280)/2);
		    this.chartMONTLHYYPROFILEPANEHeight = ((this.pageLayoutHeight-305)/2);
        }
		
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        menu_btn.addEventListener("click", () => {
            sidebar.classList.toggle("active-nav");
            container.classList.toggle("active-cont");
        });
        // treeview         
        // console.log("-----dashboardTreeViewsession---------->>"+JSON.stringify(sessionStorage.getItem("dashboardTreeViewsession")));
        // START **
       // await this.getTreeListDetails();
        if(sessionStorage.getItem("dashboardTreeViewsession") != null){
             this.treeViewData  = JSON.parse(sessionStorage.getItem("dashboardTreeViewsession"));
             this.treeSessionListViewData  = await this.getTreeSessionListDetails();
             if(this.treeSessionListViewData != null){
                console.log("treeSessionListViewData : "+this.treeSessionListViewData.length);
                console.log("treeViewData : "+this.treeViewData.length);
                if(this.treeSessionListViewData.length != this.treeViewData.length){
                    await this.getTreeListDetails();
                }
             }
             await this.convertTreeViewFRT(this.treeViewData);
        }else{
            await this.getTreeListDetails();
        }       
        await this.getDayProfileChartDetails();
        await this.getMonthlyProfileChartDetails();
        await this.callDashboardLoad();
        await this.getMETEventsDetails();
        // END **



        //await this.getMODEMEventsDetails();
       
        this.isLoading = false;
		this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
    },
	destroyed(){
		clearInterval(this.dashboardInterval);
		if(this.meterstsInterval != null){
			clearInterval(this.meterstsInterval);
		}
	},
	// updated(){
	// 	this.userSessionData = JSON.parse(sessionStorage.getItem('loginsession'));
	// 	console.log('---------CHANGE---------------..'+JSON.stringify(this.userSessionData));
	// },
    methods: {
	async callDashboardLoad(){
    await this.getINSTParamsDetails();
	await this.getMODEMParamsDetails();
    var api_sent = false;
    var response_received = true;
    this.dashboardInterval = setInterval(async () => {
      console.log('callDashboardLoad :api_sent :'+ api_sent + ': response_received :'+ response_received);
      if (api_sent == false && response_received == true) {
        console.log("callDashboardLoad API Request Sent");
        api_sent = true;
        response_received = false;
        var startDate = new Date();
		await this.getINSTParamsDetails();
		await this.getMODEMParamsDetails();
        var endDate = new Date();
        var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        console.log("Total Elapsed : ", seconds);
        console.log("****callDashboardLoad**API*****END****");
        response_received = true;
        api_sent = false;
      }
    }, GUIConfig.runIntervalTime);
    },
        async locationTreeItemClick(node) {
            this.isLoading = true;
            // // {"loc_id":29,"loc_name":"softel","dev_id":"867624060024378","dev_name":"Gateway_1","met_id":"2","text":"METER_2","met_name":"METER_2","met_status":"Communicating","met_manf_name":"Schneider Electric India Private Limited","met_version":"NTD56.03","selected":true,"disabled":false,"icon":" fa fa-newspaper text-c-white"}
			console.log(" node !", JSON.stringify(node.model));
            this.selLOCID = node.model.loc_id;
            this.selLOCNAME = node.model.loc_name;
            this.selDEVID = node.model.dev_id;
            this.selDEVNAME = node.model.dev_name;
            this.selMETID = node.model.met_id;
            this.selMETNAME = node.model.met_name;
            console.log(this.selLOCID + " clicked !");
            clearInterval(this.dashboardInterval);
		    if(this.meterstsInterval != null){
			clearInterval(this.meterstsInterval);
		    }
            await this.getDayProfileChartDetails();
            await this.getMonthlyProfileChartDetails();
            await this.callDashboardLoad();
            await this.getMETEventsDetails();
            //await this.getMODEMEventsDetails();
            sessionStorage.setItem('dashboardTreeViewsession', JSON.stringify(this.treeViewData));
            this.isLoading = false;
            // setTimeout(() => {
            //     this.isLoading = false;
            // }, 1000);
        },
		async getTreeListDetails() {
            let dashboardTreeviewListJSON = await commMethods.apiGETmethod(apiURL.dashboardTreeviewListURL, null,this.userSessionData);
            console.log('JSON :::dashboardTreeviewListJSON::: ' + JSON.stringify(dashboardTreeviewListJSON));
            if (dashboardTreeviewListJSON != undefined && dashboardTreeviewListJSON.data.status == "found") {
                sessionStorage.setItem('dashboardTreeViewsession',JSON.stringify(dashboardTreeviewListJSON.data.tree_details));
                await this.convertTreeViewFRT(dashboardTreeviewListJSON.data.tree_details);
            }
        },
        async getTreeSessionListDetails() {
            let dashboardTreeviewListJSON = await commMethods.apiGETmethod(apiURL.dashboardTreeviewListURL, null,this.userSessionData);
            console.log('JSON :::getTreeSessionListDetails::: ' + JSON.stringify(dashboardTreeviewListJSON));
            if (dashboardTreeviewListJSON != undefined && dashboardTreeviewListJSON.data.status == "found") {
                return dashboardTreeviewListJSON.data.tree_details;
                //sessionStorage.setItem('dashboardTreeViewsession',JSON.stringify(dashboardTreeviewListJSON.data.tree_details));
                //await this.convertTreeViewFRT(dashboardTreeviewListJSON.data.tree_details);
            }
        },
		async getMeterStatusDetails() {
			var meterManagementListURL = apiURL.meterManagementListURL.replace('$$dev_id$$',this.selDEVID);
            let meterManagementListJSON = await commMethods.apiGETmethod(meterManagementListURL, null,this.userSessionData);
            console.log('JSON :::meterManagementListJSON::: ' + JSON.stringify(meterManagementListJSON.data));
            if (meterManagementListJSON != undefined && meterManagementListJSON.data.status == "found") {
				this.deviceMetStatusArrayList = meterManagementListJSON.data.details;
            }else{
				this.deviceMetStatusArrayList = [];
			}
        },
        convertTreeViewFRT(treeJson) {
            console.log("--->>>>>-->>"+JSON.stringify(treeJson));
			if(treeJson.length != 0){
             for (var locIndx = 0; locIndx < treeJson.length; locIndx++) {
				if (treeJson[locIndx].children.length != 0) {
					for (var devIndx = 0; devIndx < treeJson[locIndx].children.length; devIndx++) {
					if(treeJson[locIndx].children[devIndx].length != 0){
					if (treeJson[locIndx].opened == true && treeJson[locIndx].children[devIndx].opened == true && treeJson[locIndx].children[devIndx].children.length != 0) {
                        for(var metInx=0; metInx < treeJson[locIndx].children[devIndx].children.length; metInx++){
                            if (treeJson[locIndx].children[devIndx].children[metInx].selected == true){
                                console.log("--OBJ-metInx-->>>>"+ JSON.stringify(treeJson[locIndx].children[devIndx].children[metInx]));
                                this.selLOCID = treeJson[locIndx].children[devIndx].children[metInx].loc_id;
                   		        this.selLOCNAME = treeJson[locIndx].children[devIndx].children[metInx].loc_name;
						        this.selDEVID = treeJson[locIndx].children[devIndx].children[metInx].dev_id;
                   		        this.selDEVNAME = treeJson[locIndx].children[devIndx].children[metInx].dev_name;
                                this.selMETID = treeJson[locIndx].children[devIndx].children[metInx].met_id;
                                this.selMETNAME = treeJson[locIndx].children[devIndx].children[metInx].met_name;
                                break;
                            }                           
                        }
                    }}
				}
                }
			 }
			}
			console.log('--------selLOCID------>>'+this.selLOCID);
			console.log('--------selLOCNAME------>>'+this.selLOCNAME);
			console.log('--------selDEVID------>>'+this.selDEVID);
			console.log('--------selDEVNAME------>>'+this.selDEVNAME);
			console.log('--------selMETID------>>'+this.selMETID);
			console.log('--------selMETNAME------>>'+this.selMETNAME);
			this.treeViewData = treeJson;
        },
        async getDayProfileChartDetails() {
            var dailyProfileChartJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "met_id": this.selMETID,
                "date": this.dailydateLBL
            };
            console.log(JSON.stringify(dailyProfileChartJson) + '---------getDayProfileChartDetails :::' + apiURL.dashboardDayProfileURL);
            let dashboardDayProfileJSON = await commMethods.apiPOSTmethod(apiURL.dashboardDayProfileURL, dailyProfileChartJson,this.userSessionData);
            console.log('JSON :::dashboardDayProfileJSON::: ' + JSON.stringify(dashboardDayProfileJSON.data));
            if (dashboardDayProfileJSON != undefined && dashboardDayProfileJSON.data.status == "found") {
                this.dailyseriesData = dashboardDayProfileJSON.data.details;
                const allArrays = [];
                for(var dataIndx = 0; dataIndx < dashboardDayProfileJSON.data.details.length; dataIndx++){
                    allArrays.push(...dashboardDayProfileJSON.data.details[dataIndx].data)
                }
                var maxdata = await commMethods.findMaxValueInArr(allArrays,"y");               
                if(maxdata != NaN || maxdata != null){
                    // this.dailychartOptions.yaxis['min'] = 0;
                    // this.dailychartOptions.yaxis['max'] = 0;
                    if(maxdata < 1){
                        this.dailychartOptions.yaxis.max = 1;
                    }else{
                    this.dailychartOptions.yaxis.max = maxdata + 50;
                    }
                }
                this.dailychartKey += 1;
            } else {
                this.dailyseriesData = [];
            }
        },
		async viewMeterStatusPopup(){
		await this.getMeterStatusDetails();
		var api_sent = false;
    	var response_received = true;
    	this.meterstsInterval = setInterval(async () => {
     	 console.log('viewMeterStatusPopup :api_sent :'+ api_sent + ': response_received :'+ response_received);
      	if (api_sent == false && response_received == true) {
        console.log("viewMeterStatusPopup API Request Sent");
        api_sent = true;
        response_received = false;
        var startDate = new Date();
		await this.getMeterStatusDetails();
        var endDate = new Date();
        var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        console.log("Total Elapsed : ", seconds);
        console.log("****viewMeterStatusPopup**API*****END****");
        response_received = true;
        api_sent = false;
    	  }
   		 }, GUIConfig.runIntervalTime);
		await this.showMeterStatuspopup();
		},
		showMeterStatuspopup(){
			this.$modal.show('meterStatuspopup');
		},
		closeMeterStatuspopup(){
			this.$modal.hide('meterStatuspopup');
			if(this.meterstsInterval != null){
			clearInterval(this.meterstsInterval);
			}
		},
        async getMonthlyProfileChartDetails() {
            var monthlyProfileChartJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "met_id": this.selMETID,
                "date": this.monthlydateLBL
            };
            console.log(JSON.stringify(monthlyProfileChartJson) + '---------getMonthlyProfileChartDetails :::' + apiURL.dashboardMonthProfileURL);
            let dashboardMonthProfileJSON = await commMethods.apiPOSTmethod(apiURL.dashboardMonthProfileURL, monthlyProfileChartJson,this.userSessionData);
            console.log('JSON :::dashboardMonthProfileJSON::: ' + JSON.stringify(dashboardMonthProfileJSON.data));
            if (dashboardMonthProfileJSON != undefined && dashboardMonthProfileJSON.data.status == "found") {
                this.monthlyseriesData = dashboardMonthProfileJSON.data.details;
            } else {
                this.monthlyseriesData = [];
            }
        },
        async getINSTParamsDetails() {
            var instParamsJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "met_id": this.selMETID,
            };
            console.log(JSON.stringify(instParamsJson) + '---------getINSTParamsDetails :::' + apiURL.dashboardInstParamsURL);
            let dashboardInstParamsJSON = await commMethods.apiPOSTmethod(apiURL.dashboardInstParamsURL, instParamsJson,this.userSessionData);
            console.log('JSON :::dashboardInstParamsJSON::: ' + JSON.stringify(dashboardInstParamsJSON.data));
            if (dashboardInstParamsJSON != undefined && dashboardInstParamsJSON.data.status == "found") {
                this.serialnoTXT = dashboardInstParamsJSON.data.details.sernum;
                // this.avgvoltTXT = dashboardInstParamsJSON.data.details.avg_volt;
                this.volt1TXT= dashboardInstParamsJSON.data.details.v1;
                this.volt2TXT = dashboardInstParamsJSON.data.details.v2;
                this.volt3TXT = dashboardInstParamsJSON.data.details.v3;

                this.avgcurrTXT = dashboardInstParamsJSON.data.details.avg_curr;
                this.pfTXT = dashboardInstParamsJSON.data.details.pf;
                this.actPWTXT = dashboardInstParamsJSON.data.details.act;
                this.reactPWTXT = dashboardInstParamsJSON.data.details.react;
                this.freqTXT = dashboardInstParamsJSON.data.details.freq;
                this.instUpdatedTime = dashboardInstParamsJSON.data.details.update_time;
            } else {
                this.serialnoTXT = "-";
                //this.avgvoltTXT = "-";
                this.volt1TXT= "-";
                this.volt2TXT = "-";
                this.volt3TXT = "-";
                this.avgcurrTXT = "-";
                this.pfTXT = "-";
                this.actPWTXT = "-";
                this.reactPWTXT = "-";
                this.freqTXT = "-";
                this.instUpdatedTime = "";
            }
        },
        async getMODEMParamsDetails() {
            var instParamsJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "met_id": this.selMETID,
            };
            console.log(JSON.stringify(instParamsJson) + '---------getMODEMParamsDetails :::' + apiURL.dashboardModemParamsURL);
            let dashboardModemParamsJSON = await commMethods.apiPOSTmethod(apiURL.dashboardModemParamsURL, instParamsJson,this.userSessionData);
            console.log('JSON :::dashboardModemParamsJSON::: ' + JSON.stringify(dashboardModemParamsJSON.data));
            if (dashboardModemParamsJSON != undefined && dashboardModemParamsJSON.data.status == "found") {
                this.modemConStsTXT = dashboardModemParamsJSON.data.details.con_sts;
                //this.modemConFromTXT = dashboardModemParamsJSON.data.details.con_frm;
				this.modemDevSerTXT =  dashboardModemParamsJSON.data.details.dev_ser;
                this.modemConUptimeTXT = dashboardModemParamsJSON.data.details.con_time;
                this.modemSignalStrTXT = dashboardModemParamsJSON.data.details.signal_str;
                this.modemDiagMsgTXT = dashboardModemParamsJSON.data.details.last_diag_msg;
                this.modemfwVerTXT = dashboardModemParamsJSON.data.details.fw_ver;
            } else {
                this.modemConStsTXT = "-";
				this.modemDevSerTXT = "-";
                this.modemConFromTXT = "-";
                this.modemConUptimeTXT = "-";
                this.modemSignalStrTXT = "-";
                this.modemDiagMsgTXT = "-";
                this.modemfwVerTXT = "-";
            }
        },
        async getMETEventsDetails() {
            var instJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "met_id": this.selMETID,
                "date": this.eventdateFRMT
            };
            console.log(JSON.stringify(instJson) + '---------getMETEventsDetails :::' + apiURL.dashboardMeterEventsURL);
            let dashboardMeterEventsJSON = await commMethods.apiPOSTmethod(apiURL.dashboardMeterEventsURL, instJson,this.userSessionData);
            console.log('JSON :::dashboardMeterEventsJSON::: ' + JSON.stringify(dashboardMeterEventsJSON.data));
            if (dashboardMeterEventsJSON != undefined && dashboardMeterEventsJSON.data.status == "found") {
                this.meterEventArrayList = dashboardMeterEventsJSON.data.event_details;
            } else {
                this.meterEventArrayList = [];
            }
        },
        eventTab(tabName) {
            this.eventtTabsName = tabName;
        },
        async getMODEMEventsDetails() {
            var modemJson = {
                "loc_id": this.selLOCID,
                "loc_name": this.selLOCNAME,
                "dev_id": this.selDEVID,
                "dev_name": this.selDEVNAME,
                "met_id": this.selMETID,
                "date": this.eventdateFRMT
            };
            console.log(JSON.stringify(modemJson) + '---------getMODEMEventsDetails :::' + apiURL.dashboardModemEventsURL);
            let dashboardModemEventsJSON = await commMethods.apiPOSTmethod(apiURL.dashboardModemEventsURL, modemJson,this.userSessionData);
            console.log('JSON :::dashboardModemEventsJSON::: ' + JSON.stringify(dashboardModemEventsJSON.data));
            if (dashboardModemEventsJSON != undefined && dashboardModemEventsJSON.data.status == "found") {
                this.modemEventArrayList = dashboardModemEventsJSON.data.event_details;
            } else {
                this.modemEventArrayList = [];
            }
        },
        dailychartTypeMethod(type) {
            this.dailyChartType = type;
        },
        monthlychartTypeMethod(type) {
            this.monthlyChartType = type;
            if(this.monthlyChartType == "bar"){
                this.monthlychartOptions = {
                chart: {
                    id: "monthlyprofilechart",
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [20, 100, 100],
                    },
                },
                grid: {
                    borderColor: "#f1f1f1",
                    row: {
                        colors: ["#f4f4f4", "#f4f4f4"],
                        opacity: 0.5,
                    },
                    column: {
                        colors: ["#f4f4f4", "#f7f7f7"],
                        opacity: 0.5,
                    },
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
					//type: "datetime",
                //    min:"01-06-2023",
                //    max:"30-06-2023",
					// tickPlacement: 'on',
					title: {
						text: "Days",
						offsetX: 0,
    					offsetY: 105,
						// margin: 10,
					},
                    labels: {
                        show: true,
                        rotate: -25,
                        rotateAlways: true,
                        hideOverlappingLabels: true,
                        //format: 'dd MMM',
						// offsetX: -50,
          				// offsetY: 0,
                        formatter: function (value, timestamp) {
                            var today = new Date(timestamp);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth();
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            //  if (mm < 10) {mm = "0" + mm;}
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            // var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                            var monthlimt = [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ];
                            var date =  monthlimt[Number(mm)]+ " "+dd;
                            return date;
                        },
                    },
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center",
                },
                yaxis: [{
					labels: {
                        formatter: function (value) {
                            return value.toFixed(2);
                        }
                    },
                    title: {
                        text: "Energy",
                    },
                }, ],
                markers: {
                    size: [4, 4],
                },
                colors: ["#E91E63", "#00535c", "#0D47A1","#5bc0de"],
                chart: {
                    zoom: {
                        enabled: false
                    },
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 350,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 850,
                        },
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    curve: "smooth", // ['smooth', 'straight']
                    lineCap: "butt",
                    colors: undefined,
                    width: 1,
                    dashArray: 1,
                },
                title: {
                    text: "",
                    align: "left",
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth() + 1;
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            if (mm < 10) {
                                mm = "0" + mm;
                            }
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            var date = dd + "-" + mm + "-" + yyyy;
                            return date;
                        },
                    },
                },
            };
            }else{
                this.monthlychartOptions = {
                chart: {
                    id: "monthlyprofilechart",
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 0.5,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [20, 100, 100],
                    },
                },
                grid: {
                    borderColor: "#f1f1f1",
                    row: {
                        colors: ["#f4f4f4", "#f4f4f4"],
                        opacity: 0.5,
                    },
                    column: {
                        colors: ["#f4f4f4", "#f7f7f7"],
                        opacity: 0.5,
                    },
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
				//	type: "datetime",
                //    min:"01-05-2023",
                //    max:"30-05-2023",
					// tickPlacement: 'on',
					title: {
						text: "Days",
						offsetX: 0,
    					offsetY: 105,
						// margin: 10,
					},
                    labels: {
                        show: true,
                        rotate: -25,
                        rotateAlways: true,
                        hideOverlappingLabels: true,
                       // format: 'MMM dd',
						// offsetX: -50,
          				// offsetY: 0,
                        formatter: function (value, timestamp) {
                            var today = new Date(value);
                            var yyyy = today.getFullYear();
                            var mm = today.getMonth();
                            var dd = today.getDate();
                            var hh = today.getHours();
                            var min = today.getMinutes();
                            var sec = today.getSeconds();
                            //  if (mm < 10) {mm = "0" + mm;}
                            if (dd < 10) {
                                dd = "0" + dd;
                            }
                            if (hh < 10) {
                                hh = "0" + hh;
                            }
                            if (min < 10) {
                                min = "0" + min;
                            }
                            if (sec < 10) {
                                sec = "0" + sec;
                            }
                            // var date = yyyy + "-" + mm + "-" + dd +" : "+ hh +":"+ min + ":"+ sec;
                            var monthlimt = [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                            ];
                            var date =  monthlimt[Number(mm)]+ " "+dd;
                            return date;
                        },
                    },
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center",
                },
                yaxis: [{
					labels: {
                        formatter: function (value) {
                            return value.toFixed(2);
                        }
                    },
                    title: {
                        text: "Energy",
                    },
                }, ],
                markers: {
                    size: [4, 4],
                },
                colors: ["#E91E63", "#00535c", "#0D47A1","#5bc0de"],
                chart: {
                    zoom: {
                        enabled: false
                    },
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 350,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 850,
                        },
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    curve: "smooth", // ['smooth', 'straight']
                    lineCap: "butt",
                    colors: undefined,
                    width: 1,
                    dashArray: 1,
                },
                title: {
                    text: "",
                    align: "left",
                },
                tooltip: {
                    x: {
                        show: true,
                        format: 'dd-mm-yyyy',
                        // formatter: function (value, timestamp) {
                        //     var today = new Date();
                        //     var yyyy = today.getFullYear();
                        //     var mm = today.getMonth() + 1;
                        //     var dd = today.getDate();
                        //     var hh = today.getHours();
                        //     var min = today.getMinutes();
                        //     var sec = today.getSeconds();
                        //     if (mm < 10) {
                        //         mm = "0" + mm;
                        //     }
                        //     if (dd < 10) {
                        //         dd = "0" + dd;
                        //     }
                        //     if (hh < 10) {
                        //         hh = "0" + hh;
                        //     }
                        //     if (min < 10) {
                        //         min = "0" + min;
                        //     }
                        //     if (sec < 10) {
                        //         sec = "0" + sec;
                        //     }
                        //     var date = dd + "-" + mm + "-" + yyyy;
                        //     return date;
                        // },
                    },
                },
            };
            }
        },
        notAfterToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
		beforeOpen() {},
        beforeClose() {},
        opened(e) {},
        closed(e) {},
        async dailyPickerChange(value) {
            this.isLoading = true;
            this.dailydateFRMT = value;
            this.dailydateLBL = value;
            await this.getDayProfileChartDetails();
            this.isLoading = false;
            console.log("----------->" + value);
        },
        async monthlyPickerChange(value) {
            this.isLoading = true;
            this.monthlydateFRMT = value;
            this.monthlydateLBL = value;
            await this.getMonthlyProfileChartDetails();
            console.log("----------->" + value);
            this.isLoading = false;
        },
        async eventsPickerChange(value) {
            this.isLoading = true;
            this.eventdateFRMT = value;
            await this.getMETEventsDetails();
          //  await this.getMODEMEventsDetails();
            console.log("----------->" + value);
            this.isLoading = false;
        },
		dateOnlyFRMT(dateval) {
    var date = new Date(dateval);
    var month = date.getMonth();
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
	var monthlimt = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dateStr = day <= 9 ? "0" + day : day;
    return  dateStr +" " +monthlimt[month] +" " +year;
    },
    dateOnlyNUMFRMT(dateval) {
    var date = new Date(dateval);
    var month = date.getMonth()+1;
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
	var monthlimt = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthStr = month <= 9 ? "0" + month : month;
    var dateStr = day <= 9 ? "0" + day : day;
    return  year +"-" +monthStr +"-" +dateStr;
    },
	dateMonthFRMT(dateval) {
    var date = new Date(dateval);
    var month = date.getMonth();
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
	var monthlimt = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dateStr = day <= 9 ? "0" + day : day;
    return  monthlimt[month] +" " +year;
    },
    },
    watch: {},
};
</script>

<style scoped>

.flexboxcontainer{
    display:flex;
    /* grid-template-columns: repeat(auto-fit, minmax(500px,1fr)); */
    flex-wrap:wrap;
}

.rightbox1{
    min-width: 700px;
}

.rightbox2{
    min-width: 400px;
}

.rightbox3{
    min-width: 400px;
}

.rightbox4{
    min-width: 700px;
}

.rightbox5{
    min-width: 400px;
}




.table td,
.table th {
    border-top: 1px solid transparent !important;
}

.side-navbar {
    width: 260px;
    height: 100%;
    position: fixed;
    margin-left: -280px;
    margin-top: -5px;
    background-color: #ffffff;
    transition: 0.5s;
}



.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #ffffff26;
}

.my-container {
    transition: 0.4s;
}

.active-nav {
    margin-left: 0;
}

/* for main section */
.active-cont {
    margin-left: 250px;
}

#menu-btn {
    background-color: #00535c;
    color: #fff;
    margin-top: -15px;
    margin-left: -30px;
    cursor: pointer;
}

/*

.my-container input {

  border-radius: 2rem;

  padding: 2px 20px;

}

*/

.item {
    width: 100px;
    height: 55px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e2e3d7;
    cursor: pointer;
    border-radius: 3px;
}
.item:hover{
    transform: scale(1.1);
    background-color: #dddfc4;
}
.mx-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.badge {
    font-size: 100%;
    padding: 0.5em 0.7em;
    font-weight: bold !important;
    width: 100% !important;
}
.badgeinst {
    font-size: 100%;
    padding: 0.5px 0.5em;
    font-weight: bold !important;
    width: 100% !important;
}
.mx-datepicker-main{
    top:50px important;
}
.mx-datepicker-popup{
    top:50px important;
}
</style>
